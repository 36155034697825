import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { blue } from "@mui/material/colors";
import { WishlistModel } from "../../shared/models/WishlistModel";
import { Favorite } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

export interface WishlistDialogProps {
  wishlists: Array<WishlistModel>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickFunc: (wishlistId: string) => void;
}

export default function WishlistDialog(props: WishlistDialogProps) {
  const { open, wishlists, setOpen, onClickFunc } = props;

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (wishlistId: string) => {
    onClickFunc(wishlistId);
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Please Select a wishlist</DialogTitle>
      <List sx={{ pt: 0 }}>
        {wishlists.map((wishlist) => (
          <ListItem
            button
            onClick={() => handleListItemClick(wishlist._id)}
            key={wishlist.name}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <Favorite />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={wishlist.name} />
          </ListItem>
        ))}
      </List>
      {/* <Grid container>
        <Grid item xs={12}> */}
      <Button
        variant="contained"
        style={{
          margin: "auto",
          marginBottom: "5px",
          height: "60px",
          width: "160px",
          borderRadius: "11px",
          border: "1px solid #FF5757",
          color: "#FFFFFF",
          backgroundColor: "#FF5757",
          fontFamily: "Neuton",
          fontStyle: "Normal",
          fontWeight: "300",
          fontSize: "16px",
          lineHeight: "16px",
          textTransform: "none",
        }}
        // startIcon={<FavoriteBorderOutlined color="inherit" />}
        onClick={() => history.push("/wishlists")}
      >
        Create Wishlist
      </Button>
      {/* </Grid>
      </Grid> */}
    </Dialog>
  );
}
