import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { wishService } from "../../services/wish.service";
import { WishlistItem } from "../../shared/models/WishlistItem";
import ItemDetailsDialog from "../wishlist/ItemDetailsDialog";

export default function ReservedWishes() {
  const [wishes, setWishes] = useState<null | Array<WishlistItem>>(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  useEffect(() => {
    wishService.getReservedWishes().then((res) => {
      setWishes(res);
    });
    // accountService.getById();
  }, []);

  const updateItem = (itemIdx: number, details: any) => {
    if (wishes) {
      const nextItems = wishes.map((item, idx) => {
        if (itemIdx === idx) {
          return { ...item, ...details };
        } else {
          // No change
          return item;
        }
      });
      setWishes(nextItems);

      const wishId = wishes[itemIdx]._id;

      if (wishId) {
        wishService.updateWish(wishId, details);
      }
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography
        variant="h6"
        sx={{
          mt: 1,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "30px",
          letterSpacing: "0.5px",
          color: "#000000",
          lineHeight: "30px",
        }}
      >
        Reserved wishes
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 1,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "16px",
          letterSpacing: "0.5px",
          color: "#000000",
          lineHeight: "16px",
        }}
      >
        All the wishes that you are fulfilling will show up here
      </Typography>
      <div
        style={{
          flex: 1,
          height: "1px",
          backgroundColor: "black",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      />
      {wishes ? (
        wishes.map((wish: WishlistItem, idx) => (
          <>
            {" "}
            <FormControlLabel
              sx={{ mt: 3 }}
              control={
                <Checkbox
                  checked={wish.reservedBy.purchased ? true : false}
                  onChange={(e) =>
                    updateItem(idx, {
                      reservedBy: e.target.checked
                        ? {
                            ...wish.reservedBy,
                            purchased: true,
                          }
                        : {
                            ...wish.reservedBy,
                            purchased: false,
                          },
                    })
                  }
                />
              }
              label={
                wish.reservedBy.purchased ? (
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Neuton",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Purchased!
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Neuton",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Mark as purchased
                  </Typography>
                )
              }
            />
            <Paper
              sx={{
                mb: 3,
                p: 2,
              }}
              style={{
                width: "100%",
                border: "1px solid #000000",
                borderRadius: "18px",
                fontFamily: "Neuton",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "20px",
                letterSpacing: "0.5px",
                color: "#000000",
              }}
              elevation={0}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Neuton",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "20px",
                  letterSpacing: "0.5px",
                }}
              >
                {wish.name}
              </Typography>

              <Grid container sx={{ mt: 1 }} justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Neuton",
                      fontStyle: "normal",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "16px",
                      letterSpacing: "0.5px",
                      color: "black",
                    }}
                  >
                    Receiver :{wish.user.fullName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Neuton",
                      fontStyle: "normal",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "16px",
                      letterSpacing: "0.5px",
                      color: "black",
                    }}
                  >
                    Reserved On :{wish.reservedBy.date.toString().split("T")[0]}
                  </Typography>
                </Grid>
                <Grid item>
                  <ItemDetailsDialog
                    disabled={
                      (wish.brand && wish.brand.length > 0) ||
                      (wish.link && wish.link.length > 0) ||
                      (wish.note && wish.note.length > 0) ||
                      (wish.size && wish.size.length > 0)
                        ? false
                        : true
                    }
                    open={detailsDialogOpen}
                    setOpen={setDetailsDialogOpen}
                    item={wish}
                    idx={idx}
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        ))
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
}
