import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./helpers/ScrollToTop";

startApp();

function startApp() {
  ReactDOM.render(
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
}
