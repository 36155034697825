import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { DialogContent, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export interface SimpleDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SaveWishlistDialog(props: SimpleDialogProps) {
  const history = useHistory();
  const { open, setOpen } = props;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={() => history.goBack()}
      open={open}
      PaperProps={{
        style: {
          background: ` #FFFFFF`,
          boxShadow: `0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)`,
          borderRadius: `16px`,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center", mt: 1 }}>
        <Typography
          sx={{ fontFamily: "Neuton", fontSize: "35px", color: "#000000" }}
        >
          Voila!
        </Typography>
        <Typography
          sx={{ fontFamily: "Neuton", fontSize: "25px", color: "#000000" }}
        >
          Your Wishlist is saved
        </Typography>
        <img style={{ marginTop: 5 }} src="/wishlist.png"></img>
      </DialogTitle>
      {/* <DialogContent sx={{ textAlign: "center" }}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{
            borderColor: "black",
            textTransform: "none",
            fontSize: 15,
            mt: 2,
            height: 50,
            width: "100%",
            textAlign: "center",
            borderRadius: "11px",
            border: "1px solid #FF5757",
            color: "#FF5757",
          }}
        >
          <Typography sx={{ fontFamily: "Neuton", fontSize: "15px" }}>
            Share Your Wishlist
          </Typography>
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          sx={{
            borderColor: "black",
            textTransform: "none",
            fontSize: 15,
            mt: 2,
            height: 50,
            width: "100%",
            textAlign: "center",
            borderRadius: "11px",
            border: "1px solid #FF5757",
            color: "#FF5757",
          }}
          onClick={() => history.push("/search")}
        >
          <Typography sx={{ fontFamily: "Neuton", fontSize: "15px" }}>
            Search Friend's Wishlist
          </Typography>
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          sx={{
            borderColor: "black",
            textTransform: "none",
            fontSize: 15,
            mt: 2,
            height: 50,
            width: "100%",
            textAlign: "center",
            borderRadius: "11px",
            border: "1px solid #FF5757",
            color: "#FF5757",
          }}
        >
          <Typography sx={{ fontFamily: "Neuton", fontSize: "15px" }}>
            Add Milestones/Events
          </Typography>
        </Button>
      </DialogContent> */}
    </Dialog>
  );
}
