import { Edit, PanToolAlt } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { accountService } from "../../services";

interface AddressPopupProps {
  userId: string;
  name: string;
  address: any;
  open: boolean;
  myProfile: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddressPopup(props: AddressPopupProps) {
  const history = useHistory();

  const { setOpen, open, address, name, myProfile, userId } = props;

  var noAddress: boolean = false;

  if (!address || !address.houseNo || !address.locality || !address.city) {
    noAddress = true;
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "20px",
          color: "#000000",
          letterSpacing: "0.5px",
        }}
      >
        {name}'s Address
      </DialogTitle>
      <DialogContent
        sx={{
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "16px",
          color: "#000000",
          letterSpacing: "0.5px",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          {noAddress ? (
            <Typography>Not available</Typography>
          ) : (
            <>
              <Typography>{address.houseNo}</Typography>
              <Typography>{address.locality}</Typography>
              <Typography>{address.city}</Typography>
              <Typography>{address.pinCode}</Typography>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!noAddress && !myProfile ? null : (
          <Button
            variant="outlined"
            style={{
              height: "48px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#000000",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "20px",
              textTransform: "none",
              marginLeft: "auto",
            }}
            startIcon={
              noAddress ? (
                myProfile ? (
                  <Edit color="inherit" />
                ) : (
                  <PanToolAlt color="inherit" />
                )
              ) : (
                <Edit color="inherit" />
              )
            }
            onClick={() => {
              if (noAddress) {
                if (myProfile) {
                  history.push("/profile/edit");
                } else
                  accountService.requestAddress(userId).then(() => {
                    alert("The user has been notified");
                    handleClose();
                  });
              } else history.push("/profile/edit");
            }}
          >
            {noAddress ? (myProfile ? "Edit" : "Ask for address") : "Edit"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
