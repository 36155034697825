import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

interface AddressPopupProps {
  name: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SantaPopup(props: AddressPopupProps) {
  const { setOpen, open, name } = props;

  console.log(name);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "20px",
          color: "#000000",
          letterSpacing: "0.5px",
        }}
      >
        Your Secret Santa
      </DialogTitle>
      <DialogContent
        sx={{
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "16px",
          color: "#000000",
          letterSpacing: "0.5px",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          <Typography>{name}</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
