import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import productsArray from "./products";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { wishlistService } from "../../services/wishlist.service";
import { accountService } from "../../services";
import { WishlistModel } from "../../shared/models/WishlistModel";
import WishlistDialog from "./WishlistDialog";
import { wishService } from "../../services/wish.service";
import { CardActions, Paper } from "@mui/material";
import { Mixpanel } from "../../helpers/mixpanel-helper";

function CreateProduct(
  product: any,
  setSelectedProduct: React.Dispatch<any>,
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    Mixpanel.track("Products Page Opened");
  }, []);

  return (
    <Grid item lg={6} md={6} sm={6} xs={6}>
      {/* <Paper variant="outlined">
        <img src={product.imageUrls[0]} height="191px" width="191px"></img>
        <Paper
          sx={{ width: "193px", height: "67px", background: "#000000" }}
        ></Paper>
      </Paper> */}

      <Paper elevation={4} sx={{ borderRadius: "15px" }}>
        <Card className="cardStyle" raised={true}>
          <CardMedia
            // onClick={() => {
            //   window.open(product.productUrl, "_blank");
            // }}
            component="img"
            alt="product image"
            height="191px"
            width="191px"
            image={product.imageUrls[0]}
          />
          <CardContent sx={{ background: "#FFFFFF" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid
                // onClick={() => {
                //   window.open(product.productUrl, "_blank");
                // }}
                item
                xs={12}
              >
                <Typography
                  sx={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textTransform: "none",
                    textAlign: "left",
                    letterSpacing: "0.2px",
                  }}
                >
                  {product.name}
                </Typography>
              </Grid>
              {/* <Grid item xs={4}>
              <Button
                style={{
                  color: "#FF5757",
                  fontSize: "medium",
                }}
                onClick={() => {
                  setSelectedProduct(product);
                  setDialogOpen(true);
                }}
              >
                <ControlPointIcon fontSize="large" />
              </Button>
            </Grid> */}
              <Grid
                // onClick={() => {
                //   window.open(product.productUrl, "_blank");
                // }}
                item
                xs={8}
              >
                <Typography
                  sx={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textTransform: "none",
                    color: "rgba(0, 0, 0)",
                    textAlign: "left",
                  }}
                >
                  <b>{product.price}</b>
                </Typography>
              </Grid>
              {/* <Grid item xs={4}>
              <Button
                fontSize="small"
                style={{
                  color: "#FF5757",
                }}
              >
                <OpenInNewIcon />
              </Button>
            </Grid> */}
            </Grid>
          </CardContent>
          {/* <Typography
            sx={{ height: 60 }}
            gutterBottom
            variant="body1"
            component="div"
          >
            {product.name}
          </Typography>
          <Typography
            sx={{ height: 20 }}
            variant="body1"
            color="text.secondary"
          >
            {product.price}
          </Typography>
        </CardContent> */}
          <CardActions>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    width: "100%",
                    borderRadius: "11px",
                    border: "1px solid #FF5757",
                    color: "#000000",
                    textTransform: "none",
                    fontFamily: "Neuton",
                    fontStyle: "Normal",
                    fontWeight: "300",
                    fontSize: "12px",
                    lineHeight: "12px",
                  }}
                  onClick={() => {
                    window.open(product.productUrl, "_blank");
                  }}
                >
                  View Product
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    width: "100%",
                    borderRadius: "11px",
                    border: "1px solid #FF5757",
                    color: "#FFFFFF",
                    backgroundColor: "#FF5757",
                    fontFamily: "Neuton",
                    fontStyle: "Normal",
                    fontWeight: "300",
                    fontSize: "12px",
                    lineHeight: "12px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setSelectedProduct(product);
                    setDialogOpen(true);
                  }}
                >
                  Add to Wishlist
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Paper>
    </Grid>
  );
}

function ProductsPage() {
  const [wishlists, setWishlists] = useState<Array<WishlistModel> | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  async function onClickFunc(wishlistId: string) {
    wishlistService.updateWishlist(wishlistId, {
      status: "saved",
    });
    await wishService.addWishToWishlist(wishlistId, {
      user: accountService.userValue.id,
      name: selectedProduct.name,
      link: selectedProduct.productUrl,
    });
    alert("Wish added to your Wishlist");
  }

  useEffect(() => {
    wishlistService
      .getWishlistsByUserId(accountService.userValue.id)
      .then((res) => {
        setWishlists(res);
      });
  }, []);

  return (
    <div style={{ marginBottom: 100 }} className="NoApp">
      <Box
        sx={{
          p: 2,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "12px",
          lineHeight: "12px",
          textTransform: "none",
          color: "#000000",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Neuton",
            color: "#000000",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "30px",
            lineHeight: "30px",
          }}
        >
          Add items to your Wishlist!
        </Typography>
      </Box>
      {/* <Container
        fixed
        className="productCategory"
        style={{
          border: "1px solid #000000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        sx={{
          background: `rgba(103, 80, 164, 0.06)`,
          borderRadius: 3,
          padding: "20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Card
              value="all"
              className="cardStyle"
              variant="outlined"
              style={{ border: "1px solid #000000" }}
              sx={{ maxWidth: 345, maxHeight: 370, borderRadius: 3 }}
              raised="true"
            >
              <CardMedia
                component="img"
                alt="product image"
                height="140"
                style={{
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%",
                  borderRadius: "100%",
                  border: "2px solid #000000",
                }}
                image="https://cdn-icons-png.flaticon.com/512/5110/5110777.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  All
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Card
              className="cardStyle"
              variant="outlined"
              style={{ border: "1px solid #000000" }}
              sx={{ maxWidth: 345, maxHeight: 370, borderRadius: 3 }}
              raised="true"
            >
              <CardMedia
                component="img"
                style={{
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%",
                  borderRadius: "100%",
                  border: "2px solid #000000",
                }}
                alt="product image"
                height="140"
                image="https://cdn-icons-png.flaticon.com/512/1946/1946430.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Trending
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Card
              className="cardStyle"
              variant="outlined"
              style={{ border: "1px solid #000000" }}
              sx={{ maxWidth: 345, maxHeight: 370, borderRadius: 3 }}
              raised="true"
            >
              <CardMedia
                component="img"
                alt="product image"
                height="140"
                style={{
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%",
                  borderRadius: "100%",
                  border: "2px solid #000000",
                }}
                image="https://cdn-icons-png.flaticon.com/512/3159/3159002.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  For You
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Card
              className="cardStyle"
              variant="outlined"
              style={{ border: "1px solid #000000" }}
              sx={{ maxWidth: 345, maxHeight: 370, borderRadius: 3 }}
              raised="true"
            >
              <CardMedia
                component="img"
                alt="product image"
                height="140"
                style={{
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%",
                  borderRadius: "100%",
                  border: "2px solid #000000",
                }}
                image="https://cdn-icons-png.flaticon.com/512/1359/1359308.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Favourites
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container> */}
      {/* <Container
        fixed
        style={{
          border: "2px solid #000000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        sx={{
          background: `rgba(103, 80, 164, 0.06)`,
          borderRadius: 3,
          marginTop: "50px",
          marginBottom: "100px",
          padding: "50px",
        }}
      > */}
      <Grid container sx={{ mt: 1 }} spacing={1}>
        {productsArray.map((product) =>
          CreateProduct(product, setSelectedProduct, setDialogOpen)
        )}
      </Grid>
      {/* </Container> */}
      <WishlistDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        onClickFunc={onClickFunc}
        wishlists={wishlists ? wishlists : []}
      />
    </div>
  );
}

export default ProductsPage;
