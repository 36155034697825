import TopAppBar from "./modules/TopAppBar";
import LandingPage from "./pages/landing-page/LandingPage";
import { Switch, Redirect, useLocation, Route } from "react-router-dom";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { PrivateRoute } from "./helpers";
import HomePage from "./pages/home-page/HomePage";
import Onboarding from "./pages/Onboarding/Onboarding";
import UserProfile from "./pages/profile/UserProfile";
import EditProfile from "./pages/profile/EditProfile";
import CreateWishlist from "./pages/wishlist/CreateWishlist";
import BottomAppBar from "./modules/BottomAppBar";
import { accountService } from "./services";
import Wishlists from "./pages/wishlist/Wishlists";
import UpdateWishlist from "./pages/wishlist/UpdateWishlist";
import SearchWishlist from "./pages/search-wishlist/SearchWishlist";
import PublicProfile from "./pages/search-wishlist/PublicProfile";
import PublicWishlist from "./pages/search-wishlist/PublicWishlist";
import FulfilledWishes from "./pages/fulfilled-wishes/FulfilledWishes";
import ReservedWishes from "./pages/reserved-wishes/ReservedWishes";
import Exchanges from "./pages/exchange/Exchanges";
import ProductsPage from "./pages/products-page/ProductsPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CreateExchange from "./pages/exchange/CreateExchange";
import UpdateExchange from "./pages/exchange/UpdateExchange";
import InvitePeople from "./pages/exchange/InvitePeople";
import ComingSoon from "./pages/ComingSoon";
import ExchangeMembers from "./pages/exchange/ExchangeMembers";
import PostMatch from "./pages/profile/PostMatch";
import { createTheme, ThemeProvider } from "@mui/material";
import MyProfile from "./pages/profile/MyProfile";

function App() {
  const { pathname } = useLocation();

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#000000",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          background: "#F5F5F5",
          // background: "url('/backgroundDark.gif')",
          // backgroundSize: "cover",
        }}
      >
        <div
          style={{
            background: "#FFFFFF",
            // background: "url('/backgroundDark.gif')",
            // backgroundRepeat: "no-repeat",
            minHeight: "100vh",
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
            border: "1px solid #000000",
            // overflow: "scroll",
          }}
        >
          {pathname !== "/coming-soon" && <TopAppBar />}
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <PrivateRoute exact path="/" component={LandingPage} />
            <PrivateRoute exact path="/home" component={HomePage} />
            <PrivateRoute exact path="/onboarding" component={MyProfile} />
            <PrivateRoute exact path="/profile" component={MyProfile} />
            <PrivateRoute exact path="/profile/edit" component={MyProfile} />
            <PrivateRoute exact path="/wishlists" component={Wishlists} />
            <PrivateRoute
              exact
              path="/wishlist/add/:wishlistId"
              component={CreateWishlist}
            />
            <PrivateRoute
              exact
              path="/wishlist/view"
              component={UpdateWishlist}
            />
            <PrivateRoute exact path="/search" component={SearchWishlist} />
            <PrivateRoute
              exact
              path="/public-profile/:id"
              component={PublicProfile}
            />
            <PrivateRoute
              exact
              path="/public-wishlist/:id"
              component={PublicWishlist}
            />
            <PrivateRoute
              exact
              path="/fulfilled-wishes"
              component={FulfilledWishes}
            />
            <PrivateRoute
              exact
              path="/reserved-wishes"
              component={ReservedWishes}
            />
            <PrivateRoute exact path="/exchanges" component={Exchanges} />
            <PrivateRoute
              exact
              path="/exchange/add"
              component={CreateExchange}
            />
            <PrivateRoute
              exact
              path="/exchange/invite/:exchangeId"
              component={InvitePeople}
            />
            <PrivateRoute
              exact
              path="/exchange/members"
              component={ExchangeMembers}
            />
            <PrivateRoute
              exact
              path="/exchange/user-profile"
              component={PostMatch}
            />
            <PrivateRoute exact path="/products" component={ProductsPage} />
            <Route path="/policy" component={PrivacyPolicy} />
            <Route path="/landing" component={LandingPage} />
            <Route path="/coming-soon" component={ComingSoon} />
            <Route
              exact
              path="/exchange/view/:exchangeId"
              component={UpdateExchange}
            />
          </Switch>

          {accountService.userValue &&
            !accountService.userValue.firstLogin &&
            !(window.location.href.indexOf("/wishlist/add") > -1) && (
              <BottomAppBar />
            )}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
