import { Visibility } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { accountService } from "../../services";
import { wishlistService } from "../../services/wishlist.service";
import { WishlistModel } from "../../shared/models/WishlistModel";

export default function PublicProfile() {
  const [wishlists, setWishlists] = useState<null | Array<WishlistModel>>(null);
  const [user, setUser] = useState<null | any>(null);

  const history = useHistory();

  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    wishlistService.getWishlistsByUserId(id).then((res) => {
      setWishlists(res.filter((wishlist: any) => wishlist.status === "saved"));
    });
    // accountService.getById();
  }, [id]);

  return (
    <Box sx={{ p: 2, mb: 5 }}>
      {/* <Typography variant="h5">{wishlist.name}</Typography> */}
      {wishlists && wishlists.length === 0 && (
        <Typography
          style={{
            fontFamily: "Neuton",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "20px",
            letterSpacing: "0.5px",
            color: "#000000",
          }}
        >
          No Wishlists found
        </Typography>
      )}
      {wishlists &&
        wishlists.map((wishlist) => (
          <Paper
            sx={{
              mt: 3,
              mb: 3,
              p: 2,
              border: "1px solid #000000",
              borderRadius: "20px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/public-wishlist/" + wishlist._id)}
            elevation={0}
          >
            <Grid container justifyContent="space-between">
              <Grid item sx={{ mt: 0.5 }}>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "20px",

                    display: "flex",
                    alignItems: "center",
                    letterSpacing: "0.5px",

                    color: "#000000",
                  }}
                >
                  {wishlist.name}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                    letterSpacing: "0.5px",
                    color: "#000000",
                    marginTop: 10,
                  }}
                >
                  <b>Wishes</b> : {wishlist.items ? wishlist.items.length : 0}
                </Typography>
                {wishlist.updatedAt && (
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: "Neuton",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "15px",
                      lineHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                      letterSpacing: "0.5px",
                      color: "#000000",
                      marginTop: 10,
                    }}
                  >
                    <b>Updated At</b> :{" "}
                    {wishlist.updatedAt.toString().split("T")[0]}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    color: "Black",
                    border: "1px solid #000000",
                    borderRadius: "18px",
                    fontFamily: "Neuton",
                    textTransform: "none",
                    mt: 1.5,
                  }}
                  color="secondary"
                  variant="outlined"
                  startIcon={<Visibility />}
                  onClick={() =>
                    history.push("/public-wishlist/" + wishlist._id)
                  }
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ))}
    </Box>
  );
}
