import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { wishService } from "../../services/wish.service";
import { WishlistItem } from "../../shared/models/WishlistItem";
import ShowFulfilledItem from "./ShowFulfilledItem";

export default function FulfilledWishes() {
  const [wishes, setWishes] = React.useState<null | Array<WishlistItem>>(null);

  const deleteItem = (itemIdx: number) => {
    if (wishes) {
      wishService.deleteWish(wishes[itemIdx]._id ?? "");
      const nextItems = wishes.filter((wish, idx) => idx !== itemIdx);
      setWishes(nextItems);
    }
  };

  useEffect(() => {
    wishService.getFulfilledWishes().then((res) => {
      setWishes(res);
    });
  }, []);

  const updateItem = (itemIdx: number, details: any) => {
    if (wishes) {
      const wishId = wishes[itemIdx]._id;

      if (wishId) {
        wishService.updateWish(wishId, details);
      }

      var nextItems = [];

      if (details.fulfilled === false)
        nextItems = wishes.filter((item, idx) => idx !== itemIdx);
      else
        nextItems = wishes.map((wish, idx) => {
          if (itemIdx === idx) {
            return { ...wish, ...details };
          } else {
            // No change
            return wish;
          }
        });

      setWishes(nextItems);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Neuton",
          color: "#000000",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "30px",
          lineHeight: "30px",
        }}
      >
        Fulfilled wishes
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 1,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "16px",
          letterSpacing: "0.5px",
          color: "#000000",
          lineHeight: "16px",
        }}
      >
        All the wishes that got fulfilled can be tracked here
      </Typography>
      <div
        style={{
          flex: 1,
          height: "1px",
          backgroundColor: "black",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      />

      {wishes ? (
        wishes.map((item, idx) => (
          <Grid container sx={{ mt: 1, mb: 1 }}>
            <ShowFulfilledItem
              deleteItem={deleteItem}
              item={item}
              idx={idx}
              updateItem={updateItem}
            />
          </Grid>
        ))
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
}
