import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { accountService } from "../services";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  createTheme,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { ArrowBack, MenuOutlined } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

export default function TopAppBar() {
  const location = useLocation();
  const history = useHistory();
  const rootpath = location.pathname.split("/")[1];
  const [loading, setLoading] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElHamburg, setAnchorElHamburg] = useState<null | HTMLElement>(
    null
  );

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseHamburgMenu = () => {
    setAnchorElHamburg(null);
  };

  const handleOpenHamburgMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElHamburg(event.currentTarget);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Backdrop
        sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar
        elevation={0}
        sx={{
          borderRadius: 3,
          background: "white",
          color: "black",
          // background: "url('/background1.gif')",
          // backgroundSize: "contain",
        }}
        position="static"
      >
        <Toolbar>
          <Box sx={{ flexGrow: 0, minWidth: "22%" }}>
            {accountService.userValue &&
              !accountService.userValue.firstLogin && (
                <>
                  {rootpath === "home" ? (
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      onClick={handleOpenHamburgMenu}
                    >
                      <MenuOutlined />
                    </IconButton>
                  ) : (
                    <ArrowBack onClick={() => history.goBack()} />
                  )}

                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElHamburg}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElHamburg)}
                    onClose={handleCloseHamburgMenu}
                  >
                    {/* <MenuItem
                    key="home"
                    onClick={() => {
                      handleCloseHamburgMenu();
                      history.push("/");
                    }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        fontFamily: "Neuton",
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: "16px",
                        lineHeight: "16px",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Home
                    </Typography>
                  </MenuItem> */}
                    <MenuItem
                      key="wishlists"
                      onClick={() => {
                        handleCloseHamburgMenu();
                        history.push("/wishlists");
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontFamily: "Neuton",
                          fontStyle: "normal",
                          fontWeight: "300",
                          fontSize: "16px",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        Wishlists
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      key="exchanges"
                      onClick={() => {
                        handleCloseHamburgMenu();
                        history.push("/exchanges");
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontFamily: "Neuton",
                          fontStyle: "normal",
                          fontWeight: "300",
                          fontSize: "16px",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        Events
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      key="products"
                      onClick={() => {
                        handleCloseHamburgMenu();
                        history.push("/products");
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontFamily: "Neuton",
                          fontStyle: "normal",
                          fontWeight: "300",
                          fontSize: "16px",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        Explore Products
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      key="reserved_wishes"
                      onClick={() => {
                        handleCloseHamburgMenu();
                        history.push("/reserved-wishes");
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontFamily: "Neuton",
                          fontStyle: "normal",
                          fontWeight: "300",
                          fontSize: "16px",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        Reserved Wishes
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      key="fulfilled_wishes"
                      onClick={() => {
                        handleCloseHamburgMenu();
                        history.push("/fulfilled-wishes");
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontFamily: "Neuton",
                          fontStyle: "normal",
                          fontWeight: "300",
                          fontSize: "16px",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        Fulfilled Wishes
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
          </Box>
          <Typography
            onClick={() => history.push("/")}
            variant="h6"
            component="div"
            sx={{
              mt: 0.5,
              ml: 1,
              flexGrow: 1,
              cursor: "pointer",
              fontFamily: "Chewy",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "26px",
              lineHeight: "24px",
            }}
          >
            MakeYourWish
          </Typography>
          {accountService.userValue ? (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="Remy Sharp"
                  src={accountService.userValue.profilePic}
                />
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {accountService.userValue &&
                  !accountService.userValue.firstLogin && (
                    <>
                      <MenuItem
                        key="profile"
                        onClick={() => {
                          handleCloseUserMenu();
                          history.push("/profile");
                        }}
                      >
                        <Typography
                          textAlign="center"
                          sx={{
                            fontFamily: "Neuton",
                            fontStyle: "normal",
                            fontWeight: "300",
                            fontSize: "16px",
                            lineHeight: "16px",
                            letterSpacing: "0.5px",
                          }}
                        >
                          Update Profile
                        </Typography>
                      </MenuItem>
                      {/* <MenuItem
                      key="profile"
                      onClick={() => {
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontFamily: "Neuton",
                          fontStyle: "normal",
                          fontWeight: "300",
                          fontSize: "16px",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        Add Milestones
                      </Typography>
                    </MenuItem> */}
                    </>
                  )}
                <MenuItem
                  key="logout"
                  onClick={() => {
                    handleCloseUserMenu();
                    accountService.logout();
                    window.location.reload();
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: "Neuton",
                      fontStyle: "normal",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "16px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : null}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
