import Typography from "@mui/material/Typography";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { Backdrop, CircularProgress, Grid, Paper } from "@mui/material";
import "../../App.css";
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google";
import { accountService } from "../../services";
import { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Mixpanel } from "../../helpers/mixpanel-helper";

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function LandingPage({ location }: { location: any }) {
  Mixpanel.track("Landing Page Opened");

  var exchangeId = location.state ? location.state.exchangeId : null;

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const responseGoogle = (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      setLoading(true);
      accountService
        .socialSignin("Google", credentialResponse.credential)
        .then((res) => {
          setLoading(false);
          if (!res.firstLogin) {
            if (exchangeId) history.push("/exchange/view/" + exchangeId);
            else history.push("/");
          } else {
            history.push("/onboarding", { exchangeId: exchangeId });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          alert("Google Login Failed");
        });
    } else {
      setLoading(false);
      alert("Google Login Failed");
    }
  };

  if (accountService.userValue) {
    return (
      <Redirect
        to={{
          pathname: "/home",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Grid
      // sx={{ height: "85vh" }}
      container
      justifyContent="space-around"
      alignItems="center"
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Grid item sx={{ textAlign: "center" }} xs={12}> */}
      <img
        style={{
          height: "400px",
          width: "95%",
          borderRadius: "15px",
          border: "1px solid #000000",
          marginBottom: "10px",
        }}
        src="/landing1.jpeg"
      ></img>
      {/* </Grid> */}
      {/* <Grid item sx={{ textAlign: "center" }} xs={11}>
          <Paper
            variant="outlined"
            sx={{ borderRadius: 3, overflow: "hidden" }}
          >
            <img src="/landing.png"></img>
          </Paper>
        </Grid> */}
      <Grid item sx={{ textAlign: "center", m: 3 }}>
        <GoogleOAuthProvider clientId="235778210711-1gt21c247p03mlmu5ggstasog204msfc.apps.googleusercontent.com">
          <GoogleLogin
            shape="pill"
            text="signin"
            // sx={{}}
            onSuccess={(credentialResponse) => {
              responseGoogle(credentialResponse);
            }}
            onError={() => {
              alert("Google Login Failed");
            }}
          />
        </GoogleOAuthProvider>
      </Grid>
      <Grid item sx={{ textAlign: "center" }} xs={12}>
        <img
          style={{
            marginTop: "10px",
            width: "95%",
            borderRadius: "15px",
            border: "1px solid #000000",
          }}
          src="/landing2.png"
        ></img>
      </Grid>
      <Grid item sx={{ textAlign: "center" }} xs={11}>
        <Typography variant="caption" display="block" gutterBottom>
          By clicking on Sign Up, you confirm that you agree to our Privacy
          Policy
        </Typography>
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: 12,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => window.open("/policy")}
          color="blue"
        >
          Privacy Policy
        </Typography>
      </Grid>
    </Grid>
    // <div className="App">
    //   <header className="App-header">
    //     <div className="landingHomeDiv">
    //       <ThemeProvider theme={theme}>
    //         <Typography variant="h3" gutterBottom>
    //           <b>MakeYourWish</b>
    //         </Typography>
    //         <Typography variant="h5" gutterBottom>
    //           We make it happen
    //         </Typography>
    //       </ThemeProvider>
    //       <Grid sx={{ mt: 5 }} container justifyContent="center">
    //         <Grid item xs={12} md={10} lg={7}>
    //           <img
    //             style={{ width: "100%" }}
    //             src="/final_signin_page_banner.png"
    //           ></img>
    //         </Grid>
    //       </Grid>
    //       <br />
    //       <Button variant="contained" sx={{ m: 4 }}>
    //         Get Started
    //       </Button>
    //     </div>
    //   </header>
    // </div>
  );
}

// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import {
//   createTheme,
//   responsiveFontSizes,
//   ThemeProvider,
// } from "@mui/material/styles";
// import {
//   autocompleteClasses,
//   Backdrop,
//   CircularProgress,
//   Grid,
//   Paper,
// } from "@mui/material";
// import "../../App.css";
// import {
//   CredentialResponse,
//   GoogleLogin,
//   GoogleOAuthProvider,
// } from "@react-oauth/google";
// import { accountService } from "../../services";
// import { useState } from "react";
// import { useHistory } from "react-router-dom";
// let theme = createTheme();
// theme = responsiveFontSizes(theme);

// export default function LandingPage() {
//   const history = useHistory();
//   const [loading, setLoading] = useState(false);
//   const responseGoogle = (credentialResponse: CredentialResponse) => {
//     if (credentialResponse.credential) {
//       setLoading(true);
//       accountService
//         .socialSignin("Google", credentialResponse.credential)
//         .then(() => {
//           setLoading(false);
//           const user = accountService.userValue;
//           if (user.phone) {
//             // const { from } = { from: { pathname: "/" } };
//             history.push("/");
//           } else {
//             // const { from } = { from: { pathname: "/onboarding" } };
//             history.push("/onboarding");
//           }
//         })
//         .catch((err) => {
//           setLoading(false);
//           console.log(err);
//           alert("Google Login Failed");
//         });
//     } else {
//       setLoading(false);
//       alert("Google Login Failed");
//     }
//   };

//   return (
//     <div
//       style={{
//         background: "url('/signup.png')",
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//         height: "730px",
//       }}
//     >
//       <Grid
//         sx={{ height: "85vh" }}
//         container
//         justifyContent="space-around"
//         alignItems="center"
//       >
//         <Backdrop
//           sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//           open={loading}
//         >
//           <CircularProgress color="inherit" />
//         </Backdrop>
//         {/* <Grid item sx={{ textAlign: "center" }} xs={11}>
//           <Paper
//             variant="outlined"
//             sx={{ borderRadius: 3, overflow: "hidden" }}
//           >
//             <img src="/landing.png"></img>
//           </Paper>
//         </Grid> */}
//         <Grid item sx={{ textAlign: "center", mt: -18 }}>
//           <GoogleOAuthProvider clientId="235778210711-1gt21c247p03mlmu5ggstasog204msfc.apps.googleusercontent.com">
//             <GoogleLogin
//               shape="pill"
//               text="signin"
//               // sx={{}}
//               onSuccess={(credentialResponse) => {
//                 responseGoogle(credentialResponse);
//               }}
//               onError={() => {
//                 alert("Google Login Failed");
//               }}
//             />
//           </GoogleOAuthProvider>
//         </Grid>
//         <Grid item sx={{ textAlign: "center" }} xs={11}>
//           <Typography variant="caption" display="block" gutterBottom>
//             By clicking on Sign Up, you confirm that you agree to our Privacy
//             Policy
//           </Typography>
//           <Typography
//             sx={{
//               fontWeight: 300,
//               fontSize: 12,
//               cursor: "pointer",
//               textDecoration: "underline",
//             }}
//             onClick={() => window.open("/policy")}
//             color="blue"
//           >
//             Privacy Policy
//           </Typography>
//         </Grid>
//       </Grid>
//     </div>
//     // <div className="App">
//     //   <header className="App-header">
//     //     <div className="landingHomeDiv">
//     //       <ThemeProvider theme={theme}>
//     //         <Typography variant="h3" gutterBottom>
//     //           <b>MakeYourWish</b>
//     //         </Typography>
//     //         <Typography variant="h5" gutterBottom>
//     //           We make it happen
//     //         </Typography>
//     //       </ThemeProvider>
//     //       <Grid sx={{ mt: 5 }} container justifyContent="center">
//     //         <Grid item xs={12} md={10} lg={7}>
//     //           <img
//     //             style={{ width: "100%" }}
//     //             src="/final_signin_page_banner.png"
//     //           ></img>
//     //         </Grid>
//     //       </Grid>
//     //       <br />
//     //       <Button variant="contained" sx={{ m: 4 }}>
//     //         Get Started
//     //       </Button>
//     //     </div>
//     //   </header>
//     // </div>
//   );
// }
