import { Favorite, PanTool, PanToolAlt, Visibility } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { exchangeService } from "../../services/exchange.service";
import { wishlistService } from "../../services/wishlist.service";

interface WishlistPopupProps {
  exchangeId: string;
  userId: string;
  name: string;
  wishlist: any;
  open: boolean;
  myProfile: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Wishlist(props: WishlistPopupProps) {
  const [openLoader, setOpenLoader] = useState(false);
  const history = useHistory();

  const { setOpen, open, wishlist, name, myProfile, exchangeId, userId } =
    props;

  const handleClose = () => {
    setOpen(false);
  };

  const initWishlist = async () => {
    const res = await wishlistService.addToWishlist({
      name: "My Wishlist",
      status: "draft",
      exchange: exchangeId,
    });

    return res._id;
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card sx={{ maxWidth: 345, background: "#FBD6C6", borderRadius: "15px" }}>
        <CardHeader
          sx={{
            fontFamily: "Neuton",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "20px",
            color: "#000000",
          }}
          title={`${name}'s Wishlist`}
        />
        <CardMedia
          component="img"
          height="194"
          image="/updateWishlist.png"
          alt="Paella dish"
        />
        <CardContent>
          <Typography
            sx={{
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "16px",
              color: "#000000",
            }}
            variant="h6"
            color="text.secondary"
          >
            {wishlist ? wishlist.name : "Secret Santa Wishlist"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "16px",
              color: "#000000",
            }}
            variant="body2"
            color="text.secondary"
          >
            Wishes: {wishlist ? wishlist.items.length : 0}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Button
            variant="outlined"
            style={{
              height: "48px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#FFFFFF",
              backgroundColor: "#FF5757",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "16px",
              textTransform: "none",
              marginLeft: "auto",
            }}
            startIcon={
              wishlist ? (
                <Visibility color="inherit" />
              ) : myProfile ? (
                <Favorite color="inherit" />
              ) : (
                <PanToolAlt color="inherit" />
              )
            }
            onClick={async () => {
              if (wishlist) {
                if (!myProfile)
                  history.push(
                    "/public-wishlist/" + (wishlist ? wishlist._id : "")
                  );
                else {
                  if (wishlist.status === "draft") {
                    setOpenLoader(true);
                    const wishlistId = await initWishlist();
                    setOpenLoader(false);
                    history.push("/wishlist/add/" + wishlistId, {
                      exchangeId,
                    });
                  } else
                    history.push("/wishlist/view", {
                      data: wishlist,
                    });
                }
              } else {
                if (myProfile) {
                  setOpenLoader(true);
                  const wishlistId = await initWishlist();
                  setOpenLoader(false);
                  history.push("/wishlist/add/" + wishlistId, {
                    exchangeId,
                  });
                } else {
                  exchangeService
                    .requestWishlist(exchangeId, userId)
                    .then(() => {
                      alert("The user has been notified");
                      handleClose();
                    });
                }
              }
            }}
          >
            {wishlist
              ? "View Wishlist"
              : myProfile
              ? "Create Wishlist"
              : "Ask for Wishlist"}
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
}
