import { Add, Search } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Mixpanel } from "../../helpers/mixpanel-helper";
import Controlled6Carousel from "../../modules/Controlled6Carousel";
import { accountService } from "../../services";
import { wishlistService } from "../../services/wishlist.service";
import { WishlistItem } from "../../shared/models/WishlistItem";
import AddItems from "./AddItems";
import SaveWishlistDialog from "./SaveWishlistDialog";

let timer: any;

export default function CreateWishlist({ location }: { location: any }) {
  let newWishlistId = useParams<{ wishlistId: string }>().wishlistId;

  var exchangeId = location.state ? location.state.exchangeId : null;
  // var data = location.state ? location.state.data : null;
  const history = useHistory();

  const [saveWishlistDialogOpen, setSaveWishlistDialogOpen] =
    React.useState(false);

  const [items, setItems] = React.useState<Array<WishlistItem>>([
    {
      placeholder: "Sunglasses",
      user: accountService.userValue.id,
    },
    { placeholder: "Indoor Plants", user: accountService.userValue.id },
    { placeholder: "Boobleheads", user: accountService.userValue.id },
  ]);
  const [name, setName] = React.useState("");
  const status = "draft";
  const [wishlistId, setWishlistId] = React.useState(null);

  const [openLoader, setOpenLoader] = React.useState(false);

  React.useEffect(() => {
    Mixpanel.track("Create Wishlist Opened");

    setOpenLoader(true);

    if (newWishlistId) {
      wishlistService.getWishlistByWishlistId(newWishlistId).then((res) => {
        setOpenLoader(false);
        if (res.items && res.items.length > 0) setItems(res.items);
        setName(res.name);
        setWishlistId(res._id);
      });
    }
  }, []);

  const initWishlist = async () => {
    const res = await wishlistService.addToWishlist({
      name: name && name.length > 0 ? name : "My Wishlist",
      status,
      exchange: exchangeId,
    });

    setWishlistId(res._id);

    return res._id;
  };

  React.useEffect(() => {
    if (wishlistId && status === "draft") {
      timer = window.setTimeout(async () => {
        wishlistService.updateWishlist(wishlistId, {
          name: name && name.length > 0 ? name : "My Wishlist",
        });
      }, 1000);
    }

    return () => {
      window.clearTimeout(timer);
    };
  }, [name]);

  function checkNoWishes() {
    const placeholdersRemoved = items.map(
      ({ placeholder, ...keepAttrs }) => keepAttrs
    );
    const noNamesRemoved = placeholdersRemoved.filter((item) =>
      item.name ? (item.name.length > 0 ? true : false) : false
    );

    if (noNamesRemoved.length === 0) return true;

    return false;
  }

  return (
    <Grid
      sx={{ p: 2 }}
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid sx={{ width: "100%" }} xs={12} item>
        <TextField
          InputLabelProps={{
            style: {
              fontFamily: "Neuton",
              fontSize: "16px",
            },
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
              fontFamily: "Neuton",
            },
          }}
          inputProps={{
            min: 0,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
            },
          }}
          color="secondary"
          style={{
            width: "100%",
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
          sx={{ "& fieldset": { border: "none" } }}
          id="outlined-basic"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder="My Wishlist"
          label="Wishlist Name"
          variant="filled"
          size="small"
        />
      </Grid>
      {/* <Grid item xs={12}> */}
      <Box sx={{ width: "100%", mt: 1 }}>
        <Controlled6Carousel></Controlled6Carousel>
      </Box>
      {/* </Grid> */}

      <Grid sx={{ width: "100%", mt: 2 }} item>
        <AddItems
          initWishlist={initWishlist}
          wishlistId={wishlistId}
          disabled={false}
          items={items}
          setItems={setItems}
        />
      </Grid>
      <Grid container spacing={1} textAlign="center">
        <Grid sx={{ mt: 0 }} xs={6} item>
          <Button
            color="inherit"
            style={{
              width: "80%",
              height: "35px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#000000",
              textTransform: "none",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "16px",
            }}
            variant="outlined"
            startIcon={<Add />}
            onClick={() =>
              setItems([...items, { user: accountService.userValue.id }])
            }
          >
            Add Rows
          </Button>
        </Grid>
        <Grid xs={6} item>
          <Button
            color="inherit"
            style={{
              width: "80%",
              height: "35px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#000000",
              textTransform: "none",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "16px",
            }}
            disabled={checkNoWishes()}
            variant="outlined"
            startIcon={<Search />}
            onClick={() => history.push("/products")}
          >
            Must Haves
          </Button>
        </Grid>
        <Grid sx={{ mt: 0 }} xs={12} item>
          <Button
            color="secondary"
            variant="contained"
            style={{
              width: "60%",
              height: "35px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#FFFFFF",
              backgroundColor: "#FF5757",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "16px",
              textTransform: "none",
            }}
            sx={{
              marginBottom: 10,
            }}
            onClick={() => {
              setOpenLoader(true);
              const placeholdersRemoved = items.map(
                ({ placeholder, ...keepAttrs }) => keepAttrs
              );
              const noNamesRemoved = placeholdersRemoved.filter((item) =>
                item.name ? (item.name.length > 0 ? true : false) : false
              );

              if (wishlistId) {
                wishlistService
                  .updateWishlist(wishlistId, {
                    name: name && name.length > 0 ? name : "My Wishlist",
                    status: "saved",
                    items: noNamesRemoved,
                  })
                  .then(() => {
                    setSaveWishlistDialogOpen(true);
                    setOpenLoader(false);
                  })
                  .catch((error) => {
                    setOpenLoader(false);
                    alert("An error occurred. Please try again.");
                  });
              }
            }}
          >
            Save Wishlist
          </Button>
        </Grid>
      </Grid>
      <SaveWishlistDialog
        open={saveWishlistDialogOpen}
        setOpen={setSaveWishlistDialogOpen}
      />
    </Grid>
  );
}
