import * as React from "react";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { SearchOutlined } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";

export default function InputWithIcon({
  query,
  setQuery,
  searchFunc,
}: {
  query: null | string;
  setQuery: React.Dispatch<React.SetStateAction<string | null>>;
  searchFunc: () => void;
}) {
  return (
    <Grid container spacing={2}>
      <Grid xs={8} item>
        <TextField
          value={query}
          sx={{
            width: "100%",
            color: "black",
            borderRadius: "11px",
            border: "1px solid #000000",
            "& fieldset": { border: "none" },
          }}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter Name/Number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined style={{ color: "black" }} />
              </InputAdornment>
            ),
            style: {
              fontFamily: "Neuton",
              fontSize: "16px",
              lineHeight: "16px",
            },
          }}
        />
      </Grid>
      <Grid xs={4} item>
        <Button
          sx={{ height: 55, background: "#FFFFFF" }}
          style={{
            borderRadius: "11px",
            border: "1px solid #FF5757",
            color: "#FFFFFF",
            backgroundColor: "#FF5757",
            fontFamily: "Neuton",
            fontWeight: "300",
          }}
          variant="outlined"
          startIcon={<SearchOutlined fontSize="small" />}
          onClick={searchFunc}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
}
