import {
  EditOutlined,
  InsertLinkOutlined,
  WhatsApp,
  MailOutline,
  ArrowRightAlt,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import InviteViaLinkPopup from "./InviteViaLinkPopup";
import SelectiveInviteDialog from "./SelectiveInviteDialog";

export default function InvitePeople() {
  const history = useHistory();
  let { exchangeId } = useParams<{ exchangeId: string }>();
  const [selectiveInviteOpen, setSelectiveInviteOpen] =
    useState<boolean>(false);

  const [inviteViaLinkPopup, setInviteViaLinkPopup] = useState<boolean>(false);

  return (
    <Box sx={{ p: 2, textAlign: "center" }}>
      <Typography
        sx={{
          mt: 1,
          mb: 1,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "24px",
          lineHeight: "16px",
          letterSpacing: "0.5px",
        }}
        variant="h6"
      >
        Invite Others to your Exchange!
      </Typography>
      <img
        style={{
          marginTop: 10,
          marginBottom: 10,
          borderRadius: "15px",
          width: "100%",
        }}
        src="/Invite_Image.png"
      ></img>
      {/* <Box
        sx={{
          width: "355px",
          height: "195px",
          backgroundColor: "#FBD6C6",
          borderRadius: "10px",
          m: 2,
        }}
      ></Box> */}

      <Grid container justifyContent="space-around" alignItems="center">
        <Grid onClick={() => setSelectiveInviteOpen(true)} item>
          <Button
            color="inherit"
            style={{
              width: "332px",
              height: "48px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#000000",
              textTransform: "none",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "20px",
            }}
            sx={{
              mt: 1,
              mb: 1,
            }}
            variant="outlined"
            startIcon={
              <MailOutline
                style={{
                  color: "#FF5757",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />
            }
          >
            Invite via Mail
          </Button>
        </Grid>
        <Grid onClick={() => setInviteViaLinkPopup(true)} item>
          <Button
            color="inherit"
            style={{
              width: "332px",
              height: "48px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#000000",
              textTransform: "none",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "20px",
            }}
            sx={{
              mt: 1,
              mb: 1,
            }}
            variant="outlined"
            startIcon={
              <InsertLinkOutlined
                style={{ color: "#FF5757", fontSize: "25px" }}
              />
            }
          >
            Invite via link
          </Button>
        </Grid>
      </Grid>
      {/* <Grid container justifyContent="space-around" alignItems="center">
        <Grid item>
          <Button
            color="inherit"
            style={{
              width: "332px",
              height: "48px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#000000",
              textTransform: "none",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "20px",
            }}
            sx={{
              mt: 1,
              mb: 1,
            }}
            variant="outlined"
            startIcon={
              <EditOutlined style={{ color: "#FF5757", fontSize: "25px" }} />
            }
          >
            Invite via bulk upload
          </Button>
        </Grid>
      </Grid> */}
      <SelectiveInviteDialog
        exchangeId={exchangeId}
        open={selectiveInviteOpen}
        setOpen={setSelectiveInviteOpen}
      />
      <InviteViaLinkPopup
        exchangeId={exchangeId}
        open={inviteViaLinkPopup}
        setOpen={setInviteViaLinkPopup}
      />
      <Button
        endIcon={<ArrowRightAlt />}
        sx={{
          textTransform: "none",
          mb: -1,
          fontFamily: "Neuton",
          color: "#000000",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "18px",
        }}
        onClick={() => history.push("/exchange/view/" + exchangeId)}
      >
        Skip this step
      </Button>
      <br />
      <Typography
        variant="caption"
        sx={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          fontFamily: "Neuton",
        }}
      >
        (you can invite your friends later)
      </Typography>
      <br />
      <br />
    </Box>
  );
}
