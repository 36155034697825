const prod = Object.freeze({
  apiUrl: "https://api.makeyourwish.in",
});

const dev = Object.freeze({
  //apiUrl: "http://localhost:3000",
  // apiUrl: "https://apiv2.bookfish.in",
  apiUrl: "http://localhost:7071",
  // apiUrl: "https://makeyourwish.azurewebsites.net",
});

export const config = process.env.NODE_ENV === "development" ? dev : prod;
