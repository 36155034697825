import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Paper from "@mui/material/Paper";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import {
  OtherHousesOutlined,
  PersonOutlineOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { createTheme } from "@mui/system";
import { styled } from "@mui/material/styles";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: black;
  &.Mui-selected {
    color: #FF5757;
  }
`);

export default function BottomAppBar() {
  const history = useHistory();
  const location = useLocation();

  const rootpath = location.pathname.split("/")[1];

  const [value, setValue] = React.useState(
    rootpath === "exchanges" || rootpath === "exchange"
      ? 2
      : rootpath === "wishlists" || rootpath === "wishlist"
      ? 1
      : rootpath === "search" ||
        rootpath === "public-profile" ||
        rootpath === "public-wishlist"
      ? 3
      : rootpath === "products"
      ? 4
      : 0
  );

  React.useEffect(() => {
    setValue(
      rootpath === "exchanges" || rootpath === "exchange"
        ? 2
        : rootpath === "wishlists" || rootpath === "wishlist"
        ? 1
        : rootpath === "search" ||
          rootpath === "public-profile" ||
          rootpath === "public-wishlist"
        ? 3
        : rootpath === "products"
        ? 4
        : 0
    );
  }, [rootpath]);

  const theme = createTheme({
    typography: {
      fontFamily: '"Neuton",cursive',
      fontWeight: "400",
      fontSize: "14",
    },
  });

  return (
    <Paper
      sx={{
        width: "100%",
        maxWidth: 500,
        position: "fixed",
        bottom: 0,
        padding: 0.1,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          color: "black",
          background: "#FFFFFF",
          boxShadow: "0px 1px 4px 3px rgba(0, 0, 0, 0.25)",
          borderRadius: "15px 15px 0px 0px",
        }}
      >
        <BottomNavigationAction
          sx={{
            ".MuiBottomNavigationAction-label": {
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "18px",
            },
          }}
          label="Home"
          icon={<OtherHousesOutlined />}
          onClick={() => history.push("/")}
        />

        <BottomNavigationAction
          sx={{
            ".MuiBottomNavigationAction-label": {
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "18px",
            },
          }}
          label="Wishlists"
          icon={<FavoriteBorderOutlinedIcon fontSize="medium" />}
          onClick={() => history.push("/wishlists")}
        />
        <BottomNavigationAction
          sx={{
            ".MuiBottomNavigationAction-label": {
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "18px",
            },
          }}
          label="Events"
          icon={<CakeOutlinedIcon fontSize="medium" />}
          onClick={() => history.push("/exchanges")}
        />
        <BottomNavigationAction
          sx={{
            ".MuiBottomNavigationAction-label": {
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "18px",
            },
          }}
          label="Friends"
          icon={<PersonOutlineOutlined fontSize="medium" />}
          onClick={() => history.push("/search")}
        />
        <BottomNavigationAction
          sx={{
            ".MuiBottomNavigationAction-label": {
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "18px",
            },
          }}
          label="Gifts"
          icon={<CardGiftcardIcon />}
          onClick={() => history.push("/products")}
        />
      </BottomNavigation>
    </Paper>
  );
}
