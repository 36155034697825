import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { useHistory } from "react-router-dom";
import { CakeOutlined } from "@mui/icons-material/";
import { wishlistService } from "../../services/wishlist.service";

export default function SquareButton({
  icon,
  text,
  url,
  image,
  setOpenLoader,
}: {
  icon: any;
  text: string;
  url: string;
  image: string;
  setOpenLoader?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const history = useHistory();

  const initWishlist = async () => {
    const res = await wishlistService.addToWishlist({
      name: "My Wishlist",
      status: "draft",
    });

    return res._id;
  };

  return (
    <Paper
      sx={{
        width: "98%",
        height: 180,
        my: 0.5,
        // border: "1px solid #000000",
        background: "#FFFFFF",
        borderRadius: "15px",
        cursor: "pointer",
      }}
      onClick={async () => {
        if (url == "/wishlist/add" && setOpenLoader) {
          setOpenLoader(true);
          const wishlistId = await initWishlist();
          setOpenLoader(false);
          history.push(url + "/" + wishlistId);
        } else history.push(url);
      }}
      // variant="outlined"
      elevation={4}
    >
      <Grid
        sx={{ height: "100%" }}
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {/* <Grid item sx={{ color: "#000000" }}>
          {icon}
        </Grid> */}

        <img
          style={{ width: "70%", height: "120px", marginTop: 0 }}
          src={image}
        ></img>
        <Grid
          item
          sx={{
            color: "#000000",
            fontFamily: "Neuton",
            fontStyle: "Normal",
            fontSize: "24px",
            lineHeight: "20px",
            textTransform: "none",
            mb: 1,
          }}
        >
          {text}
        </Grid>
      </Grid>
    </Paper>
  );
}
