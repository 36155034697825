import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useHistory } from "react-router-dom";

interface SignUpToContinueProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SignUpToContinue(props: SignUpToContinueProps) {
  const { setOpen, open } = props;

  let history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Sign Up to Continue</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/");
            }}
          >
            <b>Sign Up</b>
          </span>{" "}
          {"to continue"}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
