import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

const baseUrl = `${config.apiUrl}/api/wishes`;

export const wishService = {
  updateWish,
  deleteWish,
  getReservedWishes,
  getFulfilledWishes,
  addWishToWishlist,
};

function addWishToWishlist(wishlistId: string, body: any) {
  return fetchWrapper.post(`${baseUrl}/add-to-wishlist/${wishlistId}`, body);
}

function deleteWish(wishlistId: string) {
  return fetchWrapper.delete(`${baseUrl}/${wishlistId}`).then((x) => {
    return x;
  });
}

function updateWish(wishlistId: string, body: any) {
  return fetchWrapper.put(`${baseUrl}/${wishlistId}`, body).then((x) => {
    return x;
  });
}

function getReservedWishes() {
  return fetchWrapper.get(`${baseUrl}/get-reserved`).then((x) => {
    return x;
  });
}

function getFulfilledWishes() {
  return fetchWrapper.get(`${baseUrl}/get-fulfilled`).then((x) => {
    return x;
  });
}
