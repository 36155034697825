import { Grid, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { wishService } from "../../services/wish.service";
import { wishlistService } from "../../services/wishlist.service";
import { WishlistItem } from "../../shared/models/WishlistItem";
import { WishlistModel } from "../../shared/models/WishlistModel";
import ItemDetailsDialog from "../wishlist/ItemDetailsDialog";
import ShowItem from "./ShowItem";

export default function PublicWishlist() {
  const [wishlist, setWishlist] = useState<null | WishlistModel>(null);

  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    wishlistService.getWishlistByWishlistId(id).then((res) => {
      setWishlist(res);
    });
    // accountService.getById();
  }, [id]);

  const updateItem = (itemIdx: number, details: any) => {
    if (wishlist) {
      const nextItems = wishlist.items.map((item, idx) => {
        if (itemIdx === idx) {
          return { ...item, ...details };
        } else {
          // No change
          return item;
        }
      });
      setWishlist({ ...wishlist, items: nextItems });

      const wishId = wishlist.items[itemIdx]._id;

      if (wishId) {
        wishService.updateWish(wishId, details);
      }
    }
  };

  return (
    <Box sx={{ p: 2, mb: 5 }}>
      {wishlist && (
        <>
          <Typography
            sx={{
              mt: 1,
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "30px",
              letterSpacing: "0.5px",
              color: "#000000",
              lineHeight: "30px",
            }}
            variant="h6"
          >
            {wishlist.name}{" "}
          </Typography>
          <Typography
            sx={{
              mt: 2,
              fontFamily: "Neuton",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "24px",
              letterSpacing: "0.5px",
              color: "#000000",
              lineHeight: "16px",
            }}
            variant="h6"
          >
            Start Gifting!
          </Typography>
          {wishlist.items.map((item, idx) => (
            <Grid container sx={{ mt: 1, mb: 1 }}>
              <ShowItem item={item} idx={idx} updateItem={updateItem} />
            </Grid>
          ))}

          {/* <PublicItem wishlist={wishlist} setWishlist={setWishlist}/> */}
        </>
      )}
    </Box>
  );
}
