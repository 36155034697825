import { BehaviorSubject } from "rxjs";
import { config } from "../shared/constants";
// import { User } from "../_shared/models/User";
import { fetchWrapper } from "../helpers";
import Cookies from "js-cookie";

// const userSubject = new BehaviorSubject(
//   JSON.parse(localStorage.getItem("user")!)
// );

export const userSubject: any = new BehaviorSubject(
  JSON.parse(localStorage.getItem("user")!)
);

const baseUrl = `${config.apiUrl}/api/users`;

export const accountService = {
  requestAddress,
  searchUsers,
  // getProfileByUsername,
  sendMail,
  // nearbyUsers,
  signin,
  socialSignin,
  logout,
  refreshToken,
  register,
  // checkUsername,
  verifyEmail,
  forgotPassword,
  validateResetToken,
  resetPassword,
  changePassword,
  getAll,
  getById,
  create,
  update,
  deleteUser,
  uploadImage,
  getNewNotifications,
  // getNewChats,
  // noNewChat,
  noNewNotification,
  // changeBlockStatus,
  delete: _delete,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
};

function requestAddress(userId: string) {
  return fetchWrapper.get(`${baseUrl}/request-address/${userId}`);
}

function searchUsers(query: string) {
  return fetchWrapper.get(`${baseUrl}/search/${query}`);
}

// function changeBlockStatus(userId: string, status: boolean) {
//   return fetchWrapper
//     .put(`${baseUrl}/block-status`, { userId, status })
//     .then((user) => {
//       user = { ...userSubject.value, ...user };
//       localStorage.setItem("user", JSON.stringify(user));
//       userSubject.next(user);
//       return user;
//     })
//     .catch((error) => console.log(error));
// }

// function getProfileByUsername(username: string) {
//   return fetchWrapper.get(`${baseUrl}/getProfile/${username}`);
// }

function sendMail() {
  return fetchWrapper.get(`${baseUrl}/sendVerificationMail`);
}

async function noNewNotification() {
  return fetchWrapper.get(`${baseUrl}/noNewNotification`);
}

async function getNewNotifications() {
  return fetchWrapper.get(`${baseUrl}/newNotifications`);
}

// async function noNewChat() {
//   return fetchWrapper.get(`${baseUrl}/noNewChat`);
// }

// async function getNewChats() {
//   return fetchWrapper.get(`${baseUrl}/newChats`);
// }

// function nearbyUsers(region: {
//   name: any;
//   countryCode: any;
//   stateCode: any;
//   latitude: any;
//   longitude: any;
// }) {
//   return fetchWrapper.get(
//     `${baseUrl}/nearbyUsers?region=${JSON.stringify(region)}`
//   );
// }

function uploadImage(data: any, userId: string) {
  return fetchWrapper.postFormData(
    `${baseUrl}/profile-pic?filename=${userId}&email=profile-pics`,
    data
  );
}

function deleteUser(userId: string) {
  return fetchWrapper.delete(`${baseUrl}/delete/${userId}`);
}

function socialSignin(provider: string, token: string) {
  return fetchWrapper
    .post(`${baseUrl}/socialauth`, { provider, token })
    .then((user) => {
      // Cookies.set("refreshtokenavailable", "yes", { expires: 7 });
      var currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    });
}

function signin(email: string, password: string) {
  return fetchWrapper
    .post(`${baseUrl}/login`, { email, password })
    .then((user) => {
      var currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    });
}

function logout() {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page

  fetchWrapper.post(`${baseUrl}/revoke-token`, {});
  stopRefreshTokenTimer();
  localStorage.removeItem("user");
  localStorage.removeItem("tokenexpiry");
  userSubject.next(null);
  Cookies.remove("refreshToken");
  Cookies.remove("jwtToken");
  window.location.reload();
  return "true";
}

function refreshToken() {
  // console.log("refreshToken");
  return fetchWrapper
    .post(`${baseUrl}/refresh-token`, {})
    .then((user) => {
      // Cookies.set("refreshtokenavailable", "yes", { expires: 7 });
      // publish user to subscribers and start timer to refresh token
      var currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    })
    .catch((error) => {
      console.log(error);
      Cookies.set("refreshtokenavailable", "no", { expires: 7 });
    });
}

function register(body: any) {
  return fetchWrapper.post(baseUrl, body).then((user) => {
    // Cookies.set("refreshtokenavailable", "yes", { expires: 7 });
    var currDate = new Date();
    currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
    localStorage.setItem("tokenexpiry", "" + currDate.getTime());
    localStorage.setItem("user", JSON.stringify(user));
    userSubject.next(user);
    startRefreshTokenTimer();
    return user;
  });
}

// async function checkUsername(username: string) {
//   try {
//     return await axios.get(`${baseUrl}/checkUsername`, {
//       params: {
//         username: username,
//       },
//     });
//   } catch (error) {
//     return error;
//   }
// }

function verifyEmail(token: any) {
  return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(email: string) {
  return fetchWrapper.post(`${baseUrl}/forgot-password`, { email });
}

function validateResetToken(token: string | (string | null)[] | null) {
  return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token });
}

function resetPassword({ token, password, confirmPassword }: any) {
  return fetchWrapper.post(`${baseUrl}/reset-password`, {
    token,
    password,
    confirmPassword,
  });
}

function changePassword(oldPassword: string, newPassword: string) {
  return fetchWrapper.post(`${baseUrl}/change-password`, {
    oldPassword,
    newPassword,
  });
}

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function getById(id: string) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(body: any) {
  return fetchWrapper.post(baseUrl, body);
}

function update(body: any) {
  return fetchWrapper
    .put(baseUrl, body)
    .then((user) => {
      // update stored user if the logged in user updated their own record
      // if (userSubject.value && user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user };
      // setUser(user);
      var currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      // }
      return user;
    })
    .catch((error) => console.log(error));
}

function _delete(id: string) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    if (userSubject.value && id === userSubject.value.id) {
      logout();
    }
    return x;
  });
}

// helper functions

let refreshTokenTimeout: NodeJS.Timeout;

function startRefreshTokenTimer() {
  if (userSubject.value) {
    // parse json object from base64 encoded jwt token
    // const jwtToken = JSON.parse(
    //   Buffer.from(userSubject.value.jwtToken.split(".")[1], "base64").toString()
    // );

    // set a timeout to refresh the token a minute before it expires
    // const expires = new Date(jwtToken.exp * 1000);
    const timeout = 13 * 60 * 1000;
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
  }
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}
