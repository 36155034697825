import { MoreVert } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  InputBase,
} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import React, { useEffect } from "react";
import AlertDialog from "../../modules/AlertDialog";
import { WishlistItem } from "../../shared/models/WishlistItem";
import ItemDetailsDialog from "./ItemDetailsDialog";

export default function AddItem({
  item,
  idx,
  updateItem,
  deleteItem,
  disabled = false,
  updating,
}: {
  item: any;
  idx: number;
  updateItem: (itemIdx: number, details: WishlistItem) => void;
  deleteItem: (itemIdx: number) => void;
  disabled?: boolean;
  updating: boolean;
}) {
  const [currItem, setCurrItem] = React.useState(item);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCurrItem(item);
  }, [item]);

  return (
    <Grid
      sx={{ mb: 2 }}
      container
      item
      justifyContent="space-around"
      alignItems="center"
    >
      {updating && (
        <Grid xs={12} item>
          <FormControlLabel
            control={
              <Checkbox
                checked={item.fulfilled}
                onChange={(e) =>
                  updateItem(idx, { ...item, fulfilled: e.target.checked })
                }
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Neuton",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  letterSpacing: "0.5px",
                }}
              >
                I have got this
              </Typography>
            }
          />
        </Grid>
      )}
      <Grid xs={8} item>
        <div style={{ position: "relative" }}>
          <InputBase
            disabled={disabled}
            multiline={true}
            maxRows={2}
            sx={{
              width: "100%",
              height: "47px",
              color: "Black",
              border: "1px solid #000000",
              borderRadius: "15px",
              padding: 0.5,
              paddingLeft: 1.5,
              paddingRight: 1,
              fontFamily: "Neuton",
            }}
            inputProps={{
              style: item.fulfilled
                ? { textDecoration: "line-through", paddingRight: 1 }
                : { paddingRight: "30px" },
            }}
            color="secondary"
            value={currItem.name ? currItem.name : ""}
            placeholder={item.placeholder}
            onChange={(e) => {
              setCurrItem({ ...currItem, name: e.target.value });
            }}
            onBlur={(e) => updateItem(idx, { name: e.target.value })}
          />
          <div
            style={{
              top: "2px",
              right: "12px",
              position: "absolute",
              cursor: "pointer",
            }}
          >
            <IconButton
              disabled={disabled}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDetailsDialogOpen(true);
                }}
              >
                Edit Wish
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDetailsDialogOpen(true);
                }}
              >
                Add/Edit Details
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setAlertOpen(true);
                }}
              >
                Delete Wish
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Grid>
      <Grid xs={4} item>
        <ItemDetailsDialog
          open={detailsDialogOpen}
          setOpen={setDetailsDialogOpen}
          item={currItem}
          idx={idx}
          updateItem={updateItem}
        />
      </Grid>
      <AlertDialog
        title="Delete Item"
        body="Are you sure you want to delete this item?"
        open={alertOpen}
        setOpen={setAlertOpen}
        agreeFunc={() => deleteItem(idx)}
        disagreeFunc={() => {}}
      />
    </Grid>
  );
}
