import { ArrowRightAlt, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Mixpanel } from "../../helpers/mixpanel-helper";
import AlertDialog from "../../modules/AlertDialog";
import { accountService } from "../../services";
import { exchangeService } from "../../services/exchange.service";
import { ExchangeModel } from "../../shared/models/ExchangeModel";

export default function Exchanges() {
  const [alertOpen, setAlertOpen] = useState(false);

  const history = useHistory();

  const [exchangeIdx, setExchangeIdx] = useState<number | null>(null);

  const [exchanges, setExchanges] = useState<null | Array<ExchangeModel>>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function deleteExchange(exchangeId: string) {
    exchangeService.deleteExchange(exchangeId);
    const nextItems =
      exchanges && exchanges.filter((item) => item._id !== exchangeId);
    setExchanges(nextItems);

    setTimeout(function () {
      alert("Your event has been deleted");
    }, 100);
  }

  useEffect(() => {
    Mixpanel.track("Exchanges Opened");

    exchangeService
      .getExchangesByUserId(accountService.userValue.id)
      .then((res) => setExchanges(res));
  }, []);

  return (
    <Box padding={2}>
      <Paper elevation={0} sx={{ borderRadius: 3, overflow: "hidden" }}>
        <Typography
          variant="h5"
          sx={{
            mt: 0,
            mb: 1,
            fontFamily: "Neuton",
            color: "#000000",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "30px",
            lineHeight: "30px",
          }}
        >
          Your Events
        </Typography>
        <img
          style={{ width: "100%", borderRadius: "15px" }}
          src="/HomePage2.png"
        ></img>

        <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
          <Button
            color="secondary"
            variant="contained"
            style={{
              width: "230px",
              height: "42px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#FFFFFF",
              backgroundColor: "#FF5757",
              fontFamily: "Neuton",
              fontWeight: "300",
              fontSize: "24px",
              textTransform: "none",
            }}
            onClick={() => history.push("/exchange/add")}
          >
            Create Event
          </Button>
        </div>
      </Paper>

      <br />
      {exchanges ? (
        exchanges.map((exchange, idx) => (
          <div style={{ position: "relative", marginBottom: 20 }}>
            <Button
              sx={{
                height: 50,
                width: "100%",
                color: "black",
                justifyContent: "flex-start",
                textTransform: "none",
                fontSize: 20,
                border: "1px solid #000000",
                borderRadius: "20px",
                fontFamily: "Neuton",
              }}
              color="secondary"
              variant="outlined"
              onClick={() => {
                history.push("/exchange/view/" + exchange._id, {
                  data: exchange,
                });
              }}
            >
              {exchange.name}
            </Button>

            <div
              style={{
                top: "5px",
                right: "0px",
                position: "absolute",
                cursor: "pointer",
              }}
            >
              {exchange.organiser &&
              exchange.organiser._id === accountService.userValue.id ? (
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(e) => {
                    setExchangeIdx(idx);
                    handleClick(e);
                  }}
                >
                  <MoreVert />
                </IconButton>
              ) : (
                <ArrowRightAlt
                  onClick={() => {
                    history.push("/exchange/view/" + exchange._id, {
                      data: exchange,
                    });
                  }}
                  sx={{ mt: 1, mr: 1 }}
                />
              )}
            </div>
          </div>
        ))
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
      {exchanges && exchangeIdx !== null && (
        <>
          <AlertDialog
            title="Delete Event"
            body="Are you sure you want to delete this event?"
            open={alertOpen}
            setOpen={setAlertOpen}
            agreeFunc={() => deleteExchange(exchanges[exchangeIdx]._id)}
            disagreeFunc={() => {}}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              Share
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setAlertOpen(true);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}

      <br />
      <br />
      <br />
    </Box>
  );
}
