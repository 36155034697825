import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { exchangeService } from "../../services/exchange.service";
import { useState } from "react";
import { WhatsApp, MailOutline } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";

export default function SelectiveInviteDialog({
  exchangeId,
  open,
  setOpen,
}: {
  exchangeId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const history = useHistory();
  const [invitees, setInvitees] = useState([
    {
      name: "",
      email: "",
    },
    {
      name: "",
      email: "",
    },
    {
      name: "",
      email: "",
    },
    {
      name: "",
      email: "",
    },
    {
      name: "",
      email: "",
    },
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          borderRadius: "24px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }} id="alert-dialog-title">
        <Typography
          sx={{
            p: 1,
            fontFamily: "Neuton",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "26px",
            lineHeight: "26px",
            letterSpacing: "0.3px",
            color: "#000000",
          }}
          variant="h6"
        >
          Invite via Mail
        </Typography>
        <Typography
          sx={{
            m: 1,
            fontFamily: "Neuton",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "16px",
            lineHeight: "16px",
            letterSpacing: "1.5px",
            color: "#FF5757",
          }}
        >
          <MailOutline fontSize="medium" /> E-Mail
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        {invitees.map((invitee, idx) => (
          <Grid justifyContent="space-around" container>
            <Grid xs={5} item>
              <TextField
                value={invitee.name}
                onChange={(e) => {
                  const itemIdx = idx;
                  const nextItems = invitees.map((item, idx) => {
                    if (itemIdx === idx) {
                      return { ...item, name: e.target.value };
                    } else {
                      // No change
                      return item;
                    }
                  });
                  setInvitees(nextItems);
                }}
                id="standard-basic"
                label="Name"
                variant="standard"
              />
            </Grid>
            <Grid xs={5} item>
              <TextField
                type="email"
                value={invitee.email}
                onChange={(e) => {
                  const itemIdx = idx;
                  const nextItems = invitees.map((item, idx) => {
                    if (itemIdx === idx) {
                      return { ...item, email: e.target.value };
                    } else {
                      // No change
                      return item;
                    }
                  });
                  setInvitees(nextItems);
                }}
                id="standard-basic"
                label="Email"
                variant="standard"
              />
            </Grid>
          </Grid>
        ))}

        <Button
          color="inherit"
          style={{
            height: "48px",
            width: "160px",
            borderRadius: "11px",
            border: "1px solid #FF5757",
            color: "#000000",
            textTransform: "none",
            fontFamily: "Neuton",
            fontStyle: "Normal",
            fontWeight: "300",
            fontSize: "18px",
            lineHeight: "18px",
            marginTop: 20,
          }}
          variant="outlined"
          startIcon={<Add />}
          onClick={() => setInvitees([...invitees, { name: "", email: "" }])}
        >
          <b>Add Items</b>
        </Button>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mt: -1 }}>
        <Box width="100%" textAlign="center">
          {/* <Button
            style={{
              height: "48px",
              width: "160px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#FF5757",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "18px",
              lineHeight: "18px",
              textTransform: "none",
            }}
            color="secondary"
            variant="outlined"
          >
            Add Rows
          </Button> */}
          <Button
            style={{
              height: "48px",
              width: "160px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#FFFFFF",
              backgroundColor: "#FF5757",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "18px",
              lineHeight: "18px",
              textTransform: "none",
            }}
            color="secondary"
            variant="contained"
            onClick={() => {
              var noName = false;
              invitees.map((invitee) => {
                if (invitee.email.length > 0 && invitee.name.length === 0) {
                  alert("Name is required");
                  noName = true;
                }
              });

              if (!noName) {
                exchangeService.sendInvites(exchangeId, invitees).then(() => {
                  alert("Invites Sent");
                  history.push("/exchange/view/" + exchangeId);
                });
                handleClose();
              }
            }}
          >
            Send Invitations
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
