import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { accountService } from "../../services";
import AddressPopup from "./AddressPopup";
import WishlistPopup from "./WishlistPopup";
import {
  FavoriteBorder,
  AddLocationAltOutlined,
  CardGiftcard,
} from "@mui/icons-material";
import SantaPopup from "./SantaPopup";

export default function PostMatch({ location }: { location: any }) {
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  const [santaDialogOpen, setSantaDialogOpen] = useState(false);
  const [wishlistDialogOpen, setWishlistDialogOpen] = useState(false);

  var user = location.state ? location.state.user : null;
  var secretSanta = location.state ? location.state.secretSanta : null;
  var exchangeId = location.state ? location.state.exchangeId : null;
  var exchangeDateComing = location.state
    ? location.state.exchangeDateComing
    : null;
  const myProfile =
    user && user.id === accountService.userValue.id ? true : false;

  return (
    <Grid
      sx={{ pl: 2, pr: 2 }}
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
    >
      <img
        style={{
          width: "100%",
          borderRadius: "15px",
          border: "1px solid #000000",
        }}
        src="/EventHomePage2.png"
      ></img>
      <Box
        sx={{
          textAlign: "center",
          width: "100%",
          height: "75px",
          background: "#cacee8",
          borderRadius: "30px",
          mt: 2,
        }}
      >
        <Typography
          sx={{
            mt: 3,
            fontFamily: "Neuton",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "22px",
            lineHeight: "22px",
            letterSpacing: "0.5px",
            textTransform: "none",
            color: "#000000",
          }}
        >
          {" "}
          {!myProfile
            ? `You’re Gifting to : ${user.fullName}`
            : "Your Section!"}{" "}
        </Typography>
      </Box>
      <Button
        onClick={() => setWishlistDialogOpen(true)}
        color="secondary"
        sx={{
          mt: 2,
          mb: 2,
        }}
        style={{
          width: "332px",
          height: "48px",
          borderRadius: "11px",
          border: "1px solid #FF5757",
          color: "#000000",
        }}
        variant="outlined"
        // onClick={() => {
        //   if (!myProfile)
        //     history.push(
        //       "/public-wishlist/" +
        //         (user.matchedWishlist ? user.matchedWishlist._id : "")
        //     );
        //   else {
        //     if (user.matchedWishlist.status === "draft")
        //       history.push("/wishlist/add", {
        //         data: user.matchedWishlist,
        //       });
        //     else
        //       history.push("/wishlist/view", {
        //         data: user.matchedWishlist,
        //       });
        //   }
        // }}
      >
        <FavoriteBorder
          style={{ color: "#FF5757", fontSize: "25px", marginRight: 1 }}
        />
        <Typography
          sx={{
            ml: 2,
            textTransform: "none",
            fontFamily: "Neuton",
            fontStyle: "Normal",
            fontWeight: "300",
            fontSize: "20px",
            lineHeight: "20px",
          }}
        >
          {myProfile ? "Your Wishlist" : `${user.fullName}'s Wishlist`}
        </Typography>
      </Button>
      <Button
        onClick={() => setAddressDialogOpen(true)}
        color="secondary"
        style={{
          width: "332px",
          height: "48px",
          borderRadius: "11px",
          border: "1px solid #FF5757",
          color: "#000000",
          textTransform: "none",
          fontFamily: "Neuton",
          fontStyle: "Normal",
          fontWeight: "300",
          fontSize: "20px",
          lineHeight: "20px",
        }}
        sx={{ mb: 2 }}
        variant="outlined"
      >
        <AddLocationAltOutlined
          style={{ color: "#FF5757", fontSize: "25px" }}
        />
        <Typography
          sx={{
            ml: 2,
            textTransform: "none",
            fontFamily: "Neuton",
            fontStyle: "Normal",
            fontWeight: "300",
            fontSize: "20px",
            lineHeight: "20px",
          }}
        >
          {myProfile ? "Your Address" : `${user.fullName}'s Address`}
        </Typography>
      </Button>
      {myProfile && exchangeDateComing !== null && !exchangeDateComing && (
        <Button
          color="secondary"
          style={{
            width: "332px",
            height: "48px",
            borderRadius: "11px",
            border: "1px solid #FF5757",
            color: "#000000",
            textTransform: "none",
            fontFamily: "Neuton",
            fontStyle: "Normal",
            fontWeight: "300",
            fontSize: "20px",
            lineHeight: "20px",
          }}
          sx={{ mb: 2 }}
          variant="outlined"
          onClick={() => setSantaDialogOpen(true)}
        >
          <CardGiftcard style={{ color: "#FF5757", fontSize: "25px" }} />
          <Typography
            sx={{
              ml: 2,
              textTransform: "none",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "20px",
            }}
          >
            My Secret Santa
          </Typography>
        </Button>
      )}
      {/* <Button
        color="secondary"
        style={{
          width: "332px",
          height: "48px",
          borderRadius: "11px",
          border: "1px solid #FF5757",
          color: "#000000",
          textTransform: "none",
          fontFamily: "Neuton",
          fontStyle: "Normal",
          fontWeight: "300",
          fontSize: "20px",
          lineHeight: "20px",
        }}
        variant="outlined"
        onClick={() => {
          if (!myProfile) {
            history.push("/reserved-wishes");
          } else history.push("/fulfilled-wishes");
        }}
      >
        <ShoppingCartOutlined style={{ color: "#FF5757", fontSize: "25px" }} />{" "}
        {myProfile ? "Mark Gift Received" : "Mark Gift Purchased"}
      </Button> */}
      <AddressPopup
        open={addressDialogOpen}
        setOpen={setAddressDialogOpen}
        address={user.address}
        name={user.fullName}
        myProfile={myProfile}
        userId={user.id ? user.id : user._id}
      />
      <WishlistPopup
        open={wishlistDialogOpen}
        setOpen={setWishlistDialogOpen}
        wishlist={user.matchedWishlist}
        name={user.fullName}
        myProfile={myProfile}
        exchangeId={exchangeId}
        userId={user.id ? user.id : user._id}
      />
      <SantaPopup
        open={santaDialogOpen}
        setOpen={setSantaDialogOpen}
        name={secretSanta ? secretSanta.fullName : ""}
      />
    </Grid>
  );
}
