import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import React, { useState } from "react";
import AlertDialog from "../../modules/AlertDialog";
import { accountService } from "../../services";
import { WishlistItem } from "../../shared/models/WishlistItem";
import ItemDetailsDialog from "../wishlist/ItemDetailsDialog";

export default function ShowItem({
  item,
  idx,
  updateItem,
}: {
  item: any;
  idx: number;
  updateItem: (itemIdx: number, details: WishlistItem) => void;
}) {
  const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);

  const [alertOpen, setAlertOpen] = useState(false);

  return (
    <Grid
      sx={{ mb: 2 }}
      container
      item
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid xs={12} item>
        <FormControlLabel
          control={
            <Checkbox
              checked={item.reservedBy && item.reservedBy.user ? true : false}
              onChange={(e) => {
                if (!e.target.checked) {
                  if (
                    !(item.reservedBy && item.reservedBy.user) ||
                    !(
                      item.reservedBy &&
                      item.reservedBy.user !== accountService.userValue.id
                    )
                  )
                    updateItem(idx, {
                      reservedBy: e.target.checked
                        ? {
                            user: accountService.userValue.id,
                            purchased: false,
                            date: Date.now(),
                          }
                        : {
                            user: null,
                            purchased: false,
                            date: Date.now(),
                          },
                    });
                } else {
                  setAlertOpen(true);
                }
              }}
            />
          }
          label={
            item.reservedBy && item.reservedBy.user ? (
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Neuton",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  letterSpacing: "0.5px",
                }}
              >
                On Hold
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Neuton",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  letterSpacing: "0.5px",
                }}
              >
                Fulfill Wish
              </Typography>
            )
          }
        />
      </Grid>
      <Grid xs={8} item>
        <TextField
          sx={{
            "& fieldset": { border: "none" },
            width: "100%",
            border: "1px solid #000000",
            borderRadius: "18px",
            fontFamily: "Neuton",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "20px",
            letterSpacing: "0.5px",
            color: "#000000",
          }}
          inputProps={{
            style:
              item.fulfilled || (item.reservedBy && item.reservedBy.user)
                ? { textDecoration: "line-through" }
                : {},
            readOnly: true,
          }}
          color="secondary"
          value={item.name}
        />
      </Grid>
      <Grid xs={4} item>
        <ItemDetailsDialog
          disabled={
            (item.brand && item.brand.length > 0) ||
            (item.link && item.link.length > 0) ||
            (item.note && item.note.length > 0) ||
            (item.size && item.size.length > 0)
              ? false
              : true
          }
          open={detailsDialogOpen}
          setOpen={setDetailsDialogOpen}
          item={item}
          idx={idx}
        />
      </Grid>
      <AlertDialog
        title="Gift an item"
        body="Are you sure you want to gift this item?"
        open={alertOpen}
        setOpen={setAlertOpen}
        agreeFunc={() =>
          updateItem(idx, {
            reservedBy: {
              user: accountService.userValue.id,
              purchased: false,
              date: Date.now(),
            },
          })
        }
        disagreeFunc={() => setAlertOpen(false)}
      />
    </Grid>
  );
}
