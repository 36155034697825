import { Add } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { useHistory } from "react-router-dom";
import { accountService } from "../../services";
import { wishlistService } from "../../services/wishlist.service";
import { WishlistItem } from "../../shared/models/WishlistItem";
import AddItems from "./AddItems";

let timer: any;

export default function UpdateWishlist({ location }: { location: any }) {
  var data = location.state.data;

  const history = useHistory();

  const [items, setItems] = React.useState<Array<WishlistItem>>(
    data.items ? data.items : []
  );
  const [name, setName] = React.useState(data.name);
  const [openLoader, setOpenLoader] = React.useState(false);

  React.useEffect(() => {
    const placeholdersRemoved = items.map(
      ({ placeholder, ...keepAttrs }) => keepAttrs
    );
    const noNamesRemoved = placeholdersRemoved.filter((item) =>
      item.name ? (item.name.length > 0 ? true : false) : false
    );
    timer = window.setTimeout(async () => {
      wishlistService.updateWishlist(data._id, {
        name: name && name.length > 0 ? name : "My Wishlist",
        items: noNamesRemoved,
      });
    }, 1000);

    return () => {
      window.clearTimeout(timer);
    };
  }, [name, items]);

  return (
    <Grid
      sx={{ p: 2 }}
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid sx={{ width: "100%" }} xs={12} item>
        <TextField
          color="secondary"
          style={{
            width: "100%",
            // border: "1px solid #000000",
            borderRadius: "10px",
          }}
          id="outlined-basic"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Wishlist Name"
          variant="outlined"
        />
      </Grid>
      <Typography
        variant="h6"
        sx={{
          width: "100%",
          mt: 3,
          mb: 2,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "24px",
          lineHeight: "16px",
          color: "#000000",
        }}
      >
        To be fulfilled wishes
      </Typography>
      <Grid sx={{ width: "100%" }} xs={12} item>
        <AddItems
          wishlistId={data._id}
          items={items}
          setItems={setItems}
          updating={true}
        />
      </Grid>
      <Grid sx={{ mt: 3 }} item>
        <Button
          color="inherit"
          style={{
            width: "172px",
            height: "48px",
            borderRadius: "11px",
            border: "1px solid #FF5757",
            color: "#000000",
            textTransform: "none",
            fontFamily: "Neuton",
            fontStyle: "Normal",
            fontWeight: "300",
            fontSize: "24px",
            lineHeight: "16px",
          }}
          variant="outlined"
          startIcon={<Add />}
          onClick={() =>
            setItems([...items, { user: accountService.userValue.id }])
          }
        >
          <b>Add Items</b>
        </Button>
      </Grid>
      <Typography
        variant="h6"
        sx={{
          width: "100%",
          mt: 3,
          mb: 2,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "24px",
          lineHeight: "16px",
          color: "#000000",
        }}
      >
        Fulfilled wishes
      </Typography>
      <Grid sx={{ width: "100%" }} xs={12} item>
        <AddItems
          wishlistId={data._id}
          items={items}
          setItems={setItems}
          updating={true}
          showFulfilled={true}
        />
      </Grid>
      <Grid sx={{ mt: 3 }} item>
        <Button
          variant="contained"
          style={{
            width: "172px",
            height: "48px",
            borderRadius: "11px",
            border: "1px solid #FF5757",
            color: "#FFFFFF",
            backgroundColor: "#FF5757",
            fontFamily: "Neuton",
            fontStyle: "Normal",
            fontWeight: "300",
            fontSize: "24px",
            lineHeight: "16px",
            textTransform: "none",
          }}
          sx={{
            marginBottom: 10,
          }}
          onClick={() => {
            setOpenLoader(true);
            const noNamesRemoved = items.filter((item) =>
              item.name ? (item.name.length > 0 ? true : false) : false
            );
            wishlistService
              .updateWishlist(data._id, { name, items: noNamesRemoved })
              .then((res) => {
                setOpenLoader(false);
                alert("Wishlist has been updated.");
                history.push("/");
              })
              .catch((error) => {
                console.log(error);
                setOpenLoader(false);
                history.push("/");
              });
          }}
        >
          Save Wishlist
        </Button>
      </Grid>
    </Grid>
  );
}
