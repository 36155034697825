import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

const baseUrl = `${config.apiUrl}/api/exchanges`;

export const exchangeService = {
  getExchangesByUserId,
  getExchangeByExchangeId,
  addToExchanges,
  updateExchange,
  deleteExchange,
  sendInvites,
  rejectInvite,
  acceptInvite,
  removeMember,
  drawNames,
  requestWishlist,
};

function requestWishlist(exchangeId: string, userId: string) {
  return fetchWrapper
    .post(`${baseUrl}/request-wishlist/${exchangeId}`, { userId })
    .then((x) => {
      return x;
    });
}

function removeMember(exchangeId: string, userId: string) {
  return fetchWrapper
    .post(`${baseUrl}/remove-member/${exchangeId}`, { userId })
    .then((x) => {
      return x;
    });
}

function drawNames(exchangeId: string) {
  return fetchWrapper.get(`${baseUrl}/draw-names/${exchangeId}`).then((x) => {
    return x;
  });
}

function rejectInvite(exchangeId: string) {
  return fetchWrapper
    .get(`${baseUrl}/reject-invite/${exchangeId}`)
    .then((x) => {
      return x;
    });
}

function acceptInvite(exchangeId: string) {
  return fetchWrapper
    .get(`${baseUrl}/accept-invite/${exchangeId}`)
    .then((x) => {
      return x;
    });
}

function sendInvites(exchangeId: string, invitees: Array<any>) {
  return fetchWrapper
    .post(`${baseUrl}/add-invitees/${exchangeId}`, invitees)
    .then((x) => {
      return x;
    });
}

function deleteExchange(exchangeId: string) {
  return fetchWrapper.delete(`${baseUrl}/${exchangeId}`).then((x) => {
    return x;
  });
}

function updateExchange(exchangeId: string, body: any) {
  return fetchWrapper.put(`${baseUrl}/update/${exchangeId}`, body).then((x) => {
    return x;
  });
}

function getExchangesByUserId(userId: string) {
  return fetchWrapper.get(`${baseUrl}/get-by-userid/${userId}`).then((x) => {
    return x;
  });
}

function getExchangeByExchangeId(exchangeId: string) {
  return fetchWrapper
    .get(`${baseUrl}/get-by-exchangeid/${exchangeId}`)
    .then((x) => {
      return x;
    });
}

function addToExchanges(body: any) {
  return fetchWrapper.post(`${baseUrl}/add`, body).then((x) => {
    return x;
  });
}
