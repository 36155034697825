import { Visibility } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { accountService } from "../../services";
import InputWithIcon from "./InputWithIcon";

let timer: any;

function mask(phoneNo: string) {
  var first4 = phoneNo.substring(0, 4);
  var last5 = phoneNo.substring(phoneNo.length - 3);
  var mid_mask = phoneNo.substring(4, phoneNo.length - 3).replace(/\d/g, "*");

  return first4 + mid_mask + last5;
}

export default function SearchWishlist() {
  const history = useHistory();

  const [query, setQuery] = useState<null | string>(null);
  const [results, setResults] = useState<null | any>(null);

  useEffect(() => {
    let isSubscribed = true;

    timer = window.setTimeout(async () => {
      if (query)
        accountService.searchUsers(query).then((res) => {
          if (isSubscribed) {
            console.log(res);
            setResults(res);
          }
        });
    }, 1000);

    return () => {
      isSubscribed = false;
      window.clearTimeout(timer);
    };
  }, [query]);

  return (
    <Box sx={{ padding: 2, mb: 2 }}>
      <Typography
        variant="h6"
        sx={{
          mt: 1,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "25px",
          letterSpacing: "0.5px",
          color: "#000000",
          lineHeight: "25px",
        }}
      >
        Search for friends' Wishlists
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 1,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "16px",
          letterSpacing: "0.5px",
          color: "#000000",
          lineHeight: "16px",
        }}
      >
        Only place to find what your closed ones want!
      </Typography>
      <div
        style={{
          flex: 1,
          height: "1px",
          backgroundColor: "black",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      />
      <InputWithIcon
        query={query}
        setQuery={setQuery}
        searchFunc={() => console.log(query)}
      />
      {results &&
        (results.length === 0 && query && query.length > 0 ? (
          <p>No results found</p>
        ) : (
          results.map((user: any) => (
            <Paper
              sx={{
                mt: 3,
                mb: 3,
                p: 2,
                border: "1px solid #000000",
                borderRadius: "20px",
                cursor: "pointer",
              }}
              onClick={() => history.push("/public-profile/" + user._id)}
              elevation={0}
            >
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Neuton",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "20px",

                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "0.5px",

                  color: "#000000",
                }}
              >
                {user.fullName}
              </Typography>
              <Grid
                container
                sx={{
                  mt: 1,
                }}
                justifyContent="space-between"
              >
                <Grid item>
                  {user.birthday && (
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "Neuton",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "16px",

                        display: "flex",
                        alignItems: "center",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Birthday: {user.birthday.toString().split("T")[0]}
                    </Typography>
                  )}

                  {user.phone && (
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "Neuton",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "16px",

                        display: "flex",
                        alignItems: "center",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Phone No: {mask(user.phone)}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      color: "Black",
                      border: "1px solid #000000",
                      borderRadius: "18px",
                      fontFamily: "Neuton",
                      textTransform: "none",
                    }}
                    color="secondary"
                    variant="outlined"
                    startIcon={<Visibility />}
                    onClick={() => history.push("/public-profile/" + user._id)}
                  >
                    View
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          ))
        ))}
    </Box>
  );
}
