import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Add from "@mui/icons-material/Add";
import { WishlistItem } from "../../shared/models/WishlistItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Visibility } from "@mui/icons-material";
import { withStyles } from "@mui/material";

const emails = ["username@gmail.com", "user02@gmail.com"];

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  idx: number;
  onClose: (value: string) => void;
  updateItem?: (itemIdx: number, details: WishlistItem) => void;
  item: WishlistItem;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open, updateItem, item, idx } = props;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  const [name, setName] = React.useState(item.name ?? "");
  const [link, setLink] = React.useState(item.link ?? "");
  const [size, setSize] = React.useState(item.size ?? "");
  const [brand, setBrand] = React.useState(item.brand ?? "");
  const [note, setNote] = React.useState(item.note ?? "");

  function resetDetails() {
    setLink(item.link ?? "");
    setSize(item.size ?? "");
    setBrand(item.brand ?? "");
    setNote(item.note ?? "");
  }

  React.useEffect(() => {
    setName(item.name ?? "");
    setLink(item.link ?? "");
    setSize(item.size ?? "");
    setBrand(item.brand ?? "");
    setNote(item.note ?? "");
  }, [item]);

  const handleClose = () => {
    resetDetails();
    onClose(selectedValue);
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          background: `#FFFFFF`,
          boxShadow: `0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)`,
          borderRadius: `16px`,
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontFamily: "Neuton",
          fontSize: "30px",
          color: "#000000",
        }}
      >
        Add Details
      </DialogTitle>
      <TextField
        disabled={updateItem ? false : true}
        color="secondary"
        value={name}
        onChange={(e) => setName(e.target.value)}
        InputProps={{ disableUnderline: true }}
        inputProps={{
          min: 0,
          style: {
            background: "#FFFFFF",
            borderRadius: "10px",
            color: "#000000",
          },
        }}
        sx={{
          m: 2,
          mt: 1,
          borderRadius: "10px",
          border: "1px solid #000000",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        id="filled-basic"
        label="Wish Name"
        variant="filled"
      />
      <TextField
        disabled={updateItem ? false : true}
        color="secondary"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        InputProps={{ disableUnderline: true }}
        inputProps={{
          min: 0,
          style: {
            background: "#FFFFFF",
            borderRadius: "10px",
          },
        }}
        sx={{
          m: 2,
          mt: 1,
          borderRadius: "10px",
          border: "1px solid #000000",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        id="filled-basic"
        label="Add item link (optional)"
        variant="filled"
      />
      <TextField
        disabled={updateItem ? false : true}
        color="secondary"
        value={size}
        onChange={(e) => setSize(e.target.value)}
        InputProps={{ disableUnderline: true }}
        inputProps={{
          min: 0,
          style: {
            background: "#FFFFFF",
            borderRadius: "10px",
          },
        }}
        sx={{
          m: 2,
          mt: 1,
          borderRadius: "10px",
          border: "1px solid #000000",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        id="filled-basic"
        label="Size (optional)"
        variant="filled"
      />
      <TextField
        disabled={updateItem ? false : true}
        color="secondary"
        value={brand}
        onChange={(e) => setBrand(e.target.value)}
        InputProps={{ disableUnderline: true }}
        inputProps={{
          min: 0,
          style: {
            background: "#FFFFFF",
            borderRadius: "10px",
          },
        }}
        sx={{
          m: 2,
          mt: 1,
          borderRadius: "10px",
          border: "1px solid #000000",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        id="filled-basic"
        label="Brand (optional)"
        variant="filled"
      />
      <TextField
        disabled={updateItem ? false : true}
        // InputLabelProps={{ style: { color: "#FF5757" } }}
        color="secondary"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        InputProps={{ disableUnderline: true }}
        inputProps={{
          min: 0,
          style: {
            background: "#FFFFFF",
            borderRadius: "10px",
          },
        }}
        sx={{
          m: 2,
          mt: 1,
          mb: 1,
          borderRadius: "10px",
          border: "1px solid #000000",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        id="filled-basic"
        label="Note for family & friends (optional)"
        variant="filled"
      />

      <Grid
        container
        justifyContent="space-evenly"
        spacing={1}
        textAlign="center"
      >
        <Grid item xs={6}>
          <Button
            sx={{
              m: 2,
            }}
            style={{
              width: "132px",
              height: "48px",
              borderRadius: "11px",
              border: `1px solid ${!link ? "black" : "#FF5757"}`,
              color: `${!link ? "grey" : "#FF5757"}`,
              textTransform: "none",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "16px",
            }}
            onClick={() => {
              window.open(link, "_blank");
            }}
            disabled={!link}
          >
            View Product
          </Button>
        </Grid>
        {updateItem && (
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="outlined"
              sx={{
                m: 2,
                borderRadius: 10,
              }}
              style={{
                width: "132px",
                height: "48px",
                borderRadius: "11px",
                border: "1px solid #FF5757",
                color: "#FF5757",
                textTransform: "none",
                fontFamily: "Neuton",
                fontStyle: "Normal",
                fontWeight: "300",
                fontSize: "24px",
                lineHeight: "16px",
              }}
              onClick={() => {
                resetDetails();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
        {updateItem && (
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                m: 1,
                borderRadius: 10,
              }}
              style={{
                width: "132px",
                height: "48px",
                borderRadius: "11px",
                border: "1px solid #FF5757",
                color: "#FFFFFF",
                backgroundColor: "#FF5757",
                fontFamily: "Neuton",
                fontStyle: "Normal",
                fontWeight: "300",
                fontSize: "24px",
                lineHeight: "16px",
                textTransform: "none",
              }}
              onClick={() => {
                updateItem(idx, {
                  name,
                  link,
                  size,
                  brand,
                  note,
                });
                handleClose();
              }}
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
}

export default function ItemDetailsDialog({
  disabled = false,
  item,
  idx,
  updateItem,
  open,
  setOpen,
}: {
  disabled?: boolean;
  item: WishlistItem;
  idx: number;
  updateItem?: (itemIdx: number, details: WishlistItem) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button
        color="secondary"
        style={{
          border: "1px solid #000000",
          borderRadius: "15px",
          fontFamily: "Neuton",
        }}
        onClick={handleClickOpen}
        sx={{
          ml: 1,
          height: "47px",
          width: "90%",
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "16px",
          letterSpacing: "0.5px",
          textTransform: "none",
        }}
        variant="outlined"
        startIcon={
          item.brand || item.link || item.note || item.size || !updateItem ? (
            <Visibility />
          ) : (
            <Add />
          )
        }
        disabled={
          (item.name && item.name.length > 0 ? false : true) ||
          (disabled ? true : false)
        }
      >
        Details
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        idx={idx}
        onClose={handleClose}
        updateItem={updateItem}
        item={item}
      />
    </div>
  );
}
