import React, { useEffect } from "react";
import { accountService } from "../../services";
import { wishService } from "../../services/wish.service";
import { WishlistItem } from "../../shared/models/WishlistItem";
import AddItem from "./AddItem";

let timer: any;

export default function AddItems({
  initWishlist,
  wishlistId,
  items,
  setItems,
  disabled = false,
  updating = false,
  showFulfilled = false,
}: {
  initWishlist?: () => Promise<any>;
  wishlistId: any;
  items: Array<any>;
  setItems: React.Dispatch<React.SetStateAction<WishlistItem[]>>;
  disabled?: boolean;
  updating?: boolean;
  showFulfilled?: boolean;
}) {
  // const attachId = (itemIdx: number, wishId: string) => {
  //   setItems(
  //     items.map((item, idx) => {
  //       if (itemIdx === idx) {
  //         return { ...item, _id: wishId };
  //       } else {
  //         // No change
  //         return item;
  //       }
  //     })
  //   );
  // };

  // useEffect(() => {
  //   timer = window.setTimeout(async () => {
  //     for (var i = 0; i < items.length; i++) {
  //       var wishId = items[i]._id;
  //       if (wishId) {
  //         if (items[i].name && items[i].name.length > 0) {
  //           wishService.updateWish(wishId, items[i]);
  //         } else {
  //           wishService.deleteWish(wishId);
  //         }
  //       } else {
  //         if (items[i].name && items[i].name.length > 0) {
  //           if (wishlistId) {
  //             const wish = await wishService.addWishToWishlist(wishlistId, {
  //               ...items[i],
  //               user: accountService.userValue.id,
  //             });
  //             wishId = wish._id;
  //           } else {
  //             if (initWishlist) {
  //               const newWishlistId = await initWishlist();
  //               const wish = await wishService.addWishToWishlist(
  //                 newWishlistId,
  //                 {
  //                   ...items[i],
  //                   user: accountService.userValue.id,
  //                 }
  //               );
  //               wishId = wish._id;
  //             }
  //           }

  //           attachId(i, wishId);
  //         }
  //       }
  //     }
  //   }, 1000);

  //   return () => {
  //     window.clearTimeout(timer);
  //   };
  // }, [items]);

  const deleteItem = (itemIdx: number) => {
    wishService.deleteWish(items[itemIdx]._id);

    const nextItems = items.filter((item, idx) => idx !== itemIdx);
    setItems(nextItems);
  };

  const updateItem = async (itemIdx: number, details: any) => {
    var wishId = items[itemIdx]._id;

    if (wishId) {
      if (details.name && details.name.length > 0) {
        wishService.updateWish(wishId, details);
      } else {
        wishService.deleteWish(wishId);
        wishId = null;
      }
    } else {
      if (details.name && details.name.length > 0) {
        if (wishlistId) {
          const wish = await wishService.addWishToWishlist(wishlistId, {
            ...details,
            user: accountService.userValue.id,
          });
          wishId = wish._id;
        } else {
          if (initWishlist) {
            const newWishlistId = await initWishlist();
            const wish = await wishService.addWishToWishlist(newWishlistId, {
              ...details,
              user: accountService.userValue.id,
            });
            wishId = wish._id;
          }
        }
      }
    }

    setItems(
      items.map((item, idx) => {
        if (itemIdx === idx) {
          return { ...item, ...details, _id: wishId };
        } else {
          // No change
          return item;
        }
      })
    );
  };

  return (
    <>
      {items.map((item, idx) =>
        updating ? (
          showFulfilled ? (
            item.fulfilled && (
              <AddItem
                disabled={disabled}
                deleteItem={deleteItem}
                item={item}
                idx={idx}
                updateItem={updateItem}
                updating={updating}
              />
            )
          ) : (
            !item.fulfilled && (
              <AddItem
                disabled={disabled}
                deleteItem={deleteItem}
                item={item}
                idx={idx}
                updateItem={updateItem}
                updating={updating}
              />
            )
          )
        ) : (
          <AddItem
            disabled={disabled}
            deleteItem={deleteItem}
            item={item}
            idx={idx}
            updateItem={updateItem}
            updating={updating}
          />
        )
      )}
    </>
  );
}
