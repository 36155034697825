import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import ShareLink from "../../modules/ShareLink";

interface InviteViaLinkPopupProps {
  exchangeId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function InviteViaLinkPopup(props: InviteViaLinkPopupProps) {
  const history = useHistory();

  const { setOpen, open, exchangeId } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle
          sx={{
            fontFamily: "Neuton",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "24px",
            lineHeight: "24px",
            color: "#000000",
            letterSpacing: "0.5px",
            textAlign: "center",
          }}
        >
          Share link via
        </DialogTitle>
        {/* <DialogContent
          sx={{
            fontFamily: "Neuton",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "16px",
            color: "#000000",
            letterSpacing: "0.5px",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {"https://www.makeyourwish.in/exchange/view/" + exchangeId}
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <ShareLink
            url={"https://www.makeyourwish.in/exchange/view/" + exchangeId}
            title={`*_Sshhh! Secret Santa is here!_*\n\nLet's Kickstart the gifting season & start spreading joy!!\n\n Click on the below link to join my Secret Santa event!\n\nEvent Link : `}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
