import { Favorite, MoreVert } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AlertDialog from "../../modules/AlertDialog";
import { accountService } from "../../services";
import { wishlistService } from "../../services/wishlist.service";
import { WishlistModel } from "../../shared/models/WishlistModel";

export default function Wishlists() {
  const [alertOpen, setAlertOpen] = useState(false);

  const history = useHistory();

  const [wishlistIdx, setWishlistIdx] = useState<number | null>(null);

  const [openLoader, setOpenLoader] = useState(false);

  const [wishlists, setWishlists] = useState<null | Array<WishlistModel>>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function deleteWishlist(wishlistId: string) {
    wishlistService.deleteWishlist(wishlistId);
    const nextItems =
      wishlists && wishlists.filter((item) => item._id !== wishlistId);
    setWishlists(nextItems);

    setTimeout(function () {
      alert("Your wishlist has been deleted");
    }, 100);
  }

  useEffect(() => {
    wishlistService
      .getWishlistsByUserId(accountService.userValue.id)
      .then((res) => setWishlists(res));
  }, []);

  const initWishlist = async () => {
    const res = await wishlistService.addToWishlist({
      name: "My Wishlist",
      status: "draft",
    });

    return res._id;
  };

  return (
    <Box padding={2}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper elevation={0} sx={{ borderRadius: 3, overflow: "hidden" }}>
        <Typography
          variant="h5"
          sx={{
            mt: 0,
            mb: 1,
            fontFamily: "Neuton",
            color: "#000000",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "30px",
            lineHeight: "30px",
          }}
        >
          Your Wishlists
        </Typography>
        <img
          style={{ width: "100%", borderRadius: "15px" }}
          src="/HomePage1.png"
        ></img>

        <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
          <Button
            color="secondary"
            variant="contained"
            style={{
              width: "200px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#FFFFFF",
              backgroundColor: "#FF5757",
              fontFamily: "Neuton",
              fontWeight: "300",
              fontSize: "24px",
              textTransform: "none",
              height: "42px",
            }}
            onClick={async () => {
              setOpenLoader(true);
              const wishlistId = await initWishlist();
              setOpenLoader(false);
              history.push("/wishlist/add/" + wishlistId);
            }}
          >
            Create Wishlist
          </Button>
        </div>
      </Paper>

      <br />
      {wishlists ? (
        wishlists.map((wishlist, idx) => (
          <div style={{ position: "relative", marginBottom: 20 }}>
            <Button
              sx={{
                height: 50,
                width: "100%",
                color: "black",
                justifyContent: "flex-start",
                textTransform: "none",
                fontSize: 20,
                border: "1px solid #000000",
                borderRadius: "20px",
                fontFamily: "Neuton",
              }}
              color="secondary"
              variant="outlined"
              onClick={() => {
                // history.push("/wishlist/view", { data: wishlist })
                if (wishlist.status === "draft")
                  history.push("/wishlist/add/" + wishlist._id);
                else
                  history.push("/wishlist/view", {
                    data: wishlist,
                  });
              }}
            >
              {wishlist.name}
            </Button>
            <div
              style={{
                top: "5px",
                right: "0px",
                position: "absolute",
                cursor: "pointer",
              }}
            >
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => {
                  setWishlistIdx(idx);
                  handleClick(e);
                }}
              >
                <MoreVert />
              </IconButton>
            </div>
            {wishlist.status === "draft" ? (
              <Box
                sx={{
                  borderColor: "white",
                  height: 25,
                  border: "1px solid white",
                }}
              >
                <Typography
                  sx={{
                    ml: 1,
                    color: "red",
                    fontFamily: "Neuton",
                    fontSize: "15px",
                  }}
                  variant="caption"
                >
                  Draft State : Wishlist not saved yet!
                </Typography>
              </Box>
            ) : (
              <Typography
                sx={{ ml: 1, fontFamily: "Neuton" }}
                variant="caption"
              >
                {(wishlist.items ? wishlist.items.length : 0) + " wishes"}
              </Typography>
            )}
          </div>
        ))
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
      {wishlists && wishlistIdx !== null && (
        <>
          <AlertDialog
            title="Delete Wishlist"
            body="Are you sure you want to delete this wishlist?"
            open={alertOpen}
            setOpen={setAlertOpen}
            agreeFunc={() => deleteWishlist(wishlists[wishlistIdx]._id)}
            disagreeFunc={() => {}}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              Share
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleClose();
                history.push("/wishlist/view", {
                  data: wishlists[wishlistIdx],
                });
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setAlertOpen(true);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
      <br />
      <br />
    </Box>
  );
}
