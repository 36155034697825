import { ContentCopy } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function ShareLink({
  url,
  title,
}: {
  url: string;
  title: string;
}) {
  return (
    <div onClick={() => console.log("yo")}>
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(url);
          alert("Link copied to the clipboard");
        }}
        style={{
          border: "1px solid #000000",
          height: 40,
          marginBottom: 1,
          // overflow: "scroll",
        }}
      >
        <ContentCopy sx={{ width: 20 }} />
      </IconButton>{" "}
      &nbsp;
      <EmailShareButton
        subject={title}
        children={
          <EmailIcon style={{ marginTop: -3 }} size={40} round={true} />
        }
        url={url}
      />{" "}
      &nbsp;
      <WhatsappShareButton
        title={title}
        children={
          <WhatsappIcon style={{ marginTop: -3 }} size={40} round={true} />
        }
        url={url}
      />{" "}
      &nbsp;
      <FacebookShareButton
        quote={title}
        children={
          <FacebookIcon style={{ marginTop: -3 }} size={40} round={true} />
        }
        url={url}
      />
      &nbsp;
      <TwitterShareButton
        title={title}
        children={
          <TwitterIcon style={{ marginTop: -3 }} size={40} round={true} />
        }
        url={url}
      />
    </div>
  );
}
