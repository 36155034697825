import { Route, Redirect, RouteProps } from "react-router-dom";
import { accountService } from "../services";

import React from "react";

interface PrivateRouteProps extends RouteProps {
  roles?: string[];
}

function PrivateRoute({
  component: Component,
  roles,
  ...rest
}: PrivateRouteProps) {
  const [small, setSmall] = React.useState(window.innerWidth < 992);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setSmall(window.innerWidth < 992);
    });
  }, []);

  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        const user = accountService.userValue;

        if (user && !user.firstLogin) {
          if (props.location.pathname === "/") {
            return (
              <Redirect
                to={{
                  pathname: "/home",
                  state: { from: props.location },
                }}
              />
            );
          } else return <Component {...props} />;
        } else if (user) {
          if (props.location.pathname !== "/onboarding") {
            return (
              <Redirect
                to={{
                  pathname: "/onboarding",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            return <Component {...props} />;
          }
        } else {
          // not logged in so redirect to signin page with the return url
          return (
            <Redirect
              to={{
                pathname: "/landing",
                state: { from: props.location },
              }}
            />
          );
        }

        // if (!user) {
        //   // not logged in so redirect to signin page with the return url
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/account",
        //         state: { from: props.location },
        //       }}
        //     />
        //   );
        // } else if (!user.username) {
        //   if (props.location.pathname !== "/onboarding") {
        //     return (
        //       <Redirect
        //         to={{
        //           pathname: "/onboarding",
        //           state: { from: props.location },
        //         }}
        //       />
        //     );
        //   }
        // } else if (props.location.pathname === "/onboarding") {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/",
        //         state: { from: props.location },
        //       }}
        //     />
        //   );
        // }

        // // check if route is restricted by role
        // if (roles && roles.indexOf(user.role) === -1) {
        //   // role not authorized so redirect to home page
        //   return <Redirect to={{ pathname: "/" }} />;
        // }

        // // authorized so return component
        // return (
        //   <>
        //     <BootstrapNavbar />
        //     <Component {...props} />
        //     {/* {vsmall && <div style={{ width: "100vw", height: "65px" }}></div>} */}
        //     {small && <SimpleBottomNavigation />}
        //   </>
        // );
      }}
    />
  );
}

export { PrivateRoute };
