import TextField from "@mui/material/TextField";
import { getIn, useFormik } from "formik";
import { useState } from "react";
import { ProfileFormFields } from "../../shared/models/ProfileFormFields";
import * as Yup from "yup";
import { accountService } from "../../services";
import Button from "@mui/material/Button";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Redirect, useHistory } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  profilePic: Yup.string(),
  fullName: Yup.string().required("Full Name is required"),
  birthday: Yup.date(),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "too short")
    .max(10, "too long"),
  address: Yup.object().shape({
    houseNo: Yup.string(),
    locality: Yup.string(),
    city: Yup.string(),
    pinCode: Yup.string()
      .matches(phoneRegExp, "Pin Code is not valid")
      .min(6, "too short")
      .max(6, "too long"),
  }),
});

export default function MyProfile({ location }: { location: any }) {
  var exchangeId = location.state ? location.state.exchangeId : null;

  const history = useHistory();
  const [user, setUser] = useState<ProfileFormFields>(accountService.userValue);
  const [openLoader, setOpenLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      birthday: user
        ? user.birthday
          ? user.birthday.toString().split("T")[0]
          : ""
        : "",
      fullName: user ? user.fullName : "",
      phone: user ? user.phone : "",
      profilePic: user ? user.profilePic : "",
      address: user ? user.address : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setOpenLoader(true);
      if (accountService.userValue.firstLogin) {
        await accountService.update(values);
        if (exchangeId) history.push("/exchange/view/" + exchangeId);
        else history.push("/");
      } else await accountService.update(values);
      setOpenLoader(false);
      alert("Profile details have been saved!");
    },
  });

  const deleteUser = () => {
    setOpenLoader(true);
    accountService
      .deleteUser(accountService.userValue.id)
      .then(() => {
        accountService.logout();
        history.go(0);
        setOpenLoader(false);
      })
      .catch(() => {
        setOpenLoader(false);
      });
  };

  if (
    location.pathname === "/onboarding" &&
    !accountService.userValue.firstLogin
  ) {
    return (
      <Redirect
        to={{
          pathname: "/home",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <form style={{ padding: 10 }} onSubmit={formik.handleSubmit}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TextField
        inputProps={{
          style: {
            background: "#FFFFFF",
            borderRadius: "10px",
          },
        }}
        InputLabelProps={{ shrink: true, required: true }}
        fullWidth
        variant="filled"
        id="fullName"
        name="fullName"
        label="Full Name"
        value={formik.values.fullName}
        onChange={formik.handleChange}
        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
        helperText={formik.touched.fullName && formik.errors.fullName}
        sx={{
          mt: 2,
          mb: 2,
          border: "1px solid #000000",
          borderRadius: "10px",
        }}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        InputProps={{
          inputProps: {
            max: new Date().toISOString().split("T")[0],
          },
          style: {
            background: "#FFFFFF",
            borderRadius: "10px",
          },
        }}
        variant="filled"
        id="birthday"
        name="birthday"
        label="Birthday"
        type="date"
        value={formik.values.birthday}
        onChange={formik.handleChange}
        error={formik.touched.birthday && Boolean(formik.errors.birthday)}
        helperText={formik.touched.birthday && formik.errors.birthday}
        sx={{
          mt: 2,
          mb: 2,
          border: "1px solid #000000",
          borderRadius: "10px",
        }}
      />
      <TextField
        inputProps={{
          min: 0,
          style: {
            background: "#FFFFFF",
            borderRadius: "10px",
          },
        }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        variant="filled"
        id="phone"
        name="phone"
        label="Phone Number"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        sx={{
          mt: 2,
          mb: 2,
          border: "1px solid #000000",
          borderRadius: "10px",
        }}
      />
      <Button
        variant="outlined"
        sx={{
          width: "100%",
          fontFamily: "Neuton",
          fontSize: "20px",
          borderRadius: "10px",
          border: "1px solid #000000",
          color: "#000000",
        }}
        onClick={() => {
          visible ? setVisible(false) : setVisible(true);
        }}
      >
        + Address <ArrowDropDownIcon></ArrowDropDownIcon>
      </Button>
      {visible && (
        <TextField
          inputProps={{
            min: 0,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
            },
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="filled"
          id="houseNo"
          name="address.houseNo"
          label="House No"
          value={getIn(formik.values, "address.houseNo")}
          onChange={formik.handleChange}
          error={
            getIn(formik.touched, "address.houseNo") &&
            Boolean(getIn(formik.errors, "address.houseNo"))
          }
          helperText={
            getIn(formik.touched, "address.houseNo") &&
            getIn(formik.errors, "address.houseNo")
          }
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
        />
      )}
      {visible && (
        <TextField
          inputProps={{
            min: 0,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
            },
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="filled"
          id="locality"
          name="address.locality"
          label="Locality"
          value={getIn(formik.values, "address.locality")}
          onChange={formik.handleChange}
          error={
            getIn(formik.touched, "address.locality") &&
            Boolean(getIn(formik.errors, "address.locality"))
          }
          helperText={
            getIn(formik.touched, "address.locality") &&
            getIn(formik.errors, "address.locality")
          }
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
        />
      )}
      {visible && (
        <TextField
          inputProps={{
            min: 0,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
            },
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="filled"
          id="city"
          name="address.city"
          label="City"
          value={getIn(formik.values, "address.city")}
          onChange={formik.handleChange}
          error={
            getIn(formik.touched, "address.city") &&
            Boolean(getIn(formik.errors, "address.city"))
          }
          helperText={
            getIn(formik.touched, "address.city") &&
            getIn(formik.errors, "address.city")
          }
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
        />
      )}
      {visible && (
        <TextField
          inputProps={{
            min: 0,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
            },
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="filled"
          id="pinCode"
          name="address.pinCode"
          label="Pin Code"
          value={getIn(formik.values, "address.pinCode")}
          onChange={formik.handleChange}
          error={
            getIn(formik.touched, "address.pinCode") &&
            Boolean(getIn(formik.errors, "address.pinCode"))
          }
          helperText={
            getIn(formik.touched, "address.pinCode") &&
            getIn(formik.errors, "address.pinCode")
          }
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
        />
      )}

      <div style={{ textAlign: "center" }}>
        <Button
          // disabled={
          //   formik.values.phone && formik.values.birthday ? false : true
          // }
          style={{
            width: "140px",
            height: "48px",
            borderRadius: "11px",
            border: `1px solid #FF5757`,
            color: `#FFFFFF`,
            backgroundColor: `#FF5757`,
            fontFamily: "Neuton",
            fontStyle: "Normal",
            fontWeight: "300",
            fontSize: "20px",
            lineHeight: "16px",
            textTransform: "none",
            letterSpacing: "0.5px",
          }}
          sx={{
            mt: 2,
            mb: 2,
            width: "50%",
          }}
          color="secondary"
          variant="contained"
          fullWidth
          type="submit"
        >
          Save
        </Button>
        <br />
        {/* Commenting the below line  */}
        {/* {!accountService.userValue.firstLogin && (
          <Button
            sx={{
              color: "#EB5757",
              mt: 1,
              mb: 8,
            }}
            startIcon={<MdDelete style={{ color: "#828282" }} />}
            variant="text"
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            Delete User
          </Button>
        )} */}
      </div>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this profile?
            <i>
              <b>{"  "}This action is irreversible.</b>
            </i>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<MdDelete />}
            onClick={() => {
              deleteUser();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
}
