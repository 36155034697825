import { ArrowRightAlt, Cake, MoreVert, Search } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import SquareButton from "./SquareButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  CakeOutlined,
  FavoriteBorderOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { wishlistService } from "../../services/wishlist.service";
import { useEffect, useState } from "react";
import { accountService } from "../../services";
import { WishlistModel } from "../../shared/models/WishlistModel";
import { useHistory } from "react-router-dom";
import AlertDialog from "../../modules/AlertDialog";
import { ExchangeModel } from "../../shared/models/ExchangeModel";
import { exchangeService } from "../../services/exchange.service";
import ControlledCarousel from "../../modules/ControlledCarousel";
import { Mixpanel } from "../../helpers/mixpanel-helper";

export default function HomePage() {
  const [openLoader, setOpenLoader] = useState(false);
  const [wishlists, setWishlists] = useState<null | Array<WishlistModel>>(null);
  const [exchanges, setExchanges] = useState<null | Array<ExchangeModel>>(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [exchangeAlertOpen, setExchangeAlertOpen] = useState(false);

  const [wishlistIdx, setWishlistIdx] = useState<number | null>(null);
  const [exchangeIdx, setExchangeIdx] = useState<number | null>(null);

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [exchangeAnchorEl, setExchangeAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const exchangeOpen = Boolean(exchangeAnchorEl);
  const handleExchangeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExchangeAnchorEl(event.currentTarget);
  };
  const handleExchangeClose = () => {
    setExchangeAnchorEl(null);
  };

  useEffect(() => {
    Mixpanel.track("Home Page Opened");

    wishlistService
      .getWishlistsByUserId(accountService.userValue.id)
      .then((res) => {
        setWishlists(res);
      });
    exchangeService
      .getExchangesByUserId(accountService.userValue.id)
      .then((res) => setExchanges(res));
  }, []);

  function deleteWishlist(wishlistId: string) {
    wishlistService.deleteWishlist(wishlistId);
    const nextItems =
      wishlists && wishlists.filter((item) => item._id !== wishlistId);
    setWishlists(nextItems);
  }

  function deleteExchange(exchangeId: string) {
    exchangeService.deleteExchange(exchangeId);
    const nextItems =
      exchanges && exchanges.filter((item) => item._id !== exchangeId);
    setExchanges(nextItems);

    setTimeout(function () {
      alert("Your event has been deleted");
    }, 100);
  }

  const initWishlist = async () => {
    const res = await wishlistService.addToWishlist({
      name: "My Wishlist",
      status: "draft",
    });

    return res._id;
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false} sx={{ p: 0, m: 0.2 }}>
        <Box sx={{ width: "95%", m: 1 }}>
          <ControlledCarousel></ControlledCarousel>
          {/* <div
            style={{
              flex: 1,
              height: "1px",
              backgroundColor: "black",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          /> */}
        </Box>
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          spacing={0}
        >
          {/* <Grid sx={{ mb: 2 }} item>
    
              
              <img
              style={{
                width: "99%",
                borderRadius: "15px",
                border: "1px solid #000000",
                cursor: "pointer",
              }}
              onClick={() => history.push("/exchange/add")}
              src="/createExchangeHomePageCarousel.png"
            ></img>
            </Paper>
          </Grid> */}

          <Grid item sx={{ width: "50%" }}>
            <SquareButton
              icon={<FavoriteIcon />}
              text="Create Wishlist"
              url="/wishlist/add"
              image="/3.png"
              setOpenLoader={setOpenLoader}
            />
          </Grid>
          <Grid item sx={{ width: "50%" }}>
            <SquareButton
              icon={<Cake />}
              text="Create Event"
              url="/exchange/add"
              image="/1.png"
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item sx={{ width: "50%" }}>
            <SquareButton
              icon={<Search />}
              text="Search Wishlist"
              url="/search"
              image="/2.png"
            />
          </Grid>
          <Grid item sx={{ width: "50%" }}>
            <SquareButton
              icon={<ShoppingCartOutlined />}
              text="Explore Gifts"
              url="/products"
              image="/4.png"
            />
          </Grid>
        </Grid>
      </Container>
      <Box padding={2}>
        <Paper
          // variant="outlined"
          elevation={4}
          sx={{
            borderRadius: "15px",
            border: "1px solid #000000",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              padding: 2,
              background: "#FFFFFF",
              borderRadius: "15px 15px 0px 0px",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "26px",
              lineHeight: "26px",
              color: "#000000",
            }}
          >
            <FavoriteBorderOutlined fontSize="medium" sx={{ mr: 1 }} /> Your
            Wishlists
          </Typography>
          <img
            style={{ width: "100%", marginTop: 0, borderRadius: "15px" }}
            src="/HomePage1.png"
          ></img>
          <Typography
            variant="h6"
            sx={{
              padding: 2,
              textAlign: "center",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "16px",
              letterSpacing: "0.5px",
            }}
          ></Typography>
          <Box sx={{ padding: 2 }}>
            {wishlists ? (
              <>
                {wishlists[wishlists.length - 1] && (
                  <div style={{ position: "relative", marginBottom: 20 }}>
                    <Button
                      sx={{
                        height: 50,
                        width: "100%",
                        color: "black",
                        justifyContent: "flex-start",
                        textTransform: "none",
                        fontSize: 20,
                        border: "1px solid #000000",
                        borderRadius: "20px",
                        fontFamily: "Neuton",
                      }}
                      color="secondary"
                      variant="outlined"
                      onClick={async () => {
                        if (
                          wishlists[wishlists.length - 1].status === "draft"
                        ) {
                          history.push(
                            "/wishlist/add/" +
                              wishlists[wishlists.length - 1]._id
                          );
                        } else
                          history.push("/wishlist/view", {
                            data: wishlists[wishlists.length - 1],
                          });
                      }}
                    >
                      {wishlists[wishlists.length - 1].name}
                    </Button>
                    <div
                      style={{
                        top: "5px",
                        right: "0px",
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    >
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => {
                          setWishlistIdx(wishlists.length - 1);
                          handleClick(e);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </div>
                    {wishlists[wishlists.length - 1].status === "draft" ? (
                      <Box
                        sx={{
                          borderColor: "white",
                          height: 25,
                          border: "1px solid white",
                        }}
                      >
                        <Typography
                          sx={{
                            ml: 1,
                            color: "red",
                            fontFamily: "Neuton",
                            fontSize: "15px",
                          }}
                          variant="caption"
                        >
                          Draft State : Wishlist not saved yet!
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        sx={{ ml: 1, fontFamily: "Neuton", fontSize: "15px" }}
                        variant="caption"
                      >
                        {wishlists[wishlists.length - 1].items &&
                          wishlists[wishlists.length - 1].items.length +
                            " wishes"}
                      </Typography>
                    )}
                  </div>
                )}
                {wishlists[wishlists.length - 2] && (
                  <div style={{ position: "relative", marginBottom: 20 }}>
                    <Button
                      sx={{
                        height: 50,
                        width: "100%",
                        color: "black",
                        justifyContent: "flex-start",
                        textTransform: "none",
                        fontSize: 20,
                        border: "1px solid #000000",
                        borderRadius: "20px",
                        fontFamily: "Neuton",
                      }}
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        if (wishlists[wishlists.length - 2].status === "draft")
                          history.push(
                            "/wishlist/add/" +
                              wishlists[wishlists.length - 2]._id
                          );
                        else
                          history.push("/wishlist/view", {
                            data: wishlists[wishlists.length - 2],
                          });
                      }}
                    >
                      {wishlists[wishlists.length - 2].name}
                    </Button>
                    <div
                      style={{
                        top: "5px",
                        right: "0px",
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    >
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => {
                          setWishlistIdx(wishlists.length - 2);
                          handleClick(e);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </div>
                    {wishlists[wishlists.length - 2].status === "draft" ? (
                      <Box
                        sx={{
                          borderColor: "white",
                          height: 25,
                          border: "1px solid white",
                        }}
                      >
                        <Typography
                          sx={{
                            ml: 1,
                            color: "red",
                            fontFamily: "Neuton",
                            fontSize: "15px",
                          }}
                          variant="caption"
                        >
                          Draft State : Wishlist not saved yet!
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        sx={{ ml: 1, fontFamily: "Neuton", fontSize: "15px" }}
                        variant="caption"
                      >
                        {wishlists[wishlists.length - 2].items &&
                          wishlists[wishlists.length - 2].items.length +
                            " wishes"}
                      </Typography>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div style={{ width: "100%", textAlign: "center" }}>
                <CircularProgress />
              </div>
            )}
            {wishlistIdx !== null && wishlists && (
              <>
                <AlertDialog
                  title="Delete Wishlist"
                  body="Are you sure you want to delete this wishlist?"
                  open={alertOpen}
                  setOpen={setAlertOpen}
                  agreeFunc={() => deleteWishlist(wishlists[wishlistIdx]._id)}
                  disagreeFunc={() => {}}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* <MenuItem
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Share
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      history.push("/wishlist/view", {
                        data: wishlists[wishlistIdx],
                      });
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setAlertOpen(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                style={{
                  width: "150px",
                  height: "48px",
                  borderRadius: "11px",
                  border: "1px solid #FF5757",
                  color: "#FF5757",
                  textTransform: "none",
                  fontFamily: "Neuton",
                  fontStyle: "Normal",
                  fontWeight: "300",
                  fontSize: "17px",
                  lineHeight: "10px",
                }}
                onClick={() => history.push("/wishlists")}
              >
                View Wishlists
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                style={{
                  width: "150px",
                  height: "48px",
                  borderRadius: "11px",
                  border: "1px solid #FF5757",
                  color: "#FFFFFF",
                  backgroundColor: "#FF5757",
                  fontFamily: "Neuton",
                  fontStyle: "Normal",
                  fontWeight: "300",
                  fontSize: "17px",
                  lineHeight: "20px",
                  textTransform: "none",
                }}
                onClick={async () => {
                  setOpenLoader(true);
                  const wishlistId = await initWishlist();
                  setOpenLoader(false);
                  history.push("/wishlist/add/" + wishlistId);
                }}
              >
                Create Wishlist
              </Button>
            </Grid>
          </Grid>
          <br />
        </Paper>

        <br />
        <Paper
          // variant="outlined"
          elevation={4}
          sx={{ borderRadius: "15px", border: "1px solid #000000" }}
        >
          <Typography
            variant="h5"
            sx={{
              padding: 2,
              background: "#FFFFFF",
              borderRadius: "15px 15px 0px 0px",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "26px",
              lineHeight: "26px",
              color: "#000000",
            }}
          >
            <CakeOutlined fontSize="medium" sx={{ mr: 1 }} /> Your Events
          </Typography>
          <img
            style={{ width: "100%", marginTop: 0, borderRadius: "15px" }}
            src="/HomePage2.png"
          ></img>
          <Typography
            variant="h6"
            sx={{
              padding: 2,
              textAlign: "center",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "16px",
              letterSpacing: "0.5px",
            }}
          ></Typography>
          <Box sx={{ padding: 2 }}>
            {exchanges ? (
              <>
                {exchanges[exchanges.length - 1] && (
                  <div style={{ position: "relative", marginBottom: 20 }}>
                    <Button
                      sx={{
                        height: 50,
                        width: "100%",
                        color: "black",
                        justifyContent: "flex-start",
                        textTransform: "none",
                        fontSize: 20,
                        border: "1px solid #000000",
                        borderRadius: "20px",
                        fontFamily: "Neuton",
                      }}
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        history.push(
                          "/exchange/view/" +
                            exchanges[exchanges.length - 1]._id,
                          {
                            data: exchanges[exchanges.length - 1],
                          }
                        );
                      }}
                    >
                      {exchanges[exchanges.length - 1].name}
                    </Button>
                    <div
                      style={{
                        top: "5px",
                        right: "0px",
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    >
                      {exchanges[exchanges.length - 1].organiser &&
                      exchanges[exchanges.length - 1].organiser._id ===
                        accountService.userValue.id ? (
                        <IconButton
                          id="exchange-button"
                          aria-controls={
                            exchangeOpen ? "exchange-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={exchangeOpen ? "true" : undefined}
                          onClick={(e) => {
                            setExchangeIdx(exchanges.length - 1);
                            handleExchangeClick(e);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      ) : (
                        <ArrowRightAlt
                          onClick={() => {
                            history.push(
                              "/exchange/view/" +
                                exchanges[exchanges.length - 1]._id,
                              {
                                data: exchanges[exchanges.length - 1],
                              }
                            );
                          }}
                          sx={{ mt: 1, mr: 1 }}
                        />
                      )}
                    </div>
                  </div>
                )}
                {exchanges[exchanges.length - 2] && (
                  <div style={{ position: "relative", marginBottom: 20 }}>
                    <Button
                      sx={{
                        height: 50,
                        width: "100%",
                        color: "black",
                        justifyContent: "flex-start",
                        textTransform: "none",
                        fontSize: 20,
                        border: "1px solid #000000",
                        borderRadius: "20px",
                        fontFamily: "Neuton",
                      }}
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        history.push(
                          "/exchange/view/" +
                            exchanges[exchanges.length - 2]._id,
                          {
                            data: exchanges[exchanges.length - 2],
                          }
                        );
                      }}
                    >
                      {exchanges[exchanges.length - 2].name}
                    </Button>
                    <div
                      style={{
                        top: "5px",
                        right: "0px",
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    >
                      {exchanges[exchanges.length - 2].organiser &&
                      exchanges[exchanges.length - 2].organiser._id ===
                        accountService.userValue.id ? (
                        <IconButton
                          id="exchange-button"
                          aria-controls={
                            exchangeOpen ? "exchange-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={exchangeOpen ? "true" : undefined}
                          onClick={(e) => {
                            setExchangeIdx(exchanges.length - 2);
                            handleExchangeClick(e);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      ) : (
                        <ArrowRightAlt
                          onClick={() => {
                            history.push(
                              "/exchange/view/" +
                                exchanges[exchanges.length - 2]._id,
                              {
                                data: exchanges[exchanges.length - 2],
                              }
                            );
                          }}
                          sx={{ mt: 1, mr: 1 }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div style={{ width: "100%", textAlign: "center" }}>
                <CircularProgress />
              </div>
            )}
            {exchanges && exchangeIdx !== null && (
              <>
                <AlertDialog
                  title="Delete Event"
                  body="Are you sure you want to delete this event?"
                  open={exchangeAlertOpen}
                  setOpen={setExchangeAlertOpen}
                  agreeFunc={() => deleteExchange(exchanges[exchangeIdx]._id)}
                  disagreeFunc={() => {}}
                />
                <Menu
                  id="exchange-menu"
                  anchorEl={exchangeAnchorEl}
                  open={exchangeOpen}
                  onClose={handleExchangeClose}
                  MenuListProps={{
                    "aria-labelledby": "exchange-button",
                  }}
                >
                  {/* <MenuItem
                    onClick={() => {
                      handleExchangeClose();
                    }}
                  >
                    Share
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      handleExchangeClose();
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleExchangeClose();
                      setExchangeAlertOpen(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                style={{
                  width: "150px",
                  height: "48px",
                  borderRadius: "11px",
                  border: "1px solid #FF5757",
                  color: "#FF5757",
                  textTransform: "none",
                  fontFamily: "Neuton",
                  fontStyle: "Normal",
                  fontWeight: "300",
                  fontSize: "17px",
                  lineHeight: "10px",
                }}
                onClick={() => history.push("/exchanges")}
              >
                View Events
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                style={{
                  width: "150px",
                  height: "48px",
                  borderRadius: "11px",
                  border: "1px solid #FF5757",
                  color: "#FFFFFF",
                  backgroundColor: "#FF5757",
                  fontFamily: "Neuton",
                  fontStyle: "Normal",
                  fontWeight: "300",
                  fontSize: "17px",
                  lineHeight: "20px",
                  textTransform: "none",
                }}
                onClick={() => history.push("/exchange/add")}
              >
                Create Event
              </Button>
            </Grid>
          </Grid>
          <br />
        </Paper>
        <br />
        <Paper
          // variant="outlined"
          elevation={4}
          sx={{ borderRadius: "15px", border: "1px solid #000000" }}
        >
          <Typography
            variant="h5"
            sx={{
              padding: 2,
              background: "#FFFFFF",
              borderRadius: "15px 15px 0px 0px",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "26px",
              lineHeight: "26px",
              color: "#000000",
            }}
          >
            <SearchOutlined fontSize="medium" sx={{ mr: 1 }} /> Search Wishlists
          </Typography>
          <img
            style={{ width: "100%", marginTop: 0, borderRadius: "15px" }}
            src="/HomePage3.png"
          ></img>
          <Typography
            variant="h6"
            sx={{
              padding: 2,
              textAlign: "center",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "16px",
              letterSpacing: "0.5px",
            }}
          ></Typography>

          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                style={{
                  width: "172px",
                  height: "48px",
                  borderRadius: "11px",
                  border: "1px solid #FF5757",
                  color: "#FFFFFF",
                  backgroundColor: "#FF5757",
                  fontFamily: "Neuton",
                  fontStyle: "Normal",
                  fontWeight: "300",
                  fontSize: "20px",
                  lineHeight: "20px",
                  textTransform: "none",
                }}
                onClick={() => history.push("/search")}
              >
                Search Wishlist
              </Button>
            </Grid>
          </Grid>
          <br />
        </Paper>
        <br />
        <Paper
          // variant="outlined"
          elevation={4}
          sx={{ borderRadius: "15px", border: "1px solid #000000" }}
        >
          <Typography
            variant="h5"
            sx={{
              padding: 2,
              background: "#FFFFFF",
              borderRadius: "15px 15px 0px 0px",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "26px",
              lineHeight: "26px",
              color: "#000000",
            }}
          >
            <ShoppingCartOutlined fontSize="medium" sx={{ mr: 1 }} /> Explore
            Gifts
          </Typography>
          <img
            style={{
              width: "100%",
              margin: "auto",
              borderRadius: "15px",
            }}
            src="/HomePage4.png"
          ></img>
          <Typography
            variant="h6"
            sx={{
              padding: 2,
              textAlign: "center",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "16px",
              letterSpacing: "0.5px",
            }}
          ></Typography>

          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                style={{
                  width: "172px",
                  height: "48px",
                  borderRadius: "11px",
                  border: "1px solid #FF5757",
                  color: "#FFFFFF",
                  backgroundColor: "#FF5757",
                  fontFamily: "Neuton",
                  fontStyle: "Normal",
                  fontWeight: "300",
                  fontSize: "20px",
                  lineHeight: "20px",
                  textTransform: "none",
                }}
                onClick={() => history.push("/products")}
              >
                Explore Gifts
              </Button>
            </Grid>
          </Grid>
          <br />
        </Paper>
      </Box>
      <br />
      <br />
      <br />
    </Box>
  );
}
