import React from "react";

export default function ComingSoon() {
  return (
    <img
      style={{ width: "100%", marginTop: "15vh" }}
      src="/coming-soon.png"
    ></img>
  );
}
