const productsArray = [
  {
    name: "Friends Music Box",
    price: "Rs. 599",
    stars: "4.78",
    reviews: " 9 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-03-24at4.43.45PM.jpg?v=1616662656",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-03-24at4.43.45PM_1.jpg?v=1616662658",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-03-24at4.43.46PM_1.jpg?v=1616662661",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-03-24at4.43.46PM.jpg?v=1616662664",
    ],
    productUrl:
      "https://thepeppystore.in//collections/f-r-i-e-n-d-s-tv-official-licensed/products/friends-music-box",
  },
  {
    name: "Permission To Dance - Mus ...",
    price: "Rs. 1,299",
    stars: "4.89",
    reviews: " 9 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-11-10at12.35.17PM_1.jpg?v=1648124539",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-11-10at12.35.42PM.jpg?v=1648124539",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-11-10at12.35.17PM.jpg?v=1648124539",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-01-25at4.05.30PM.jpg?v=1648124539",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-01-25at4.05.30PM_1.jpg?v=1648124505",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-01-25at4.05.31PM_1.jpg?v=1645180967",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-01-25at4.05.31PM.jpg?v=1645180970",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/permission-to-dance-music-plaque-with-led-lamp",
  },
  {
    name: "The Office Sign - Engrave ...",
    price: "Rs. 599",
    stars: "4.67",
    reviews: " 6 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/THEOFFICEBACKGROUNDCropFinal.jpg?v=1636021648",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/TheOfficeWhiteBackgroundFinal.jpg?v=1636021650",
    ],
    productUrl:
      "https://thepeppystore.in//collections/the-office-official-merchandise/products/the-office-sign-engraved",
  },
  {
    name: "Harry Potter 3D Mug Hogwa ...",
    price: "Rs. 1,699",
    stars: "5",
    reviews: " 6 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/0001-6706706409_20210827_062748_0000.png?v=1630051435",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/0001-6706676501_20210827_062659_0000.png?v=1630051443",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/0001-6706725065_20210827_062815_0000.png?v=1630051454",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/0001-6706755507_20210827_062849_0000.png?v=1630051461",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/harry-potter-3d-mug-hogwarts-591.jpg?v=1630051463",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/harry-potter-3d-mug-hogwarts-375.jpg?v=1630051465",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/harry-potter-3d-mug-hogwarts-mug",
  },
  {
    name: "Bts Mini Figures ( Set Of ...",
    price: "Rs. 899",
    stars: "5",
    reviews: " 4 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/6118PNKfILL._SS1000_ae43f254-ad05-46b4-91fb-529cb6f03063.jpg?v=1629898403",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/61TjKtovmvL._SS1000_54417d62-64da-4f51-91e2-f3b7b183c4cb.jpg?v=1629898404",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/61-C_4d5BvL._SS1000_56d68157-16ae-4e73-855a-efe502a43e68.jpg?v=1629898407",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/71rCvzcaG4L._SS1000_7b299de5-319a-4e73-9c34-1d83741e90f1.jpg?v=1629898409",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/copy-of-bts-figuries-set-of-7-7-cm",
  },
  {
    name: "Music Box",
    price: "Rs. 599",
    stars: "5",
    reviews: " 4 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Harry_Potter_Music_Box30_1024x1024_ee6bfbe1-82f8-4f0b-8d5e-873f16e12f23.jpg?v=1597304344",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Harry_Potter_Music_Box62_1024x1024_e28e4283-5e1b-4224-95e9-44e3c161e801.jpg?v=1601032934",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Harry_Potter_Music_Box34_1024x1024_2f955bdb-987b-4d83-89d3-017d06977f89.jpg?v=1601032934",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Harry_Potter_Music_Box32_1024x1024_02d38c74-f22f-4485-ac07-4fdf1f691a3d.jpg?v=1601032934",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/harry-potter-music-box",
  },
  {
    name: "Bts Cartoon Miniature Mer ...",
    price: "Rs. 1,199",
    stars: "5",
    reviews: " 4 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-05-07at6.09.31PM.jpg?v=1620572571",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-05-07at6.09.32PM_2.jpg?v=1620572573",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-05-07at6.09.31PM_1.jpg?v=1620572575",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-05-07at6.09.32PM_3.jpg?v=1620572577",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-05-07at6.09.32PM.jpg?v=1620572580",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-05-07at6.09.32PM_1.jpg?v=1620572582",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/bts-cartoon-miniature",
  },
  {
    name: "Harry Potter Bobble Head  ...",
    price: "Rs. 1,000",
    stars: "5",
    reviews: " 3 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2020-11-13at7.54.52PM.jpg?v=1646124561",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/e9fa407b-888e-4ce9-a6cc-8c48d658c9db.png?v=1646124561",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/754632bc-83d6-4358-98b2-aa3dfa6bacc3.png?v=1646124561",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/ezgif.com-crop_2_a51d9a9c-493d-4bdc-ad3a-b1386734e263.gif?v=1646124561",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/13a852e94d4e443470c8e41a15e406ae_zimken_99392e7e-3009-4747-b808-fed2caa111ee.jpg?v=1646124561",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/vintage-pocket-watch-with-chain_707_da69ef53-5e4c-4739-9396-961da9d78ed6.jpg?v=1646124561",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/vintage-pocket-watch-with-chain_1_833_b3cfc939-f73a-4e00-8f82-b46fcd62f7c5.jpg?v=1646124561",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/vintage-pocket-watch-with-chain_2_337_2b1530ff-759b-4a46-8961-30cef53b3452.jpg?v=1646124561",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/harry-potter-bobble-head-pocket-watch-card-combo",
  },
  {
    name: "Harry Potter Hedwig Backp ...",
    price: "Rs. 2,299",
    stars: "5",
    reviews: " 3 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/2f18c973-f5da-4913-a999-14a8c5e87b31.jpg?v=1606215957",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/7aba3d0b-4548-43bf-ba56-eed993de939c.jpg?v=1606215960",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/ce2bf417-a2c9-4c0a-a661-f834163f7d3f.jpg?v=1606215962",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/0476ac0d-7d68-43be-ab18-bfbccf268827.jpg?v=1606215964",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/H14c0c3f3b61748d29427aad47ddf295cg.jpg?v=1645180477",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/H737e7bda0fe544edbcf591216558b5ecZ.jpg?v=1645180479",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/H06750e4107ee4d6891b20c9e31516d533.jpg?v=1645180481",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Hcc25abdd5de5400991cd85db168d717fM.jpg?v=1645180483",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/harry-potter-hedwig-backpack",
  },
  {
    name: "Bts / Bt21 Suga & Shooky  ...",
    price: "Rs. 899",
    stars: "5",
    reviews: " 3 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-19at5.23.14PM.jpg?v=1653806747",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/bts-bt21-suga-shooky-combo-of-8-pcs",
  },
  {
    name: "Cute Toast Night Light -  ...",
    price: "Rs. 1,499",
    stars: "5",
    reviews: " 22 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-16at12.14.11PM_1.jpg?v=1661640680",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/S3dd854bba16e40068190b3b8ae9c06e5k_jpg_640x640Q90_jpg.webp?v=1667438331",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Sb212338f19824bb38b3870790383b7aeJ_jpg_640x640Q90_jpg.webp?v=1667438331",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-16at12.14.12PM_1.jpg?v=1667438330",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/S4a00123e3072464d9a2c598ec074dacel_400x_2cbbd411-f16b-4f10-9e41-45b64550b417.webp?v=1667438330",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/c78a089f-5d31-4797-9ac9-ecb37d72b5c7.56c27d86abcd0840729a22ba322f064e.jpg?v=1667438330",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/tnzosArray_2.jpg?v=1667438330",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-16at12.14.12PM.jpg?v=1667438330",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/f6c59a82-20a6-4b8f-8769-5097f2f4e9e9.c53da5c18bb91c1cfcb2d01e875f2b12.jpg?v=1667438330",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-16at12.14.10PM.jpg?v=1667438330",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/cute-toast-night-light",
  },
  {
    name: "Iron Man Bobblehead - Sol ...",
    price: "Rs. 599",
    stars: "5",
    reviews: " 2 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/im.jpg?v=1649578012",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/41DXtzPe7VL.jpg?v=1649578012",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/HTB12.pySpXXXXc0XpXXq6xXFXXXk_720x_9257e502-353c-4cbd-b63f-f4718158393b.jpg?v=1596293719",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/41gTmNDsabL.jpg?v=1596293723",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/41njtqvokOL.jpg?v=1596293727",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/iron-man-bobblehead-solar",
  },
  {
    name: "Harry Potter 6Pc Combo",
    price: "Rs. 1,899",
    stars: "5",
    reviews: " 2 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-23at1.56.52PM_2.jpg?v=1661641318",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-23at1.56.52PM_1.jpg?v=1661641320",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-23at1.56.52PM.jpg?v=1661641322",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/harry-potter-6-pc-combo",
  },
  {
    name: "Dunder Mifflin Led Lamp W ...",
    price: "Rs. 1,199",
    stars: "5",
    reviews: " 2 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-11-03at7.30.35PM_1.jpg?v=1635949546",
    ],
    productUrl:
      "https://thepeppystore.in//collections/the-office-official-merchandise/products/dunder-mifflin-led-lamp-with-spotify-barcode",
  },
  {
    name: "Bts T-Shirt Merchandise-  ...",
    price: "Rs. 599",
    stars: "5",
    reviews: " 2 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-04-11at5.55.42PM_1.jpg?v=1618225986",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/bts-t-shirt-2",
  },
  {
    name: "Bts Army Bomb Keychain +  ...",
    price: "Rs. 599",
    stars: "5",
    reviews: " 2 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/70d2e676fdf8c91aa889828ffa3d50ca_1.jpg?v=1647173341",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/297c3df457817d6d09f638da902df102.jpg?v=1647173343",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/70d2e676fdf8c91aa889828ffa3d50ca.jpg?v=1647173345",
    ],
    productUrl:
      "https://thepeppystore.in//collections/keychains/products/bts-army-bomb-keychain-bagcharm-strap",
  },
  {
    name: "Cinematic Light Box - 96  ...",
    price: "Rs. 685",
    stars: "5",
    reviews: " 2 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/DY00018C_1024x1024_d6ca4414-e639-4680-8825-314d60232710.jpg?v=1596293218",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/DYS101A.jpg?v=1605686637",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/DY00018_1024x1024_9c2b55b2-c3ff-4dd8-8e20-498b277752be.jpg?v=1603808235",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/DY00018D_1024x1024_49e8e384-5b64-426f-bc7f-03a261a7af44.jpg?v=1603808235",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/cinematic-light-box-96-letters-a4-size",
  },
  {
    name: "Panda Touch Silicone Lamp ...",
    price: "Rs. 1,499",
    stars: "5",
    reviews: " 11 reviews ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/PANDA1.jpg?v=1615101386",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/PANDA2_f5b3f956-c90f-417c-b138-6532ae06d56a.jpg?v=1615101392",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/PANDA3_9c71a998-3338-4610-b09a-d592287a07a5.jpg?v=1615101397",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/PANDA4.jpg?v=1615101401",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/panda-touch-silicone-lamp",
  },
  {
    name: "Harry Potter Wand Combo ( ...",
    price: "Rs. 2,999",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-30at4.45.55PM_2.jpg?v=1650695994",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-30at4.45.55PM.jpg?v=1650695994",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-30at4.45.55PM_1.jpg?v=1650695994",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/harry-potter-combo-set-of-7",
  },
  {
    name: "Bts / Bt21 Jimin & Chimmy ...",
    price: "Rs. 1,000",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-18at6.12.32PM.jpg?v=1653806738",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-18at6.12.32PM_1.jpg?v=1653806740",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/bts-bt21-jimin-chimmy-combo",
  },
  {
    name: "Harry Potter Hogwarts Led ...",
    price: "Rs. 1,199",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-04-01at7.11.37PM.jpg?v=1650187108",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-04-01at7.11.38PM.jpg?v=1650187110",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-04-01at7.11.38PM_1.jpg?v=1650187112",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-04-01at7.11.39PM.jpg?v=1650187114",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/hogwarts-led-plaque-led-stand",
  },
  {
    name: "The Office - Michael Scot ...",
    price: "Rs. 1,299",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-15at12.42.04PM.jpg?v=1647508719",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-15at12.42.04PM_1.jpg?v=1647508721",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-15at12.42.04PM_2.jpg?v=1647508723",
    ],
    productUrl:
      "https://thepeppystore.in//collections/the-office-official-merchandise/products/the-office-michael-scott-led-with-stand",
  },
  {
    name: "Harry Potter Perfect Gift ...",
    price: "Rs. 1,500",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-01at12.24.46PM_3.jpg?v=1646143228",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-01at12.24.46PM_2.jpg?v=1646143230",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/combo-save-upto-50",
  },
  {
    name: "Naruto Bobble Head + Phon ...",
    price: "Rs. 599",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/naruto-bobble-head-uzumaki-naruto-figure-737885749264_530x_2x_f91b44ee-5d9b-4318-8ae0-4e383e9deb2a.jpg?v=1597310911",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Capture_naaruto.jpg?v=1597310920",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Capture_nnaa.jpg?v=1597310924",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/5105-a45670abee09d63a03cc13b35141af80-640x640_ff9dd96b-6167-49fd-98d1-be737084c78f.jpg?v=1597310928",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/naruto-bobble-head",
  },
  {
    name: "Harry Potter Combo (Set O ...",
    price: "Rs. 2,299",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-30at19.22.16_1.jpg?v=1650187094",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-30at19.28.55.jpg?v=1650187097",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/harry-potter-combo-set-of-8",
  },
  {
    name: "Harry Potter Bobble Head  ...",
    price: "Rs. 1,000",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-01at12.42.59PM_1.jpg?v=1651233465",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-04-29at4.57.58PM_1.jpg?v=1651570135",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-01at12.42.58PM.jpg?v=1651233465",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-03-01at12.42.59PM.jpg?v=1651233465",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-04-29at4.57.57PM.jpg?v=1651570137",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-04-29at4.57.58PM.jpg?v=1651570139",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/harry-potter-bobble-head-pocket-watch-card-combo-1",
  },
  {
    name: "Panda Figurine - 15 Cm",
    price: "Rs. 1,250",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-25at12.39.01PM.jpg?v=1661641665",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-25at12.38.12PM_1.jpg?v=1661641667",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-25at12.38.12PM.jpg?v=1661641669",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-25at12.38.11PM_1.jpg?v=1661641670",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-25at12.38.12PM_2.jpg?v=1661641672",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-25at12.38.11PM.jpg?v=1661641675",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-08-25at12.38.10PM.jpg?v=1661641676",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Screen_Shot_2020-01-10_at_5.02.46_PM.png?v=1661411787",
    ],
    productUrl:
      "https://thepeppystore.in//collections/best-selling-products/products/panda-figurine-15-cm",
  },
  {
    name: "Friends Neon Light",
    price: "Rs. 2,500",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-08-19at12.24.07PM_1.jpg?v=1629898709",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-08-19at12.24.06PM.jpg?v=1629898712",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-08-19at12.24.07PM.jpg?v=1629898714",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-08-19at12.24.06PM_1.jpg?v=1629898717",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/il_794xN.2563024335_ryhj_540x_0f8f7e0d-066e-493c-9218-b3ff5ed6610e.jpg?v=1629898719",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/il_794xN.2515356866_qa6z_540x_49f1bea3-8583-48c8-bf04-ca4e0e24a00b.jpg?v=1629898722",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/il_794xN.2515356510_itfk_540x_91a484ee-34bc-4755-b18a-2ca8b08ccdc2.jpg?v=1629898724",
    ],
    productUrl:
      "https://thepeppystore.in//collections/f-r-i-e-n-d-s-tv-official-licensed/products/friends-neon-light",
  },
  {
    name: "Harry Potter Miniature",
    price: "Rs. 599",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/TPS125-C.png?v=1639379755",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2020-12-15at6.30.24PM_1.jpg?v=1638867675",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2020-12-15at6.30.24PM_2.jpg?v=1638867675",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2020-12-15at6.30.24PM.jpg?v=1638867675",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/harry-potter-miniature",
  },
  {
    name: "Mickey And Minnie Mouse F ...",
    price: "Rs. 599",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-29at3.40.35PM_1.jpg?v=1654142394",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-29at3.40.32PM.jpg?v=1654142395",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-29at3.40.33PM.jpg?v=1654142397",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-29at3.40.34PM.jpg?v=1654142399",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-29at3.40.35PM.jpg?v=1654142401",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/mickey-and-minnie-mouse-figures-set-of-2",
  },
  {
    name: "Bts Figuries 11 / 13 Cm - ...",
    price: "Rs. 499",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-06-11at6.40.57PM_2.jpg?v=1623734588",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-06-11at6.40.57PM_4.jpg?v=1623734590",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-06-11at6.40.58PM_4.jpg?v=1623734592",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-06-11at6.40.59PM_2.jpg?v=1623734594",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-06-11at6.40.59PM_4.jpg?v=1623734597",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-06-11at6.40.58PM_5.jpg?v=1623734599",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-06-11at6.40.58PM_6.jpg?v=1623734602",
    ],
    productUrl:
      "https://thepeppystore.in//collections/keychains/products/bts-figuries-11-13-cm-big-size",
  },
  {
    name: "Black Panther Bobblehead ...",
    price: "Rs. 599",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/black-panther-bobblehead-144.jpg?v=1602413327",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/black-panther-bobblehead-588.jpg?v=1602413331",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/black-panther-bobblehead-750.jpg?v=1602413335",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/copy-of-minion-bobblehead",
  },
  {
    name: "Friends Combo",
    price: "Rs. 899",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-08-31at5.24.12PM.jpg?v=1630493336",
    ],
    productUrl:
      "https://thepeppystore.in//collections/f-r-i-e-n-d-s-tv-official-licensed/products/friends-combo-2",
  },
  {
    name: "Harry Potter Set Of 4 Com ...",
    price: "Rs. 1,650",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-11-25at12.56.10PM_1.jpg?v=1639379759",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-11-25at12.56.10PM.jpg?v=1639379762",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2021-11-25at12.56.10PM_2.jpg?v=1639379764",
    ],
    productUrl:
      "https://thepeppystore.in//collections/harry-potter/products/harry-potter-set-of-4-combo",
  },
  {
    name: "Bts / Bt21 V.Taehyung & T ...",
    price: "Rs. 1,000",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/WhatsAppImage2022-05-18at6.09.17PM.jpg?v=1653806745",
    ],
    productUrl:
      "https://thepeppystore.in//collections/bobble-heads/products/bts-bt21-v-taehyung-tata-combo",
  },
  {
    name: "The Office Wall Art",
    price: "Rs. 1,199",
    stars: "5",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Capture_the.jpg?v=1596293604",
      "https://cdn.shopify.com/s/files/1/0036/7637/3061/products/Capturethe.jpg?v=1596293608",
    ],
    productUrl:
      "https://thepeppystore.in//collections/the-office-official-merchandise/products/the-office-wall-art",
  },
  {
    name: "Friends Tv Series Peephol ...",
    price: "Rs. 1,299",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/61lW6K-evyL._SL1500_7e8d83ad-1fda-4793-a8a9-e5e282766a87_480x480.jpg?v=1601942695",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-show-peephole-friends-frame-monicas-door-present-friends-fan",
  },
  {
    name: "Christmas Double Walled M ...",
    price: "Rs. Rs.949.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_4a8a6c0e-9943-4d91-9187-fbf85f04e948_480x480.png?v=1636812019",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/christmas-double-walled-mug-with-lid",
  },
  {
    name: "Harry Potter Hogwarts Acc ...",
    price: "Rs. 999",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81WVlC_2BJT2L._SL1500_480x480.jpg?v=1571785467",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-hogwarts-acceptance-letter-official-warner-bros",
  },
  {
    name: "Cute Piggy Night Lamp",
    price: "Rs. Rs.1,099.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_ac75866a-af0d-4847-a11c-033011ea109d_480x480.png?v=1636530000",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-piggy-night-lamp",
  },
  {
    name: "Buddha Monk Solar Powered ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_dde331ed-d431-490d-9140-eb95da92be96_480x480.png?v=1658052744",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/buddha-monk-solar-powered-bobblehead",
  },
  {
    name: "Unicorn Dream Catcher Wit ...",
    price: "Rs. Rs.699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_a770bbad-b8c7-48ac-834c-eab11fafb91c_480x480.png?v=1633534106",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/unicorn-dream-catcher-with-led",
  },
  {
    name: "Cute Shimmer Sipper With  ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/botella-de-agua-de-color-caramelo-para-n_main-3_480x480.jpg?v=1624472701",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/copy-of-cute-pastel-sippers",
  },
  {
    name: "Queen Of Everything Mug | ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/10_480x480.png?v=1615295929",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/queen-of-everything-mug-1",
  },
  {
    name: "Powerpuff Girls Keychains ...",
    price: "Rs. Rs.499.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/9_3c4a000a-d92c-470f-90b5-bd035cd72875_480x480.png?v=1657357475",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/powerpuff-girls-keychains-the-quirky-quest",
  },
  {
    name: "Harry Potter Combo Set (  ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/91fpycqr3HL._SL1500_480x480.jpg?v=1571785462",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-combo-pack-of-2-house-crest-multi-color-daily-planner-notebook-and-magnetic-bookmarks-officially-licensed",
  },
  {
    name: "Cute Angel Fairy White La ...",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/PrettyLittleAngelLamp_800x_da000417-dfe6-405b-94f3-fb854110704c_480x480.jpg?v=1620407768",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-angel-fairy-white-lamp",
  },
  {
    name: "Naruto Akatsuki All Membe ...",
    price: "Rs. 399",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/71Sh2k6YzIL._SL1500_480x480.jpg?v=1574490865",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/anime-akatsuki-all-members-designed-coffee-mug-gift-set-birthday-gift-anniversary-gift",
  },
  {
    name: "Personalised Temperature  ...",
    price: "Rs. Rs.749.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_b4d72ec9-71ee-4c52-82b0-d74dea7d709c_480x480.jpg?v=1664187434",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/personalised-temperature-bottle-no-cod-the-quirky-quest",
  },
  {
    name: "Friends Tv Series Pack Of ...",
    price: "Rs. 599",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/MCSNOTEBOOK_68_130_480x480.jpg?v=1601943243",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-pack-of-2-infographic-doodle-a5-notebook",
  },
  {
    name: "Cute Unicorn Pen Stand",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/2_5542aef8-9710-4bbc-983f-3fd64c5fea97_480x480.png?v=1622201744",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-unicorn-pen-stand",
  },
  {
    name: "Reindeer Touch Lamp (7 Co ...",
    price: "Rs. Rs.1,699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_5c4e1f4a-95b2-460a-84c5-6305ca0cd642_480x480.png?v=1652378912",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/reindeer-touch-lamp-7-colours-the-quirky-quest",
  },
  {
    name: "Kawaii Baby Angel Lamp Â€ ...",
    price: "Rs. Rs.799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_48c08a63-5e69-4b0d-a788-dfc4de275f9b_480x480.png?v=1652802587",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/kawaii-baby-angel-lamp-single-piece-assorted",
  },
  {
    name: "Friends Tv Series Doodle  ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/91E-BCc9LTL._SL1500_fcae3b2e-3b2a-49c1-a3b6-b3abdf3bdbd3_480x480.jpg?v=1571785393",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-doodle-table-clocks",
  },
  {
    name: "Hedwig With Post Belt Bag ...",
    price: "Rs. Rs.1,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_0ff77b83-3c1d-4601-bbc0-7d26834b0f7e_480x480.png?v=1651665146",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/hedwig-with-post-belt-bag-the-quirky-quest",
  },
  {
    name: "Harry Potter With Wand Fi ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_434c641f-2e15-43a2-a237-9233b74ecc64_480x480.png?v=1654598668",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/harry-potter-with-wand-figure-set-of-3-the-quirky-quest",
  },
  {
    name: "Heart Dreamcatcher With L ...",
    price: "Rs. Rs.799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/8_b756c546-002a-4656-828d-cc1b1629cccf_480x480.png?v=1628841860",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/heart-dreamcatcher-with-led-lights",
  },
  {
    name: "Friends Tv Series Design  ...",
    price: "Rs. 399",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/frend-GiftWrap_1_480x480.jpg?v=1605460828",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-design-gift-wrap-paper-sheet",
  },
  {
    name: "Friends - Tv Series - Umb ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/51677VpdJ4L_480x480.jpg?v=1571785391",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-umbrella-table-clocks",
  },
  {
    name: "Friends Tv Series - On Th ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/71LfVfpxb3L._SL1500_480x480.jpg?v=1653472494",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/mcsidrazz-friends-poster-tv-series-friends-on-the-couch-friends-tv-series-poster-12x18",
  },
  {
    name: "Friends Tv Series Infogra ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81riI3ie4SL._UL1500_480x480.jpg?v=1606727813",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-infographic-design-large-canvas-handbags-for-women-tote-bag-for-grocery-shopping-travel-beach-shoulder-bags-for-women",
  },
  {
    name: "Suits Tv Series - Play Th ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/712CFKH1yvL._SL1500_3c3809a6-6a30-4830-b181-96df25cd580c_480x480.jpg?v=1571785392",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/suits-tv-series-play-the-man-table-clocks",
  },
  {
    name: "Cute Couple Love Musical  ...",
    price: "Rs. Rs.649.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_1d47c120-7775-45bd-b975-aa4adc9ecd3e_480x480.png?v=1642856927",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-couple-musical-night-lamp-valentines-special",
  },
  {
    name: "Llama Combo (Mug + Diary) ...",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_585dc9b6-faed-467a-9754-63b0c3b21dd4_480x480.png?v=1624472710",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/llama-combo-mug-diary",
  },
  {
    name: "Little Cloud Touch Sensor ...",
    price: "Rs. Rs.1,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_b000570f-5019-4aa1-b12c-88b296bbaf87_480x480.png?v=1653299287",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/little-cloud-touch-sensor-lamp",
  },
  {
    name: "Harry Potter Hd Latex Bal ...",
    price: "Rs. 799",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81TIBz8G2PL._SL1500_8187f698-b594-441b-8848-8d684fb21c86_480x480.jpg?v=1599165353",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-birthday-party-theme-party-latex-hd-balloons-for-decoration-set-of-20",
  },
  {
    name: "Beer Glass Double Walled  ...",
    price: "Rs. Rs.1,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_d3e7bd63-1aa8-4d17-be31-fd48d906059d_480x480.png?v=1631886375",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/beer-glass-double-walled-hopeside-down-classic-design",
  },
  {
    name: "Iron Man Solar Powered Bo ...",
    price: "Rs. Rs.699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_ab59cd88-06af-4d41-a380-12ae9446984e_480x480.png?v=1658312663",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/iron-man-solar-powered-bobblehead-blue-limited-edition-the-quirky-quest",
  },
  {
    name: "Digital Emoji Alarm Clock ...",
    price: "Rs. Rs.2,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_9251d4ac-3f53-44b7-b07f-8a87f32b6f12_480x480.png?v=1654413792",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/emoji-clock-lamp",
  },
  {
    name: "Bubble Ball Decorative St ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_1115f73b-cbc1-4ec6-883a-c7a389acaa46_480x480.png?v=1634632667",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/bubble-ball-decorative-string-lights-festive-special",
  },
  {
    name: "Harry Potter Hogwarts Acc ...",
    price: "Rs. 1,299",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/91AVqjwaHOL._SL1500_480x480.jpg?v=1571785501",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/copy-of-harry-potter-hogwarts-acceptance-letter-and-marauders-map-brown-scaled-size",
  },
  {
    name: "Diamond Shaped Whiskey Gl ...",
    price: "Rs. Rs.699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/4_a691ee97-9a90-403f-ae72-db7e015f9da1_480x480.png?v=1631889147",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/diamond-shaped-whiskey-glasses-angular-glasses",
  },
  {
    name: "Friends - Tv Series -Joey ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81qEobV34nL._SL1500_480x480.jpg?v=1571785390",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-joey-doesnt-share-food-table-clock",
  },
  {
    name: "Superhero Keychains (Set  ...",
    price: "Rs. Rs.1,399.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_a209ab5b-f811-4578-8047-fbfa8578cf88_480x480.jpg?v=1659378743",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/avengers-keychains-set-of-5-superhero-keychains-the-quirky-quest",
  },
  {
    name: "Christmas Mug With Santa  ...",
    price: "Rs. Rs.849.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_304cf916-83fb-44ad-8e44-a1c81194aeef_480x480.png?v=1636812980",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/christmas-mug-with-santa-lid",
  },
  {
    name: "Charmander Pokemon Action ...",
    price: "Rs. Rs.1,299.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/21_8ed786d4-bca4-416a-a6c8-29a91b6be2eb_480x480.png?v=1651585918",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/charmander-pokemon-action-figure-15cm-the-quirky-quest",
  },
  {
    name: "3D Moving Sand Art Dã‰Cor ...",
    price: "Rs. Rs.1,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/5_6a971850-eebf-47ab-ac9e-ef9afd2aa28c_480x480.jpg?v=1667641536",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/3d-moving-sand-art-decor-the-quirky-quest",
  },
  {
    name: "Looney Tunes Keychain + S ...",
    price: "Rs. Rs.299.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_925fae6b-c526-46d2-9af4-13ad0cd9a778_480x480.png?v=1654510659",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/looney-tunes-keychain-strap-bagcharm-the-quirky-quest",
  },
  {
    name: "Friends On The Couch Wall ...",
    price: "Rs. 999",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/71KCqgYZ0lL._SL1500_480x480.jpg?v=1601939400",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-on-the-couch-wall-clock",
  },
  {
    name: "Harry Potter Pouch (For P ...",
    price: "Rs. Rs.699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_4e839361-573b-4cfd-86e1-212f9aac5253_480x480.png?v=1652867008",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/harry-potter-pouch-for-potterheads-1",
  },
  {
    name: "Unicorn Night Lamp- Sweet ...",
    price: "Rs. Rs.799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/gift-shop-cute-sleeping-unicorn-resin-lamps-blue-shop-for-cute-unicorn-lamps-for-kids-online-zesta-gift-shop-15864201216085_1024x-500x500_480x480.jpg?v=1621451063",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/unicorn-sleeping-night-lamp",
  },
  {
    name: "Cute Couple Night Lamp",
    price: "Rs. Rs.949.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_62be253e-7ac5-4d38-844d-ea17db6c660b_480x480.png?v=1629311121",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-couple-night-lamp-valentines-special",
  },
  {
    name: "Harry Potter Marauder'S M ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/91zK6qKlP3L._SL1500_480x480.jpg?v=1571785501",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/copy-of-mc-sid-razz-hogwarts-acceptance-letter-officially-licenced-by-warner-bros-usa-brown",
  },
  {
    name: "Harry Potter - You Are 9/ ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81UB5XzbJDL._SL1500_480x480.jpg?v=1571785390",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-you-are-9-3-by-4-table-clock",
  },
  {
    name: "Kpop Bts Tiny Tan Figures ...",
    price: "Rs. Rs.1,199.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_5c788009-6098-4247-b9d1-73a4e123f7ae_480x480.jpg?v=1660043863",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/kpop-bts-tiny-tan-figures-set-of-7-the-quirky-quest",
  },
  {
    name: "Astronaut Night Lamp - Th ...",
    price: "Rs. Rs.949.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_0275378a-2bf0-4af7-8b2b-5af8bb374193_480x480.png?v=1653488695",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/astronaut-night-lamp-the-quirky-quest",
  },
  {
    name: "Harry Potter Mug - Magica ...",
    price: "Rs. Rs.799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_f77baa0d-8f4a-4ea0-b596-9e75bf7058a0_480x480.png?v=1615296008",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/harry-potter-magical-mug",
  },
  {
    name: "Harry Potter Combo Pack O ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/41Iw3x5fmGS_480x480.jpg?v=1630570581",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-combo-pack-of-3-binded-notebooks-officially-licensed-merchandise",
  },
  {
    name: "Friends Tv Series Wooden  ...",
    price: "Rs. 399",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/819oltbHtrL._SL1500_4c5beed8-943c-42b7-a24d-802d0e07eaba_480x480.jpg?v=1601941859",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-infographic-doodle-orange-quotes-wooden-coaster-pack-of-4-for-tea-coffee-mug-barware-dining-table-accessories",
  },
  {
    name: "Harry Potter With Broom F ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_8879cd61-da61-41fc-99fb-61c83499a108_480x480.png?v=1654600071",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/harry-potter-with-broom-figures-set-of-3-the-quirky-quest",
  },
  {
    name: "Avengers Keychains - Set  ...",
    price: "Rs. Rs.949.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_cee0340b-8dfb-491f-9da6-a78f16c4c8db_480x480.png?v=1656421674",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/avengers-keychains-superhero-keychains-the-quirky-quest",
  },
  {
    name: "Anime - Itachi Uchiha Sac ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/815vyd8gVzL._AC_SL1500_98da8ff8-428f-4715-9ade-8c91e76573ba_480x480.jpg?v=1619000408",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/anime-itachi-uchiha-sacrifice-poster-1",
  },
  {
    name: "Panda Table Lamp + Pen Ho ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/PandaTableLamp_3_480x480.jpg?v=1620407771",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/panda-table-lamp-pen-holder",
  },
  {
    name: "Bonsai Lighted Tree Table ...",
    price: "Rs. Rs.1,399.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/30_480x480.png?v=1615295936",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/bonsai-lighted-tree-table-top-led-shimmer-tree",
  },
  {
    name: "Unicorn Touch Silicone La ...",
    price: "Rs. Rs.1,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/UnicornTouchSiliconeLamp_4_480x480.png?v=1615295914",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/unicorn-touch-silicone-lamp",
  },
  {
    name: "Bullet Shot Glasses",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_a22b3e63-7a83-46f2-9f70-8086f805e8ee_480x480.png?v=1631891971",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/bullet-shot-glasses",
  },
  {
    name: "Friends Central Perk - Co ...",
    price: "Rs. 399",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81AxZkYk3vL._SL1500_480x480.jpg?v=1646045700",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-central-perk-mug",
  },
  {
    name: "Romantic Couple | Musical ...",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/3_d076104f-1d53-48e4-9c9e-3799e785c8d1_480x480.png?v=1641485020",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/romantic-couple-musical-snow-dome-with-lights-valentines-special",
  },
  {
    name: "Romantic Lovers Under Lam ...",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/A2-12-600x600_480x480.jpg?v=1628172339",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/romantic-lovers-under-lamp-post-musical-snow-dome",
  },
  {
    name: "Friends Tv Series Combo S ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/91UOqlKYjJL._SL1500_480x480.jpg?v=1571785463",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-combo-pack-of-2-infographic-notebook-and-magnetic-bookmarks",
  },
  {
    name: "Sea Lion Touch Sensor Lam ...",
    price: "Rs. Rs.1,399.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_cd8286e1-dc04-4e0d-a94f-f26d33dad4f4_480x480.png?v=1629559805",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/sea-lion-touch-sensor-lamp-multi-colour",
  },
  {
    name: "Marvel Iron Man End Game  ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81a948knL9L._SL1500_046ab0b9-4875-41a6-86ad-11e36e25fc37_480x480.jpg?v=1571785391",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/marvel-iron-man-end-game-table-clock",
  },
  {
    name: "Friends Infographic Wall  ...",
    price: "Rs. 999",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/71pAdgTgvdL._SL1500_480x480.jpg?v=1601939393",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-infographic-wall-clock",
  },
  {
    name: "Harry Potter - Infographi ...",
    price: "Rs. 999",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/819u5-MTTsL._SL1500_480x480.jpg?v=1627970066",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-grey-wall-clock-new-officially-licensed-merchandise",
  },
  {
    name: "3D Unicorn Colorful Dream ...",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/tinyminy.mo_20210127_150524_2_480x480.jpg?v=1615295926",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/3d-unicorn-colorful-dream-catcher",
  },
  {
    name: "Harry Potter - Dumbledore ...",
    price: "Rs. 1,299",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/71Fd6W8IX6L_1_480x480.jpg?v=1653465515",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-magic-wand-harry-potter-merchandise",
  },
  {
    name: "Friends Tv Series Infogra ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/91_2B6rGMofDL._SL1500_daee605f-4293-4b50-ba7e-f694bacb668c_480x480.jpg?v=1571785393",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-infographic-table-clock",
  },
  {
    name: "Harry Potter Figures (Set ...",
    price: "Rs. Rs.1,199.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/6_b94e8736-92e7-4919-bde3-d387a7c6f3c7_480x480.png?v=1654521301",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/harry-potter-figures-set-of-5-the-quirky-quest",
  },
  {
    name: "Cute Panda Mirrors Round  ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/5_1334acbf-d863-4c5b-84e0-ce1b07125db3_480x480.png?v=1615295983",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/copy-of-cute-unicorn-mirrors-round-shaped-glitter-double-sided-for-women",
  },
  {
    name: "Superhero Avengers Keycha ...",
    price: "Rs. Rs.1,499.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_86c4f30c-762b-4eab-9c8e-d0350c3550f3_480x480.jpg?v=1659628170",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/superhero-avengers-keychains-set-of-6-the-quirky-quest",
  },
  {
    name: "Iron Man Classic Red & Go ...",
    price: "Rs. Rs.699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/2_c68260b8-db19-44f2-89f5-426c80dc4120_480x480.png?v=1615296016",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/iron-man-classic-red-gold-solar-powered-bobblehead",
  },
  {
    name: "Double Ring Auto Rotating ...",
    price: "Rs. Rs.999.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_4be784b0-65b7-42c7-930e-531ecc38a62f_480x480.png?v=1633705519",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/double-ring-auto-rotating-car-air-freshener",
  },
  {
    name: "Cow Touch Lamp - 7 Colour ...",
    price: "Rs. Rs.1,699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_eed29fb7-d738-4e57-ba3e-3ba6810d67ce_480x480.png?v=1652377922",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cow-touch-lamp-7-colours-the-quirky-quest",
  },
  {
    name: "Friends Tv Series Pack Of ...",
    price: "Rs. 399",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81qne2I72WL._SL1500_480x480.jpg?v=1571785467",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-combo-pack-of-4-rectangular-fridge-magnet-straw-umbrella-door-infographic",
  },
  {
    name: "Astronaut Plant Lamp - Th ...",
    price: "Rs. Rs.949.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_d56e4fb4-1400-477a-b724-a67a31ca1722_480x480.png?v=1651562823",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/astronaut-plant-lamp-the-quirky-quest",
  },
  {
    name: "Cute Owl Night Lamp With  ...",
    price: "Rs. Rs.1,799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_9bcd2d33-3bdd-4620-96d0-079b0f5fff2a_480x480.png?v=1657447794",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-owl-night-lamp-with-7-colours-the-quirky-quest",
  },
  {
    name: "Cute Bedside Toast Night  ...",
    price: "Rs. Rs.1,399.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/7_e3849b58-a3de-40c6-9bf6-b5c57fefefd2_480x480.jpg?v=1661162505",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-bedside-toast-night-lamp-the-quirky-quest",
  },
  {
    name: "Unicorn Dreamcatcher With ...",
    price: "Rs. Rs.699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/11_800x_e3f253ac-4b7e-4fc8-a926-1975ad1c0e55_480x480.jpg?v=1620407773",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/unicorn-dreamcatcher-with-lights-and-beads",
  },
  {
    name: "Harry Potter - I Solemnly ...",
    price: "Rs. 599",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/61E2cE6hfNS._SL1280__1_480x480.jpg?v=1639549095",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-i-solemnly-swear-wooden-music-box",
  },
  {
    name: "Harry Potter With Hedwig  ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/HarryPotterWithHedwigQPosket_480x480.png?v=1658320854",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/harry-potter-with-hedwig-figure-the-quirky-quest",
  },
  {
    name: "Hello Kitty Bobblehead -  ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_6ffd3db3-6ccb-4af1-8b28-2e1c98c7db3f_480x480.png?v=1626287100",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/hello-kitty-bobblehead-the-quirky-quest",
  },
  {
    name: "Cute Dog Touch Lamp - The ...",
    price: "Rs. Rs.1,699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_98048660-a034-4592-be9f-baebbe3dde8d_480x480.png?v=1657734143",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-doggy-touch-lamp-the-quirky-quest",
  },
  {
    name: "Unicorn Floating Bubbles  ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/2_35b2f9f2-c0c9-4527-b97a-5fdd48c2dbb1_480x480.png?v=1615295942",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/unicorn-floating-bubbles-sipper",
  },
  {
    name: "Friends Tv Series Combo S ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/910sBQO7hML._SL1500_480x480.jpg?v=1571785462",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-combo-pack-of-2-quotes-notebook-and-magnetic-bookmarks",
  },
  {
    name: "Friends Coffee Mug (Infog ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_232bce7e-8c6c-490e-9265-4551738aaf71_480x480.png?v=1635609070",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/friends-coffee-mug-pop-art-mug-the-quirky-quest",
  },
  {
    name: "Harry Potter House Crest  ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/41BOHDoBK-L_f3974924-37ab-4f47-ba6a-c3e9bd330a17_480x480.jpg?v=1571785526",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-house-crest-3-heat-sensitive-mugs-cool-coffee-tea-cup-drinkware-ceramic-mugs-officially-licensed-merchandise",
  },
  {
    name: "Personalised Pen And Keyc ...",
    price: "Rs. Rs.649.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_63afa515-3ea3-4046-ac01-f130214ccb4e_480x480.jpg?v=1664189988",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/personalised-pen-and-keychain-set-of-2-no-cod-the-quirky-quest",
  },
  {
    name: "Astronaut Spaceman Figuri ...",
    price: "Rs. Rs.799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_2161c20c-3284-4b94-98f0-effbcbf337ba_480x480.png?v=1628531643",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/astronaut-spaceman-figurine-moon-night-lamp-multi-colour",
  },
  {
    name: "Plant Table Lamp",
    price: "Rs. Rs.1,799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/9_87a5e7b5-65c4-4d0f-bf26-52c693efd5fb_480x480.png?v=1644394065",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/plant-table-lamp",
  },
  {
    name: "Friends Tv Series Central ...",
    price: "Rs. 699",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/A14_MWHqc_L._SL1500_825783b9-24b0-484b-89be-af0615220c3d_480x480.jpg?v=1599508309",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-central-perk-decorative-satin-cushion-covers-16x16-inch",
  },
  {
    name: "Star Touch Silicone Lamp  ...",
    price: "Rs. Rs.1,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_9ed61b6d-5f7e-4d7f-97a3-1df92c9b96f1_480x480.png?v=1657449317",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/star-touch-silicone-lamp-the-quirky-quest",
  },
  {
    name: "Harry Potter Combo ( 1 Ho ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/91-hEftNtzL._SL1500_480x480.jpg?v=1571785463",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-house-crest-notebook-and-magnetic-bookmarks-officially-licensed",
  },
  {
    name: "Holographic And Glitter L ...",
    price: "Rs. Rs.799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/IMG_20210123_143132_1024x1024_2x_22ece1a8-d3e1-4581-956a-eb6ce2ba92be_480x480.jpg?v=1615295969",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/holographic-and-glitter-lid-mug",
  },
  {
    name: "Cute Panda Fragrance Lamp ...",
    price: "Rs. Rs.1,299.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/Cute-Panda-Fragrance-Lamp-Creative-Mini-Essential-Oil-Fragrance-Night-Light-Holiday-Gift-Bedroom-Decor-USB.jpg_q50_480x480.jpg?v=1620407757",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-panda-fragrance-lamp",
  },
  {
    name: "Game Of Thrones All House ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81lC8CU48pL._SL1500_adab1305-879c-4397-aec8-f306bd57b88a_480x480.jpg?v=1571785391",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/game-of-thrones-all-houses-table-clock",
  },
  {
    name: "Saturn Lamp Humidifier",
    price: "Rs. Rs.1,199.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/LampHumidifie.pngfafafa_480x480.png?v=1627985866",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/saturn-lamp-humidifier",
  },
  {
    name: "Cute Puppy Touch Lamp (Mu ...",
    price: "Rs. Rs.1,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_929e6598-c668-40c1-a2d2-f91e9d30deb2_480x480.png?v=1629719047",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-puppy-touch-lamp-multi-color",
  },
  {
    name: "Pikachu Lamp- The Quirky  ...",
    price: "Rs. Rs.549.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/7_263285a3-d1aa-4146-8caf-bac5e921af7e_480x480.png?v=1656500126",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/pikachu-lamp-the-quirky-quest",
  },
  {
    name: "Friends - Quotes Heat Sen ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/718pcw3B6ZL._SL1500_2c467f02-36e9-4ff6-a27f-45f17c56bad3_480x480.jpg?v=1601940095",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-infographic-sofa-morphing-magic-heat-sensitive-mugs-cool-coffee-tea-cup-drinkware-ceramic-mugs-officially-licensed",
  },
  {
    name: "Cute Cartoon Face Led Tab ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/ElfTableLamp_1_1_480x480.jpg?v=1628175880",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-cartoon-face-led-table-lamp",
  },
  {
    name: "Hedwig Owl Mug - Harry Po ...",
    price: "Rs. Rs.799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_bec1c072-6efd-4af4-81e4-785b5564ccad_480x480.png?v=1651663460",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/hedwig-owl-mug-harry-potter-mugs-the-quirky-quest",
  },
  {
    name: "Beach Themed Mug With Sau ...",
    price: "Rs. Rs.949.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/BeachMugwithSaucer_2_480x480.png?v=1628101461",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/beach-themed-mug-with-saucer-the-quirky-quest",
  },
  {
    name: "Harry Potter Hand Cranked ...",
    price: "Rs. 799",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/51yzne93u7L_480x480.jpg?v=1601942987",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/wooden-hedwig-theme-harry-potter-hand-cranked-collectable-engraved-music-box-for-harry-potter-lover",
  },
  {
    name: "Over The Moon Â€“ Unicorn ...",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/lamp7-min_1_480x480.jpg?v=1620407751",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/over-the-moon-unicorn-led-night-lamp",
  },
  {
    name: "Naruto Keychains With Bag ...",
    price: "Rs. Rs.1,499.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_b117ebc0-eec0-440e-be79-a96dbefffc46_480x480.jpg?v=1666436360",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/naruto-keychains-with-bagcharm-and-strap-set-of-5-the-quirky-quest",
  },
  {
    name: "Snoring Cat Silicon Night ...",
    price: "Rs. Rs.1,999.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_9b327ac3-d96a-43c1-9c24-eaab9be410fd_480x480.png?v=1653301554",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/snoring-cat-silicon-night-lamp-with-7-colour-modes",
  },
  {
    name: "Miss You Musical Snow Dom ...",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/Product115C_1800x1800_1_480x480.jpg?v=1628706322",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/miss-you-musical-crystal-snow-dome-gift-for-young-couple",
  },
  {
    name: "Superhero Keychains",
    price: "Rs. Rs.1,599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_9a8ece62-a9e9-4e03-9784-2169bba75a62_480x480.png?v=1656422146",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/avengers-superhero-keychains-the-quirky-quest",
  },
  {
    name: "3D Panda Mug With Lid And ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/three-dimensional-cartoon-panda-color-glaze_1024x1024_2x_98db509f-2d7b-47d8-847d-ddf469745d62_480x480.jpg?v=1634824130",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/3d-panda-mug-with-lid-and-spoon",
  },
  {
    name: "Harry Potter Favourite Mu ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81koD-kUE5L._SL1500_26c67413-bc1b-4587-a099-e28dbd2fe6c7_480x480.jpg?v=1571785525",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-favourite-muggle-table-clock-gift-set-birthday-gift-valentine-day-gift",
  },
  {
    name: "Christmas Tree Decorative ...",
    price: "Rs. Rs.1,199.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_7c7f4400-0df8-48b5-a3d1-b9dc07e7f59d_480x480.png?v=1636811461",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/christmas-tree-decorative-lamp",
  },
  {
    name: "Led Dreamcatcher With Bea ...",
    price: "Rs. Rs.599.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/3_b5d7fa5b-3e04-4b69-ad69-2ba9d04ff86c_480x480.png?v=1615295925",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/led-dreamcatcher-with-beads-and-feathers",
  },
  {
    name: "Cute Unicorn Touch Silico ...",
    price: "Rs. Rs.1,999.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_fdcd0f80-8c77-4b0c-9609-1b02897c7bc3_480x480.png?v=1653314089",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/unicorn-touch-silicone-lamp-remote-controlled-the-quirky-quest",
  },
  {
    name: "Romantic Couple | Musical ...",
    price: "Rs. Rs.899.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/kis-masali-isikli-muzikli-kar-kuresi-buyuk-boy-mavi-kc5563253-1-11574273613e465282c9e6d1b9a9fd54_480x480.jpg?v=1624472695",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/romantic-couple-musical-snow-dome",
  },
  {
    name: "Harry Potter - Magic Wand ...",
    price: "Rs. 1,299",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/71aJdRgm2kL._SL1500_480x480.jpg?v=1653465262",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-magic-wand-1-harry-potter-merchandise-india",
  },
  {
    name: "Friends Tv Series Infogra ...",
    price: "Rs. 399",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/81298sCyzEL._SL1500_791103d4-22d4-4bac-8e5d-702053b4ecf3_480x480.jpg?v=1599164636",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-infographic-soft-sleep-eye-mask-with-ear-plugs-for-women-and-men",
  },
  {
    name: "Friends Tv Series Infogra ...",
    price: "Rs. 399",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/51ENbGPEkVL._SL1000_9778ab21-0a1a-4f7f-9625-b3e984769b29_480x480.jpg?v=1599508313",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-infographic-decorative-satin-cushion-covers-16x16-inch",
  },
  {
    name: "Cute Bunny Silicone Touch ...",
    price: "Rs. Rs.1,799.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/1_0b99cfe4-86f6-44f3-83a9-20da1a79455f_480x480.png?v=1657446765",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/cute-bunny-silicone-touch-lamp-remote-controlled-the-quirky-quest",
  },
  {
    name: "The Batman - Official Mov ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/WhatsAppImage2021-10-26at3.37.30PM_2_480x480.jpg?v=1653469725",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/the-batman-official-movie-poster-officially-licensed-merchandise",
  },
  {
    name: "Scratch World Map - Delux ...",
    price: "Rs. Rs.949.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/01_quec-yv_480x480.jpg?v=1627496632",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/scratch-world-map-deluxe-edition",
  },
  {
    name: "Harry Potter - Cartoon Ch ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/818xJsdk8aL._SL1500_359959f0-034e-4010-9b74-7506bb67b31c_480x480.jpg?v=1571785393",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/harry-potter-cartoon-character-table-clocks",
  },
  {
    name: "Starbucks Coffee Keychain ...",
    price: "Rs. Rs.249.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/2_8398162c-d632-469d-a3a4-a95e6956f1be_480x480.png?v=1657726084",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/starbucks-coffee-keychain-bag-charm-strap-the-quirky-quest",
  },
  {
    name: "Multi-Colored Led Dreamca ...",
    price: "Rs. Rs.699.00",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/0520/4747/8937/products/3_521951b4-5a0e-4ffc-ad35-8a0ce0ffae30_480x480.png?v=1639582487",
    ],
    productUrl:
      "https://thequirkyquest.com//collections/best-selling-collection/products/multi-colored-led-dreamcatcher",
  },
  {
    name: "Friends Tv Series Combo S ...",
    price: "Rs. 499",
    stars: "1",
    reviews: " 1 review ",
    imageUrls: [
      "https://cdn.shopify.com/s/files/1/2491/1072/products/91RSv4bWjzL._SL1500_480x480.jpg?v=1571785462",
    ],
    productUrl:
      "https://www.epicstuff.com//collections/best-selling-products/products/friends-tv-series-combo-pack-of-2-infographic-daily-planner-notebook-and-magnetic-bookmarks",
  },
];

export default productsArray;
