import { config } from "../shared/constants";
import { fetchWrapper } from "../helpers";

const baseUrl = `${config.apiUrl}/api/wishlists`;

export const wishlistService = {
  getWishlistsByUserId,
  getWishlistByWishlistId,
  addToWishlist,
  updateWishlist,
  deleteWishlist,
};

function deleteWishlist(wishlistId: string) {
  return fetchWrapper.delete(`${baseUrl}/${wishlistId}`).then((x) => {
    return x;
  });
}

function updateWishlist(wishlistId: string, body: any) {
  return fetchWrapper.put(`${baseUrl}/update/${wishlistId}`, body).then((x) => {
    return x;
  });
}

function getWishlistsByUserId(userId: string) {
  return fetchWrapper.get(`${baseUrl}/get-by-userid/${userId}`).then((x) => {
    return x;
  });
}

function getWishlistByWishlistId(wishlistId: string) {
  return fetchWrapper
    .get(`${baseUrl}/get-by-wishlistid/${wishlistId}`)
    .then((x) => {
      return x;
    });
}

function addToWishlist(body: any) {
  return fetchWrapper.post(`${baseUrl}/add`, body).then((x) => {
    return x;
  });
}
