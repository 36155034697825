import {
  Add,
  FavoriteBorder,
  FavoriteBorderOutlined,
  MoreVert,
  SwapHoriz,
} from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SignUpToContinue from "../../modules/SignUpToContinue";
import { accountService } from "../../services";
import { exchangeService } from "../../services/exchange.service";
import { ExchangeModel } from "../../shared/models/ExchangeModel";
import * as yup from "yup";
import AlertDialog from "../../modules/AlertDialog";
import { wishlistService } from "../../services/wishlist.service";

const validationSchema = yup.object({
  budget: yup.number().nullable(),
  message: yup.string(),
  organiser_participating: yup.boolean().required("This field is required"),
});

export default function UpdateExchange() {
  // Create new Date instance
  var tomorrowDate = new Date();

  // Add a day
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  const [alertOpen, setAlertOpen] = useState(false);

  const [leaveAlertOpen, setLeaveAlertOpen] = useState(false);

  let { exchangeId } = useParams<{ exchangeId: string }>();

  const [openLoader, setOpenLoader] = React.useState(false);

  const history = useHistory();

  const [exchange, setExchange] = useState<ExchangeModel>();

  const [signUpDialogOpen, setSignUpDialogOpen] = useState(false);

  const [exchangeDateComing, setExchangeDateComing] = useState(true);
  const [drawDateComing, setDrawDateComing] = useState(true);

  const [isOrganiser, setIsOrganiser] = useState(false);
  const [isMember, setIsMember] = useState(true);

  useEffect(() => {
    if (exchange) {
      setExchangeDateComing(
        new Date(new Date(exchange.exchange_date).setHours(0, 0, 0, 0)) >
          new Date()
      );
      setDrawDateComing(
        new Date(new Date(exchange.draw_date).setHours(21, 30, 0, 0)) >
          new Date()
      );
      setIsOrganiser(
        accountService.userValue &&
          exchange.organiser &&
          exchange.organiser._id === accountService.userValue.id
      );
      setIsMember(
        accountService.userValue &&
          exchange.members.some(
            (member) => member._id === accountService.userValue.id
          )
      );
    }
  }, [exchange]);

  const formik = useFormik({
    initialValues: {
      draw_date: exchange ? exchange.draw_date.toString().split("T")[0] : "",
      exchange_date: exchange
        ? exchange.exchange_date.toString().split("T")[0]
        : "",
      budget: exchange ? exchange.budget : "",
      message: exchange ? exchange.message : "",
      organiser_participating: exchange ? exchange.organiser_participating : "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setOpenLoader(true);
      exchangeService.updateExchange(exchangeId, values).then((res) => {
        setExchange(res);
        setOpenLoader(false);
        alert("Event details updated");
      });
    },
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setOpenLoader(true);
    exchangeService.getExchangeByExchangeId(exchangeId).then((res) => {
      setExchange(res);
      setOpenLoader(false);
    });
  }, [exchangeId]);

  async function deleteExchange() {
    await exchangeService.deleteExchange(exchangeId);
    window.location.href = "/exchanges";
    setTimeout(function () {
      alert("Your event has been deleted");
    }, 100);
  }

  const initWishlist = async () => {
    const res = await wishlistService.addToWishlist({
      name: "My Wishlist",
      status: "draft",
      exchange: exchangeId,
    });

    return res._id;
  };

  return (
    <Grid
      sx={{ p: 2, mb: 3 }}
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {exchange && (
        <>
          {((!isOrganiser && !isMember) || !accountService.userValue) &&
            drawDateComing && (
              <Paper
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 2,
                  mb: 3,
                  p: 2,
                  overflow: "hidden",
                  background: "#FFFBFE",
                  border: "1px solid #FF5757",
                  borderRadius: "15px",
                }}
              >
                <Typography
                  sx={{
                    mt: 1,
                    fontFamily: "Neuton",
                    fontStyle: "Normal",
                    fontWeight: "300",
                    fontSize: "20px",
                    lineHeight: "20px",
                    textTransform: "none",
                    letterSpacing: "0.5px",
                  }}
                  variant="h6"
                >
                  Join the Fun!
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    fontFamily: "Neuton",
                    fontStyle: "Normal",
                    fontWeight: "300",
                    fontSize: "16px",
                    lineHeight: "16px",
                    textTransform: "none",
                    letterSpacing: "0.5px",
                  }}
                >
                  Please confirm if you want to join the Exchange
                </Typography>
                <Grid
                  sx={{ mt: 2, mb: 1 }}
                  container
                  spacing={1}
                  justifyContent="space-around"
                >
                  <Grid item xs={6}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      style={{
                        height: "48px",
                        width: "150px",
                        borderRadius: "11px",
                        border: "1px solid #FF5757",
                        color: "#FF5757",
                        fontFamily: "Neuton",
                        fontStyle: "Normal",
                        fontWeight: "300",
                        fontSize: "16px",
                        lineHeight: "16px",
                        textTransform: "none",
                      }}
                      onClick={async () => {
                        if (accountService.userValue) {
                          await exchangeService.rejectInvite(exchangeId);
                          window.location.reload();
                        } else setSignUpDialogOpen(true);
                      }}
                    >
                      No, Next time!
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{
                        height: "48px",
                        width: "150px",
                        borderRadius: "11px",
                        border: "1px solid #FF5757",
                        color: "#FFFFFF",
                        backgroundColor: "#FF5757",
                        fontFamily: "Neuton",
                        fontStyle: "Normal",
                        fontWeight: "300",
                        fontSize: "16px",
                        lineHeight: "16px",
                        textTransform: "none",
                      }}
                      onClick={async () => {
                        if (accountService.userValue) {
                          setOpenLoader(true);
                          await exchangeService.acceptInvite(exchangeId);
                          setOpenLoader(false);
                          window.location.reload();
                        } else
                          history.push("/landing", { exchangeId: exchangeId });
                      }}
                    >
                      Yes, Why not!
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
          <Paper
            // variant="outlined"
            elevation={8}
            sx={{
              borderRadius: 3,
              mt: -1,
              overflow: "hidden",
              background: "#FFFFFF",
              border: "1px solid #FF5757",
              mb: 5,
            }}
          >
            <Grid sx={{ p: 2 }} container justifyContent="space-between">
              <Grid item>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "20px",
                    color: "#000000",
                  }}
                >
                  {exchange.type} - {exchange.name}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "16px",
                    color: "#000000",
                  }}
                >
                  Hosted by :{" "}
                  {exchange.organiser && exchange.organiser.fullName}
                </Typography>
              </Grid>
              <Grid item>
                {accountService.userValue &&
                  exchange.organiser &&
                  exchange.organiser._id === accountService.userValue.id && (
                    <>
                      {" "}
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => {
                          handleClick(e);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {drawDateComing && (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              history.push("/exchange/members", {
                                data: exchange,
                                noDelete: false,
                              });
                            }}
                            sx={{ fontFamily: "Neuton" }}
                          >
                            Remove Members
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setAlertOpen(true);
                          }}
                          sx={{ fontFamily: "Neuton" }}
                        >
                          Delete Event
                        </MenuItem>
                      </Menu>
                    </>
                  )}
              </Grid>
            </Grid>
            <img
              style={{
                width: "98%",
                marginTop: 10,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                borderRadius: "10px",
              }}
              src="/EventHomePage.png"
              alt="banner"
            ></img>
            <Grid sx={{ p: 2, mt: 2 }} container justifyContent="space-between">
              <Grid item xs={4} container direction="column">
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "#000000",
                  }}
                >
                  Event Date
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "#000000",
                  }}
                >
                  RSVP Deadline
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "#000000",
                  }}
                >
                  Budget
                </Typography>
              </Grid>
              <Grid item xs={1} container direction="column">
                <Typography variant="caption">:</Typography>
                <Typography variant="caption">:</Typography>
                <Typography variant="caption">:</Typography>
              </Grid>
              <Grid item xs={6} container direction="column">
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "#000000",
                  }}
                >
                  {exchange.exchange_date.toString().split("T")[0]}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "#000000",
                    mt: 1,
                  }}
                >
                  {exchange.draw_date.toString().split("T")[0]}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "Neuton",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "#000000",
                    mt: 1,
                  }}
                >
                  {exchange.budget}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="caption"
                sx={{
                  fontFamily: "Neuton",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "12px",
                  lineHeight: "15px",
                  opacity: 0.7,
                  letterSpacing: 0.2,
                }}
              >
                {exchange.message}
              </Typography>
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                if (
                  exchange.organiser &&
                  exchange.organiser._id === accountService.userValue.id &&
                  drawDateComing
                )
                  history.push("/exchange/members", {
                    data: exchange,
                    noDelete: false,
                  });
                else
                  history.push("/exchange/members", {
                    data: exchange,
                    noDelete: true,
                  });
              }}
            >
              <Avatar
                sx={{
                  background: "#000000",
                  height: 15,
                  width: 15,
                  fontSize: 15,
                  p: 1.5,
                }}
              >
                {exchange.members ? exchange.members.length : 0}
              </Avatar>
              &nbsp;
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "Neuton",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "16px",
                  color: "#000000",
                  letterSpacing: "0.5px",
                }}
              >
                Joined
              </Typography>
              &nbsp;
              <Divider sx={{ color: "red" }} orientation="vertical" flexItem />
              &nbsp;
              <Avatar
                sx={{
                  background: "#000000",
                  height: 15,
                  width: 15,
                  fontSize: 15,
                  p: 1.5,
                }}
              >
                {exchange.invitees ? exchange.invitees.length : 0}
              </Avatar>
              &nbsp;
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "Neuton",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "16px",
                  color: "#000000",
                  letterSpacing: "0.5px",
                }}
              >
                Invited
              </Typography>
            </div>
            <Grid sx={{ mt: 1, mb: 1 }} container justifyContent="space-around">
              {isOrganiser && drawDateComing && (
                <Grid item>
                  <Button
                    color="secondary"
                    variant="outlined"
                    style={{
                      height: "48px",
                      width: "160px",
                      borderRadius: "11px",
                      border: "1px solid #FF5757",
                      color: "#000000",
                      textTransform: "none",
                      fontFamily: "Neuton",
                      fontStyle: "Normal",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "16px",
                    }}
                    startIcon={<Add color="inherit" />}
                    onClick={() =>
                      history.push("/exchange/invite/" + exchangeId)
                    }
                  >
                    Invite People
                  </Button>
                </Grid>
              )}
              <Grid item>
                {exchange.wishlist && exchange.wishlist.status !== "draft" ? (
                  <Button
                    variant="outlined"
                    disabled={
                      accountService.userValue &&
                      exchange.members.some(
                        (member) => member._id === accountService.userValue.id
                      )
                        ? false
                        : true
                    }
                    style={{
                      height: "48px",
                      width: "160px",
                      borderRadius: "11px",
                      border: "1px solid #FF5757",
                      color: "#FFFFFF",
                      backgroundColor: "#FF5757",
                      fontFamily: "Neuton",
                      fontStyle: "Normal",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "16px",
                      textTransform: "none",
                    }}
                    startIcon={<FavoriteBorder color="inherit" />}
                    onClick={() =>
                      history.push("/wishlist/view", {
                        data: exchange.wishlist,
                      })
                    }
                  >
                    View Wishlist
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled={
                      accountService.userValue &&
                      exchange.members.some(
                        (member) => member._id === accountService.userValue.id
                      )
                        ? false
                        : true
                    }
                    style={{
                      height: "48px",
                      width: "160px",
                      borderRadius: "11px",
                      border: "1px solid #FF5757",
                      color: "#FFFFFF",
                      backgroundColor: "#FF5757",
                      fontFamily: "Neuton",
                      fontStyle: "Normal",
                      fontWeight: "300",
                      fontSize: "16px",
                      lineHeight: "16px",
                      textTransform: "none",
                    }}
                    startIcon={<FavoriteBorderOutlined color="inherit" />}
                    onClick={async () => {
                      if (exchange.wishlist)
                        history.push("/wishlist/add/" + exchange.wishlist._id, {
                          exchangeId,
                        });
                      else {
                        setOpenLoader(true);
                        const wishlistId = await initWishlist();
                        setOpenLoader(false);
                        history.push("/wishlist/add/" + wishlistId, {
                          exchangeId,
                        });
                      }
                    }}
                  >
                    Create Wishlist
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Paper
            sx={{
              p: 2,
              borderRadius: "15px",
              border: "1px solid black",
              mb: 6,
              width: "100%",
            }}
            elevation={4}
          >
            {(isMember || isOrganiser) && (
              <>
                {drawDateComing && (
                  <>
                    <TextField
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          fontFamily: "Neuton",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                        style: {
                          textAlign: "center",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          fontFamily: "Neuton",
                          color: "#000000",
                          fontSize: "18px",
                        },
                      }}
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: "center",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          fontFamily: "Neuton",
                        },
                      }}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       <SwapHoriz />
                      //     </InputAdornment>
                      //   ),
                      //   style: {
                      //     background: "#FFFFFF",
                      //     color: "red",
                      //     borderRadius: "10px",
                      //   },
                      // }}
                      disabled
                      fullWidth
                      variant="filled"
                      id="draw_names"
                      name="draw_names"
                      label="You will be gifting to:"
                      value={
                        !drawDateComing
                          ? "Names drawn"
                          : "Names will be drawn after RSVP deadline"
                      }
                      sx={{
                        mb: 2,
                        border: "1px solid #000000",
                        borderRadius: "10px",
                        "& .MuiFilledInput-root": {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                    />
                    <TextField
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          fontFamily: "Neuton",
                        },
                      }}
                      variant="filled"
                      InputLabelProps={{
                        shrink: true,
                        style: { fontFamily: "Neuton" },
                      }}
                      select
                      label="Will you be Participating?"
                      value={isMember}
                      disabled={isOrganiser}
                      onChange={async (e) => {
                        if (!e.target.value) {
                          setLeaveAlertOpen(true);
                        }
                      }}
                      fullWidth
                      sx={{
                        mt: 2,
                        mb: 2,
                        border: "1px solid #000000",
                        borderRadius: "10px",
                        "& .MuiFilledInput-root": {
                          background: "#FFFFFF",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem
                        key="secret-santa"
                        value={true as any}
                        sx={{ fontFamily: "Neuton" }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        key="secret-santa"
                        value={false as any}
                        sx={{ fontFamily: "Neuton" }}
                      >
                        No
                      </MenuItem>
                    </TextField>
                  </>
                )}
                {isOrganiser && (
                  <form onSubmit={formik.handleSubmit}>
                    {drawDateComing && (
                      <>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                            style: { fontFamily: "Neuton" },
                          }}
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              fontFamily: "Neuton",
                            },
                            inputProps: {
                              min: tomorrowDate.toISOString().split("T")[0],
                            },
                          }}
                          variant="filled"
                          id="draw-date"
                          name="draw_date"
                          label="RSVP Deadline"
                          type="date"
                          value={formik.values.draw_date}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.draw_date &&
                            Boolean(formik.errors.draw_date)
                          }
                          helperText={
                            formik.touched.draw_date && formik.errors.draw_date
                          }
                          sx={{
                            mt: 2,
                            mb: 2,
                            border: "1px solid #000000",
                            borderRadius: "10px",
                            "& .MuiFilledInput-root": {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                        />
                        <TextField
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              fontFamily: "Neuton",
                            },
                          }}
                          variant="filled"
                          fullWidth
                          id="budget"
                          name="budget"
                          label="Budget"
                          placeholder="0"
                          InputLabelProps={{
                            shrink: true,
                            style: { fontFamily: "Neuton" },
                          }}
                          value={formik.values.budget}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.budget &&
                            Boolean(formik.errors.budget)
                          }
                          helperText={
                            formik.touched.budget && formik.errors.budget
                          }
                          sx={{
                            mt: 2,
                            mb: 2,
                            border: "1px solid #000000",
                            borderRadius: "10px",
                            "& .MuiFilledInput-root": {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                        />
                        <TextField
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              fontFamily: "Neuton",
                            },
                          }}
                          variant="filled"
                          fullWidth
                          id="message"
                          name="message"
                          placeholder="Let's kickstart the fun. Start creating your Wishlist to give your Santa some good ideas."
                          label="Message for Members"
                          value={formik.values.message}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontFamily: "Neuton" },
                          }}
                          multiline
                          rows={2}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.message &&
                            Boolean(formik.errors.message)
                          }
                          helperText={
                            formik.touched.message && formik.errors.message
                          }
                          sx={{
                            mt: 2,
                            mb: 2,
                            border: "1px solid #000000",
                            borderRadius: "10px",
                            "& .MuiFilledInput-root": {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                        />
                      </>
                    )}
                    {exchangeDateComing && (
                      <div style={{ textAlign: "center" }}>
                        <TextField
                          variant="filled"
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              fontFamily: "Neuton",
                            },
                            inputProps: {
                              min: formik.values.draw_date
                                ? formik.values.draw_date
                                : tomorrowDate.toISOString().split("T")[0],
                            },
                          }}
                          id="exchange-date"
                          name="exchange_date"
                          label="Event Date"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                            style: { fontFamily: "Neuton" },
                          }}
                          value={formik.values.exchange_date}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.exchange_date &&
                            Boolean(formik.errors.exchange_date)
                          }
                          helperText={
                            formik.touched.exchange_date &&
                            formik.errors.exchange_date
                          }
                          sx={{
                            mt: 2,
                            mb: 2,
                            border: "1px solid #000000",
                            borderRadius: "10px",
                            "& .MuiFilledInput-root": {
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            },
                          }}
                        />

                        <Button
                          style={{
                            width: "140px",
                            height: "48px",
                            borderRadius: "11px",
                            border: "1px solid #FF5757",
                            color: "#FFFFFF",
                            backgroundColor: "#FF5757",
                            fontFamily: "Neuton",
                            fontStyle: "Normal",
                            fontWeight: "300",
                            fontSize: "20px",
                            lineHeight: "16px",
                            textTransform: "none",
                            letterSpacing: "0.5px",
                          }}
                          sx={{
                            mt: 2,
                            mb: 2,
                            width: "50%",
                          }}
                          color="secondary"
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Update
                        </Button>
                      </div>
                    )}
                  </form>
                )}
                {isMember && !drawDateComing && (
                  <Grid
                    sx={{ mt: 2, mb: 2 }}
                    container
                    justifyContent="space-around"
                  >
                    {exchange.matchedUser && (
                      <Grid xs={6} item>
                        <Box
                          sx={{
                            borderRadius: "15px",
                            m: 0.5,
                            p: 0.5,
                            textAlign: "center",
                            border: "1px solid  #FF5757",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Neuton",
                              fontStyle: "Normal",
                              fontWeight: "300",
                              fontSize: "18px",
                              lineHeight: "22px",
                              textTransform: "none",
                              letterSpacing: "0.5px",
                              color: "#000000",
                            }}
                          >
                            You’re Gifting to :<br></br>
                            {exchange.matchedUser.fullName.substring(0, 10) +
                              "..."}
                          </Typography>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            sx={{
                              m: 1,
                            }}
                            style={{
                              borderRadius: "11px",
                              border: "1px solid #FF5757",
                              color: "#FFFFFF",
                              backgroundColor: "#FF5757",
                              fontFamily: "Neuton",
                              fontStyle: "Normal",
                              fontWeight: "300",
                              fontSize: "18px",
                              lineHeight: "18px",
                              textTransform: "none",
                              letterSpacing: "0.5px",
                            }}
                            onClick={() => {
                              history.push("/exchange/user-profile", {
                                user: exchange.matchedUser,
                                exchangeId: exchange._id,
                              });
                            }}
                          >
                            View Details
                          </Button>
                        </Box>
                      </Grid>
                    )}
                    <Grid xs={6} item>
                      <Box
                        sx={{
                          borderRadius: "15px",
                          m: 0.5,
                          p: 0.5,
                          textAlign: "center",
                          border: "1px solid  #FF5757",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Neuton",
                            fontStyle: "Normal",
                            fontWeight: "300",
                            fontSize: "18px",
                            lineHeight: "20px",
                            textTransform: "none",
                            letterSpacing: "0.5px",
                            color: "#000000",
                          }}
                        >
                          Help your Santa !
                        </Typography>
                        <br></br>
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                          sx={{
                            m: 1,
                          }}
                          style={{
                            borderRadius: "11px",
                            border: "1px solid #FF5757",
                            color: "#FFFFFF",
                            backgroundColor: "#FF5757",
                            fontFamily: "Neuton",
                            fontStyle: "Normal",
                            fontWeight: "300",
                            fontSize: "18px",
                            lineHeight: "18px",
                            textTransform: "none",
                            letterSpacing: "0.5px",
                          }}
                          onClick={() =>
                            history.push("/exchange/user-profile", {
                              user: {
                                ...accountService.userValue,
                                matchedWishlist: exchange.wishlist,
                              },
                              secretSanta: exchange.secretSanta,
                              exchangeId: exchange._id,
                              exchangeDateComing: exchangeDateComing,
                            })
                          }
                        >
                          Add Details
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Paper>
        </>
      )}
      <SignUpToContinue open={signUpDialogOpen} setOpen={setSignUpDialogOpen} />
      <AlertDialog
        title="Delete Event"
        body="Are you sure you want to delete this event?"
        open={alertOpen}
        setOpen={setAlertOpen}
        agreeFunc={() => deleteExchange()}
        disagreeFunc={() => {
          setAlertOpen(false);
        }}
      />
      <AlertDialog
        title="Leave Event"
        body="Are you sure you want to leave this event?"
        open={leaveAlertOpen}
        setOpen={setLeaveAlertOpen}
        agreeFunc={async () => {
          await exchangeService.rejectInvite(exchangeId);
          window.location.reload();
        }}
        disagreeFunc={() => {
          setLeaveAlertOpen(false);
        }}
      />
    </Grid>
  );
}
