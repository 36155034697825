import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function ControlledCarousel(carousel: any) {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      variant="dark"
      activeIndex={index}
      onSelect={handleSelect}
      indicators={false}
    >
      <Carousel.Item interval={2500}>
        <img
          width="100%"
          style={{
            borderRadius: "15px",
            // border: "1px solid #000000",
          }}
          src="/createEventHomePage.png"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={2500}>
        <img
          width="100%"
          style={{ borderRadius: "15px" }}
          src="/searchWishlistHomePage.png"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={2500}>
        <img
          width="100%"
          style={{ borderRadius: "15px" }}
          src="/createWishlistHomePage.png"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={2500}>
        <img
          width="100%"
          style={{ borderRadius: "15px" }}
          src="/exploreGiftsHomePage.png"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
