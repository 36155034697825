import { Add, Search } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { useHistory } from "react-router-dom";
import { accountService } from "../../services";
import { exchangeService } from "../../services/exchange.service";
// import { ExchangeItem } from "../../shared/models/ExchangeItem";
// import AddItems from "./AddItems";
// import SaveExchangeDialog from "./SaveExchangeDialog";
import * as yup from "yup";
import { useFormik } from "formik";
import { Mixpanel } from "../../helpers/mixpanel-helper";

const validationSchema = yup.object({
  type: yup.string().required("Exchange type is required"),
  name: yup.string().required("Exchange name is required"),
  exchange_date: yup.date().required("Exchange date is required"),
  draw_date: yup.date().required("Draw names date is required"),
  budget: yup.number(),
  message: yup.string(),
  organiser_participating: yup.boolean().required("This field is required"),
});

let timer: any;

// const classes = makeStyles({
//   customTextField: {
//     "& input::placeholder": {
//       fontSize: "20px",
//     },
//   },
// });

export default function CreateExchange({ location }: { location: any }) {
  const history = useHistory();

  // Create new Date instance
  var tomorrowDate = new Date();

  // Add a day
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  const formik = useFormik({
    initialValues: {
      type: "Secret Santa",
      name: "",
      exchange_date: "",
      draw_date: "",
      budget: "",
      message: "",
      organiser_participating: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setOpenLoader(true);
      const resId = await initExchange(values);
      setOpenLoader(false);
      history.push("/exchange/invite/" + resId);
    },
  });

  var data = location.state ? location.state.data : null;

  const [name, setName] = React.useState(data ? data.name : "");
  const status = data ? data.status : "draft";
  const [exchangeId, setExchangeId] = React.useState(data ? data._id : null);

  const [openLoader, setOpenLoader] = React.useState(false);

  const initExchange = async (values: any) => {
    const res = await exchangeService.addToExchanges(values);

    setExchangeId(res._id);

    return res._id;
  };

  React.useEffect(() => {
    Mixpanel.track("Create Exchange Opened");
  }, []);

  React.useEffect(() => {
    if (exchangeId && status === "draft") {
      timer = window.setTimeout(async () => {
        exchangeService.updateExchange(exchangeId, {
          name: name && name.length > 0 ? name : "My Exchange",
        });
      }, 1000);
    }

    return () => {
      window.clearTimeout(timer);
    };
  }, [name]);

  return (
    <Grid
      sx={{ p: 2 }}
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <img
        style={{
          width: "100%",
          marginTop: 10,
          marginBottom: 10,
          borderRadius: "15px",
        }}
        src="/EventHomePage.png"
      ></img>

      <Typography
        sx={{
          mt: 1,
          mb: 1,
          fontFamily: "Neuton",
          fontStyle: "normal",
          fontHeight: "300",
          fontSize: "24px",
          lineHeight: "16px",
        }}
        variant="h6"
      >
        Let’s get the fun started!
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          InputLabelProps={{
            shrink: true,
            required: true,
            style: {
              fontFamily: "Neuton",
              fontSize: "18px",
            },
          }}
          inputProps={{
            min: 0,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
              fontFamily: "Neuton",
            },
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
              fontFamily: "Neuton",
            },
          }}
          variant="filled"
          id="type"
          select
          label="Event Type"
          name="type"
          value={formik.values.type}
          onChange={formik.handleChange}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
          fullWidth
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
            "& .MuiFilledInput-root": {
              background: "#FFFFFF",
              borderRadius: "10px",
            },
          }}
        >
          <MenuItem
            key="secret-santa"
            value="Secret Santa"
            sx={{ fontFamily: "Neuton" }}
          >
            Secret Santa
          </MenuItem>
          <MenuItem
            disabled
            key="weddings"
            value="weddings"
            sx={{ fontFamily: "Neuton" }}
          >
            Weddings&nbsp;
            <span style={{ fontSize: "small" }}>(Coming soon)</span>
          </MenuItem>
          <MenuItem
            disabled
            key="birthdays"
            value="birthdays"
            sx={{ fontFamily: "Neuton" }}
          >
            Birthdays&nbsp;
            <span style={{ fontSize: "small" }}>(Coming soon)</span>
          </MenuItem>
          <MenuItem
            disabled
            key="new-years"
            value="new-years"
            sx={{ fontFamily: "Neuton" }}
          >
            New Years&nbsp;
            <span style={{ fontSize: "small" }}>(Coming soon)</span>
          </MenuItem>
          <MenuItem
            disabled
            key="book-exchange"
            value="book-exchange"
            sx={{ fontFamily: "Neuton" }}
          >
            Book Exchange&nbsp;
            <span style={{ fontSize: "small" }}>(Coming soon)</span>
          </MenuItem>
        </TextField>
        <TextField
          // classes={{ root: classes.customTextField }}
          inputProps={{
            min: 0,
            style: {
              background: "#FFFFFF",
              fontFamily: "Neuton",
              borderRadius: "10px",
              fontSize: "16px",
            },
          }}
          InputLabelProps={{
            shrink: true,
            required: true,
            style: {
              fontFamily: "Neuton",
              fontSize: "16px",
            },
          }}
          fullWidth
          variant="filled"
          id="name"
          name="name"
          InputProps={{ disableUnderline: true }}
          label="Event Name"
          placeholder="Give your exchange an unique name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
        />
        <TextField
          InputLabelProps={{
            shrink: true,
            required: true,
            style: {
              fontFamily: "Neuton",
              fontSize: "16px",
            },
          }}
          fullWidth
          InputProps={{
            disableUnderline: true,
            inputProps: {
              min: tomorrowDate.toISOString().split("T")[0],
            },
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
              fontFamily: "Neuton",
            },
          }}
          variant="filled"
          id="draw-date"
          name="draw_date"
          label="RSVP Deadline (Members can join till this date)"
          type="date"
          value={formik.values.draw_date}
          onChange={formik.handleChange}
          error={formik.touched.draw_date && Boolean(formik.errors.draw_date)}
          helperText={formik.touched.draw_date && formik.errors.draw_date}
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
        />
        <TextField
          fullWidth
          InputProps={{
            disableUnderline: true,
            inputProps: {
              min: formik.values.draw_date
                ? formik.values.draw_date
                : tomorrowDate.toISOString().split("T")[0],
            },
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
              fontFamily: "Neuton",
            },
          }}
          variant="filled"
          id="exchange-date"
          name="exchange_date"
          label="Event Date (Exchange gifts on this date)"
          type="date"
          InputLabelProps={{
            shrink: true,
            required: true,
            style: {
              fontFamily: "Neuton",
              fontSize: "16px",
            },
          }}
          value={formik.values.exchange_date}
          onChange={formik.handleChange}
          error={
            formik.touched.exchange_date && Boolean(formik.errors.exchange_date)
          }
          helperText={
            formik.touched.exchange_date && formik.errors.exchange_date
          }
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
        />
        <TextField
          InputProps={{ disableUnderline: true }}
          inputProps={{
            min: 0,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
              fontFamily: "Neuton",
            },
          }}
          fullWidth
          id="budget"
          variant="filled"
          name="budget"
          label="Budget"
          placeholder="0"
          InputLabelProps={{
            shrink: true,
            style: {
              fontFamily: "Neuton",
              fontSize: "16px",
            },
          }}
          value={formik.values.budget}
          onChange={formik.handleChange}
          error={formik.touched.budget && Boolean(formik.errors.budget)}
          helperText={formik.touched.budget && formik.errors.budget}
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
          }}
        />
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          fullWidth
          id="message"
          name="message"
          variant="filled"
          placeholder="Try to stay within the spending limit, and make sure you make a Wishlist !"
          label="Message for Members"
          value={formik.values.message}
          InputLabelProps={{
            shrink: true,
            style: {
              fontFamily: "Neuton",
              fontSize: "16px",
            },
          }}
          inputProps={{
            min: 0,
            style: {
              fontFamily: "Neuton",
              borderRadius: "10px",
              fontSize: "16px",
            },
          }}
          multiline
          rows={2}
          onChange={formik.handleChange}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
            "& .MuiFilledInput-root": {
              background: "#FFFFFF",
              borderRadius: "10px",
            },
          }}
        />
        <TextField
          InputProps={{
            disableUnderline: true,
            style: {
              background: "#FFFFFF",
              borderRadius: "10px",
              fontFamily: "Neuton",
            },
          }}
          InputLabelProps={{
            shrink: true,
            style: {
              fontFamily: "Neuton",
              fontSize: "16px",
            },
          }}
          id="organiser-participating"
          name="organiser_participating"
          select
          variant="filled"
          label="Will you be Participating?"
          value={formik.values.organiser_participating}
          onChange={formik.handleChange}
          fullWidth
          sx={{
            mt: 2,
            mb: 2,
            border: "1px solid #000000",
            borderRadius: "10px",
            "& .MuiFilledInput-root": {
              background: "#FFFFFF",
              borderRadius: "10px",
            },
          }}
        >
          <MenuItem
            key="secret-santa1"
            value={true as any}
            sx={{ fontFamily: "Neuton" }}
          >
            Yes
          </MenuItem>
          <MenuItem
            key="secret-santa2"
            value={false as any}
            sx={{ fontFamily: "Neuton" }}
          >
            No
          </MenuItem>
        </TextField>
        <div style={{ textAlign: "center" }}>
          <Button
            sx={{ mt: 2, mb: 8, width: "50%" }}
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            style={{
              width: "220px",
              height: "48px",
              borderRadius: "11px",
              border: "1px solid #FF5757",
              color: "#FFFFFF",
              backgroundColor: "#FF5757",
              fontFamily: "Neuton",
              fontStyle: "Normal",
              fontWeight: "300",
              fontSize: "24px",
              lineHeight: "16px",
              textTransform: "none",
            }}
          >
            Create Event
          </Button>
        </div>
      </form>
      {/* <SaveExchangeDialog
        open={saveExchangeDialogOpen}
        setOpen={setSaveExchangeDialogOpen}
      /> */}
    </Grid>
  );
}
