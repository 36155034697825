import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { People } from "@mui/icons-material";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { exchangeService } from "../../services/exchange.service";
import AlertDialog from "../../modules/AlertDialog";
import { accountService } from "../../services";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function ExchangeMembers({ location }: { location: any }) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteIdx, setDeleteIdx] = useState<null | number>(null);
  var data = location.state ? location.state.data : null;
  var noDelete = location.state ? location.state.noDelete : true;

  const [members, setMembers] = useState<null | Array<any>>(
    data
      ? data.members.filter(
          (member: any) => member._id !== accountService.userValue.id
        )
      : null
  );

  const [invitees, setInvitees] = useState<null | Array<any>>(
    data ? data.invitees : null
  );

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const removeMember = async (itemIdx: number) => {
    setAlertOpen(false);
    if (members && data) {
      exchangeService.removeMember(data._id, members[itemIdx]._id);
      const nextItems = members.filter((item, idx) => idx !== itemIdx);
      setMembers(nextItems);
    }
    setDeleteIdx(null);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", p: 2 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          sx={{ fontFamily: "Neuton", fontSize: "24px" }}
          label="Joined"
          {...a11yProps(0)}
        />
        <Tab
          sx={{ fontFamily: "Neuton", fontSize: "24px" }}
          label="Invited"
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Box
          sx={{
            background: `#FBD6C6`,
            borderRadius: "15px",
            textAlign: "center",
            p: 1,
          }}
        >
          <People sx={{ mb: 1, mt: 1 }} />
          {members &&
            members.map((member, idx) => (
              <>
                <Grid
                  sx={{ mt: 1, mb: 1 }}
                  container
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography>{member.fullName}</Typography>
                  </Grid>
                  <Grid item>
                    {!noDelete && (
                      <Typography
                        sx={{
                          fontFamily: "Neuton",
                        }}
                        onClick={() => {
                          setDeleteIdx(idx);
                          setAlertOpen(true);
                        }}
                        variant="caption"
                      >
                        Remove
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider />
              </>
            ))}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Box
          sx={{
            background: `#FBD6C6`,
            borderRadius: "15px",
            textAlign: "center",
            p: 1,
          }}
        >
          <People sx={{ mb: 1, mt: 1 }} />
          {invitees &&
            invitees.map((invitee, idx) => (
              <>
                <Grid
                  sx={{ mt: 1, mb: 1 }}
                  container
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontFamily: "Neuton",
                        fontSize: "20px",
                        color: "#000000",
                      }}
                    >
                      {invitee.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>&nbsp;</Typography>
                  </Grid>
                </Grid>
                <Divider />
              </>
            ))}
        </Box>
      </TabPanel>
      {deleteIdx !== null && (
        <AlertDialog
          title="Remove Member"
          body="Are you sure you want to remove this member?"
          open={alertOpen}
          setOpen={setAlertOpen}
          agreeFunc={() => removeMember(deleteIdx)}
          disagreeFunc={() => setAlertOpen(false)}
        />
      )}
    </Box>
  );
}
