import { MoreVert } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import React, { useState } from "react";
import AlertDialog from "../../modules/AlertDialog";
import { WishlistItem } from "../../shared/models/WishlistItem";
import ItemDetailsDialog from "../wishlist/ItemDetailsDialog";

export default function ShowFulfilledItem({
  item,
  idx,
  updateItem,
  deleteItem,
}: {
  item: any;
  idx: number;
  updateItem: (itemIdx: number, details: WishlistItem) => void;
  deleteItem: (itemIdx: number) => void;
}) {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      sx={{ mb: 2 }}
      container
      item
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid xs={12} item>
        <FormControlLabel
          control={
            <Checkbox
              checked={true}
              onChange={(e) =>
                updateItem(idx, {
                  fulfilled: e.target.checked,
                })
              }
            />
          }
          label={
            <Typography variant="body2" sx={{ fontFamily: "Neuton" }}>
              I have got this
            </Typography>
          }
        />
      </Grid>
      <Grid sx={{ position: "relative" }} xs={8} item>
        <TextField
          disabled
          sx={{ width: "100%" }}
          inputProps={{
            style: item.fulfilled ? { textDecoration: "line-through" } : {},
          }}
          color="secondary"
          value={item.name}
        />
        <div
          style={{
            top: "5px",
            right: "0px",
            position: "absolute",
            cursor: "pointer",
          }}
        >
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => {
              // setExchangeIdx(idx);
              handleClick(e);
            }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setDetailsDialogOpen(true);
              }}
            >
              Edit Wish
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setAlertOpen(true);
              }}
            >
              Delete Wish
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                updateItem(idx, {
                  fulfilled: false,
                });
              }}
            >
              Move back to wishlist
            </MenuItem>
          </Menu>
        </div>
      </Grid>
      <Grid xs={4} item>
        <ItemDetailsDialog
          open={detailsDialogOpen}
          setOpen={setDetailsDialogOpen}
          item={item}
          idx={idx}
          updateItem={updateItem}
        />
      </Grid>
      <AlertDialog
        title="Delete Item"
        body="Are you sure you want to delete this item?"
        open={alertOpen}
        setOpen={setAlertOpen}
        agreeFunc={() => deleteItem(idx)}
        disagreeFunc={() => {}}
      />
    </Grid>
  );
}
